import {StoreUserPageContentDetailRoom} from "./storeUserPageContentDetailRoom";
import {BaseStoreUserPage, InitDataBaseStoreUserPage} from "../base/baseStoreUserPage";

/**
 * Страница "Детальная страница номера"
 */
export class StoreUserPageDetailRoom extends BaseStoreUserPage<StoreUserPageContentDetailRoom> {

    constructor(initData: InitDataBaseStoreUserPage) {
        super(initData);
    }

    public eventPageShown() {
        super.eventPageShown();
        const storeContentPage = new StoreUserPageContentDetailRoom({
            storeRouter: this._storeRouter,
            storeSitePageTitle: this._storeSitePageTitle,
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup,
            linkBack: this._linkBack,
        });

        storeContentPage.serverRequestGetRoomData();
        storeContentPage.serverRequestGetEquipmentsStoreData();
        this._setStoreContentPage(storeContentPage);
    }
}
