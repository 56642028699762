import {DataWithInternalId} from "../../interfaces/dataWithInternalId";
import {v4 as uuidv4} from 'uuid';

interface InitDataStoreDataManager<DataItem extends DataWithInternalId> {
    dataForManage?: DataItem[];
}

/**
 * Хранилище для управления данными
 */
export class StoreDataManager<DataItem extends DataWithInternalId> {
    //region Данные для управления
    private _dataForManage: DataItem[];

    /**
     * Получить текущие управляемые данные
     */
    get dataForManage() {
        return this._dataForManage.slice();
    }

    /**
     * Установлены данные или нет
     */
    get isDataSet(): boolean {
        return !!this._dataForManage.length;
    }

    /**
     * Установить данные для управления
     * @param dataList
     */
    set dataForManage(dataList: DataItem[]) {
        this._dataForManage = dataList.slice();
    }

    //endregion

    constructor(initData?: InitDataStoreDataManager<DataItem>) {
        const initDataForManage: DataItem[] | undefined = initData?.dataForManage;

        this._dataForManage = (Array.isArray(initDataForManage)) ? initDataForManage.slice() : [];
    }

    /**
     * Найти элемент по internalId
     * @param internalId
     */
    public getItemByInternalId(internalId: string): undefined | DataItem {
        for (let i = 0; i < this._dataForManage.length; ++i) {
            if (this._dataForManage[i].internalId === internalId) {
                return Object.assign({}, this._dataForManage[i]);
            }
        }
        return undefined;
    }

    public getUuidByDataAttributeHTMLElement(element: HTMLElement): string | null {
        return element.getAttribute('data-id');
    }

    public getItemByDataAttributeUuid(element: HTMLElement): DataItem | undefined {
        const uuid: string | null = this.getUuidByDataAttributeHTMLElement(element);

        if (!uuid) {
            return undefined;
        }

        const foundElement = this.getItemByInternalId(uuid);

        if (!foundElement) {
            return undefined;
        }

        return foundElement;
    }

    /**
     * Удалить элемент по InternalId
     * Вернет true если удалось удалить
     * @param internalId
     */
    public deleteItemByInternalId(internalId: string): boolean {
        let deleteItemIndex: number = -1;
        for (let i = 0; i < this._dataForManage.length; ++i) {
            if (this._dataForManage[i].internalId === internalId) {
                deleteItemIndex = i;
            }
        }

        if (deleteItemIndex === -1) {
            return false;
        }

        this._dataForManage.splice(deleteItemIndex, 1);
        return true;
    }

    /**
     * Добавить новый элемент
     * @param item
     */
    public addNewItem(item: DataItem) {
        const newItemCopy: DataItem = {
            ...item,
            internalId: uuidv4()
        };
        // Добавляем в начало
        this._dataForManage.push(newItemCopy);
    }

    /**
     * Редактировать существующий элемент
     * Вернет true если элемент удалось изменить
     * @param item
     */
    public editExistingItem(item: DataItem): boolean {
        for (let i = 0; i < this._dataForManage.length; ++i) {
            if (this._dataForManage[i].internalId === item.internalId) {
                this._dataForManage[i] = item;
                return true;
            }
        }

        return false;
    }

    /**
     * Сбросить все данные хранилища
     */
    public resetStoreData() {
        this._dataForManage = [];
    }

    /**
     * Добавить или редактировать элемент
     * Вернет true Если элемент новый и false если уже существовал
     * @param item
     */
    public addOrChangeItem(item: DataItem): boolean {
        if (!item.internalId) {
            this.addNewItem(item);
            return true;
        }

        this.editExistingItem(item);
        return false;
    }
}


