import React from "react";
import styles from './popupFullScreenStyle.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {showHiddenDocumentBodyOverflow} from "./showHiddenDocumentBodyOverflow";

export interface PopupFullScreenBasicProps {
    eventClose?: () => void;
}

interface PopupFullScreenWithEventsProps extends PopupFullScreenBasicProps {
    children: React.ReactNode;
    className?: string;
}

/**
 * Контейнер всплывающего окна
 * Содержит события, слушает клавиатуру для закрытия при нажатии Esc
 * Имеет кнопку в шапке для закрытия (крестик)
 */
export default class PopupFullScreenWithEvents extends React.PureComponent<PopupFullScreenWithEventsProps> {
    constructor(props: PopupFullScreenWithEventsProps) {
        super(props);
        this._event_closePopupPressEscape = this._event_closePopupPressEscape.bind(this);
    }

    private _event_closePopupPressEscape(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            if (this.props.eventClose) {
                this.props.eventClose();
            }
        }
    }

    componentDidMount() {
        showHiddenDocumentBodyOverflow(false);
        document.addEventListener("keydown", this._event_closePopupPressEscape);
    }

    componentWillUnmount() {
        showHiddenDocumentBodyOverflow(true);
        document.removeEventListener("keydown", this._event_closePopupPressEscape);
    }

    render() {
        return (
            <div className={styles.popupBackground}>
                <div className={this.props.className ? `${styles.popupBody} ${this.props.className}` : styles.popupBody}>
                    {
                        this.props.eventClose ?
                            <div className={styles.containerButtonClose}>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={styles.resetIcon}
                                    onClick={this.props.eventClose}
                                />
                            </div> : null
                    }
                    <div className={styles.popupContentContainerWithCloseButton}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
