import React from "react";
import styles from './headerDesktopStyle.scss';
import {Link} from "react-router-dom";
import {observer} from "mobx-react";

const TEXT_BOOKING_ROOMS: string = 'Бронирование номеров';
const TEXT_BOOK_SE: string = 'Сервис бронирования BookSE';

interface HeaderDesktopLogo {
    logo?: string | null;
    title?: string | null;
}

function HeaderDesktopLogo(props: HeaderDesktopLogo) {
    return (
        <div className={styles.innerContainer}>
            {
                props.logo ?
                    <img
                        src={props.logo}
                    /> :
                    <img
                        src={require('../../../../../img/img_base64/bookSELogo.png')}
                        alt={'bookSELogo'}
                    />
            }
            <div className={styles.companyNameInTopMenuContainer}>
                <span>{props.title ? props.title : TEXT_BOOK_SE}</span>
                <span className={styles.typeofUserInCabinet}>{TEXT_BOOKING_ROOMS}</span>
            </div>
        </div>
    );
}

interface HeaderDesktopProps {
    menuItems?: JSX.Element[];
    linkHomePage?: string;
    logo?: string | null;
    headerTitle?: string;
}

function HeaderDesktop(props: HeaderDesktopProps) {
    return (
        <div className={styles.desktopContainer}>
            <div className={styles.companyLogoInTopMenuContainerDefault}>
                {
                    props.linkHomePage ?
                        <Link
                            to={props.linkHomePage}
                            className={styles.companyLogoImgContainerLink}
                        >
                            <HeaderDesktopLogo
                                logo={props.logo}
                                title={props.headerTitle}
                            />
                        </Link> :
                        <div className={styles.companyLogoImgContainerLink}>
                            <HeaderDesktopLogo
                                logo={props.logo}
                                title={props.headerTitle}
                            />
                        </div>
                }
            </div>
            {
                props.menuItems ?
                    <div className={styles.rightBlockMenuContainer}>
                        <div className={styles.topMenuNavigation}>
                            {props.menuItems}
                        </div>
                    </div> : null
            }
        </div>
    );
}

export default observer(HeaderDesktop);
