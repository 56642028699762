import {HotelCoordinates} from "../../../interfaces/api/hotelCoordinates";
import {StoreApiUser} from "../../../api/storeApiUser";

interface InitDataStoreUserHotelCoordinates {
    storeApi: StoreApiUser;
}

export class StoreUserHotelCoordinates {
    private readonly _storeApi: StoreApiUser;

    constructor(initData: InitDataStoreUserHotelCoordinates) {
        this._storeApi = initData.storeApi;
    }

    get hotelCoordinates(): HotelCoordinates | null {
        const latitude: number | undefined = this._storeApi.storeHotelSpecificationInfo.hotelSpecificationInfo?.latitude;
        const longitude: number | undefined = this._storeApi.storeHotelSpecificationInfo.hotelSpecificationInfo?.longitude;

        if (latitude && longitude) {
            return {
                latitude: latitude,
                longitude: longitude
            }
        }

        return null;
    }
}
