import React from "react";
import styles from "./siteTopMenuContainerStyle.scss";

interface SiteTopMenuContainerProps {
    readonly children: React.ReactNode;
}

function SiteTopMenuContainer(props: SiteTopMenuContainerProps) {
    return (
        <div className={styles.menuMainContainer}>
            <div className={styles.topMenuMainContainer}>
                {props.children}
            </div>
        </div>
    );
}

export default React.memo(SiteTopMenuContainer);
