import {SitePagesDictionary, StoreRouter} from "./common/storeRouter";
import {StoreSitePageTitle} from "./common/storeSitePageTitle";
import {StoreFullScreenPopup} from "./common/storeFullScreenPopup";
import {RouterPagesKeysUser} from "../staticData/routerPages/routerPagesUser";
import {StoreApiUser} from "../api/storeApiUser";
import {StoreHotelSpecificationInfo} from "./common/storeHotelSpecificationInfo";
import {HotelSpecification} from "../interfaces/api/hotelSpecification";
import {StoreUserHotelInfo} from "./user/hotelInfo/storeUserHotelInfo";
import {HotelInfo} from "../interfaces/api/hotelInfo";

interface InitDataBaseStoreMain {
    readonly sitePagesDictionary: SitePagesDictionary<RouterPagesKeysUser>;
    readonly storeApi: StoreApiUser;
}

export class BaseStoreMain {
    protected readonly _storeApi: StoreApiUser;
    public readonly storeFullScreenPopup: StoreFullScreenPopup;
    public readonly storeRouter: StoreRouter<RouterPagesKeysUser>;
    public readonly storeSitePageTitle: StoreSitePageTitle;
    public readonly storeHotelSpecificationInfo: StoreHotelSpecificationInfo;

    constructor(initData: InitDataBaseStoreMain) {
        this._storeApi = initData.storeApi;
        this.storeFullScreenPopup = new StoreFullScreenPopup();
        this.storeRouter = new StoreRouter({
            pages: initData.sitePagesDictionary,
        });
        this.storeSitePageTitle = new StoreSitePageTitle();
        this.storeHotelSpecificationInfo = new StoreHotelSpecificationInfo();
    }

    get errorSearchClientId(): string | undefined {
        return this._storeApi.errorSearchClientId;
    }

    get errorConnectUrl(): string | undefined {
        return this._storeApi.errorConnectUrl;
    }

    get errorAuthApi(): string | undefined {
        return this._storeApi.errorAuthApi;
    }

    get isSetAuthApiData(): boolean {
        return this._storeApi.isSetAuthApiData;
    }

    get hotelSpecificationInfo(): HotelSpecification | null | undefined {
        return this._storeApi.storeHotelSpecificationInfo.hotelSpecificationInfo;
    }

    get hotelInfo(): HotelInfo | null | undefined {
        return this._storeApi.storeUserHotelInfo.hotelInfo;
    }
}
