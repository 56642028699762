export function getRequestErrorText(errorObject: any): string {
    const TEXT_DEFAULT_ERROR = 'Во время выполнения запроса возникла ошибка';

    if (!errorObject) {
        return TEXT_DEFAULT_ERROR;
    }

    if (typeof errorObject !== 'object') {
        return TEXT_DEFAULT_ERROR;
    }

    if (!errorObject.hasOwnProperty('response')) {
        return TEXT_DEFAULT_ERROR;
    }

    const response = errorObject.response;

    if (!response) {
        return TEXT_DEFAULT_ERROR;
    }

    if (!response.hasOwnProperty('data')) {
        return TEXT_DEFAULT_ERROR;
    }

    const data = response.data;

    if (typeof data !== 'string') {
        return TEXT_DEFAULT_ERROR;
    }

    if (!data) {
        return TEXT_DEFAULT_ERROR;
    }

    if (data.length >= 300 ) {
        return TEXT_DEFAULT_ERROR;
    }

    return errorObject.response.data;
}
