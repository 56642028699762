import { createRoot } from 'react-dom/client';

interface DOMContentLoadedOptions {
    pageComponent: JSX.Element;
    onLoaded?: () => void;
}

function event_DOMContentLoadedOptions(inputOptions: DOMContentLoadedOptions): void {

    // Пытаемся получить главный элемент страницы
    let rootContainer: HTMLElement | null = document.getElementById("Root");

    if (rootContainer) {
        rootContainer.innerHTML = '';
    } else {
        const container = document.createElement("div");
        document.body.append(container);
        rootContainer = container;
    }

    if (inputOptions.onLoaded) {
        inputOptions.onLoaded();
    }

    const root = createRoot(rootContainer);
    root.render(inputOptions.pageComponent);
}

export default function DOMContentLoaded(inputOptions: DOMContentLoadedOptions) {
    const eventPageLoaded = event_DOMContentLoadedOptions.bind(null, inputOptions);
    document.addEventListener("DOMContentLoaded", eventPageLoaded); // Вешаем обработчик событий на загрузку стр
}
