import {action, computed, makeObservable, observable} from "mobx";
import {StoreApiUser} from "../../../../api/storeApiUser";
import {Dictionary} from "../../../../interfaces/dictionary";
import {RoomFirstPhotoItem, RoomPhotoItem} from "../../../../interfaces/api/roomPhotoCollection";
import {getRequestErrorText} from "../../../../api/helpers/getRequestErrorText";
import {RoomPhotoVM} from "../../../../api/src";
import {
    convertServerRoomFirstPhotoModelToClientModel
} from "../../../../helpers/validation/convertServerRoomFirstPhotoModelToClientModel";

interface InitDataStoreUserRoomPhotos {
    storeApi: StoreApiUser;
}

type ErrorPhoto = string | undefined;

export class StoreUserRecommendedRoomPhoto {
    private readonly _storeApi: StoreApiUser;

    // region Фото рекомендованных номеров
    private readonly _recommendedRoomPhotos_observable: Dictionary<RoomPhotoItem | null>;

    private _setRecommendedRoomPhotos_action(photos: RoomPhotoItem | null, roomId: number) {
        this._recommendedRoomPhotos_observable[roomId] = photos;
    }

    get recommendedRoomPhotos() {
        return this._recommendedRoomPhotos_observable;
    }
    // endregion

    // region Ошибки
    private readonly _recommendedRoomPhotosErrors_observable: Dictionary<ErrorPhoto>;

    private _setRecommendedRoomPhotosErrors_action(error: ErrorPhoto, roomId: number) {
        this._recommendedRoomPhotosErrors_observable[roomId] = error;
    }

    get recommendedRoomPhotosErrors() {
        return this._recommendedRoomPhotosErrors_observable;
    }
    // endregion

    /**
     * Получение изображения номера
     * @param roomId
     * @public
     */
    public serverRequestGetFirstRoomPhoto(roomId: number) {
        this._storeApi.apiRoom.roomGetRoomPhotoFirstGet(roomId)
            .then((inputData) => {
                const inputPhoto: RoomPhotoVM = inputData.data;
                // Валидация данных
                const validPhotosData: RoomFirstPhotoItem | undefined = convertServerRoomFirstPhotoModelToClientModel(inputPhoto);
                if (!validPhotosData) {
                    return;
                }
                // Установка данных
                this._setRecommendedRoomPhotos_action(validPhotosData.photo, roomId)
            })
            .catch((errorText) => {
                this._setRecommendedRoomPhotosErrors_action(getRequestErrorText(errorText), roomId)
            });
    }

    constructor(initData: InitDataStoreUserRoomPhotos) {
        this._storeApi = initData.storeApi;

        this._recommendedRoomPhotos_observable = {};
        this._recommendedRoomPhotosErrors_observable = {};

        this.serverRequestGetFirstRoomPhoto = this.serverRequestGetFirstRoomPhoto.bind(this);

        makeObservable<this,
            '_recommendedRoomPhotos_observable'
            | '_recommendedRoomPhotosErrors_observable'
            | '_setRecommendedRoomPhotos_action'
            | '_setRecommendedRoomPhotosErrors_action'>(this, {
            _recommendedRoomPhotos_observable: observable,
            _recommendedRoomPhotosErrors_observable: observable,
            _setRecommendedRoomPhotos_action: action,
            _setRecommendedRoomPhotosErrors_action: action,
            recommendedRoomPhotos: computed,
            recommendedRoomPhotosErrors: computed
        });
    }
}
