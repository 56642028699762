import {StoreComponentInputText} from "../../common/storeComponentInputText";
import validationStringStandard from "../../../helpers/validation/validationStringStandard";
import {StoreComponentStandardCheckbox} from "../../common/storeComponentStandardCheckbox";
import {BookingRoom} from "../../../interfaces/api/bookingRoom";
import {Dates, FocusedInputShape} from "./storeUserPageContentBooking";
import {action, computed, makeObservable, observable} from "mobx";
import {StoreFullScreenPopup} from "../../common/storeFullScreenPopup";
import {ErrorText} from "../../../globalType/ErrorText";

interface InitDataStoreUserBookingRoom {
    readonly fullyBookedDays: string[];
    readonly storeFullScreenPopup: StoreFullScreenPopup;
    readonly eventSaveData: (data: BookingRoom) => void;
    readonly roomId: number;
}

export class StoreUserBookingRoom {
    private readonly _fullyBookedDays: string[];
    private readonly _storeFullScreenPopup: StoreFullScreenPopup;
    private readonly _externalEventSaveData: (data: BookingRoom) => void;
    private readonly _roomId: number;

    public readonly storeEditName: StoreComponentInputText;
    public readonly storeEditPhone: StoreComponentInputText;
    public readonly storeEditIsCheckPersonalData: StoreComponentStandardCheckbox;

    private _dates_observable: Dates;

    private _setDates_action(dates: Dates) {
        this._dates_observable = dates;
    }

    get dates() {
        return this._dates_observable;
    }

    private _datesError_observable: ErrorText;

    private _setDatesError_action(dates: ErrorText) {
        this._datesError_observable = dates;
    }

    get datesError() {
        return this._datesError_observable;
    }

    public eventSaveData() {
        const validName = this.storeEditName.validValue();
        const validPhone = this.storeEditPhone.validValue();
        const validFeedbackCheckPersonalData = this.storeEditIsCheckPersonalData.isCheck;

        if (!validFeedbackCheckPersonalData) {
            this.storeEditIsCheckPersonalData.setErrorText('Согласитесь с условиями')
        }

        if (!this._dates_observable.startDate) {
            this._setDatesError_action('Дата заезда не задана');
            return;
        }

        if (!this._dates_observable.endDate) {
            this._setDatesError_action('Дата выезда не задана');
            return;
        }

        if (
            validName.errorText
            || validPhone.errorText
            || !this._dates_observable.startDate
            || !this._dates_observable.endDate
            || this.storeEditIsCheckPersonalData.errorText
        ) {
            return;
        }

        this._externalEventSaveData({
            roomId: this._roomId,
            name: validName.validValue,
            phone: validPhone.validValue,
            checkIn: this._dates_observable.startDate.toDate(),
            checkOut: this._dates_observable.endDate.toDate()
        });
    }

    private _focusedInput_observable: FocusedInputShape | null;

    private _setFocusedInput_action(focus: FocusedInputShape | null) {
        this._focusedInput_observable = focus;
    }

    public defaultFocusedInput: FocusedInputShape;

    get focusedInput() {
        return this._focusedInput_observable;
    }

    public handleDatesChange = (dates: Dates) => {
        this._setDates_action(dates);
    };

    public onFocusChange = (focusedInput: any) => {
        this._setFocusedInput_action(focusedInput);
    };

    /**
     * Выход за пределы диапазона
     * @param day
     * @public
     */
    public isOutsideRange(day: any): boolean {
        if (this._fullyBookedDays) {
            for (let i = 0; i < this._fullyBookedDays.length; ++i) {
                if (day._d.setHours(12,0,0,0) === new Date(this._fullyBookedDays[i]).setHours(12,0,0,0)) {
                    return true;
                }
            }
        }

        return day._d.setHours(12, 0, 0, 0) < new Date().setHours(12, 0, 0, 0);
    }

    /**
     * Событие закрытия календаря
     * @param dates
     * @public
     */
    public onCloseCalendar(dates: any) {
        if (this._fullyBookedDays && dates.startDate && dates.endDate) {
            for (let i = 0; i < this._fullyBookedDays.length; ++i) {
                if (
                    dates.startDate._d.setHours(12,0,0,0) < new Date(this._fullyBookedDays[i]).setHours(12,0,0,0) &&
                    dates.endDate._d.setHours(12,0,0,0) > new Date(this._fullyBookedDays[i]).setHours(12,0,0,0)
                ) {
                    this._resetDates();
                    this._storeFullScreenPopup.error = {
                        errorText: 'Ошибка выбора диапазона дат. В диапазоне содержатся недоступные для бронирования дни',
                        eventClose: this._storeFullScreenPopup.closeAllWindows,
                    }
                    return;
                }
            }
        }
    }

    /**
     * Сбросить календарь
     * @private
     */
    private _resetDates() {
        this._setDates_action({
            startDate: null,
            endDate: null
        });

        this._setDatesError_action(undefined);
        this._setFocusedInput_action(null);
    }

    constructor(initData: InitDataStoreUserBookingRoom) {
        this._fullyBookedDays = initData.fullyBookedDays;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;
        this.eventSaveData = this.eventSaveData.bind(this);
        this._externalEventSaveData = initData.eventSaveData;

        this._roomId = initData.roomId;

        this.storeEditName = new StoreComponentInputText({
            value: '',
            isResetErrorOnChangeValue: true,
            externalFunctionValidValue: (inputName) =>
                validationStringStandard({
                    minLength: 5,
                    maxLength: 50,
                    stringToCheck: inputName
                })
        });

        this.storeEditPhone = new StoreComponentInputText({
            value: '',
            isResetErrorOnChangeValue: true,
            externalFunctionValidValue: (inputPhone) =>
                validationStringStandard({
                    minLength: 11,
                    maxLength: 30,
                    stringToCheck: inputPhone
                })
        });

        this.storeEditIsCheckPersonalData = new StoreComponentStandardCheckbox({
            isCheck: false,
            isResetErrorOnChangeValue: true
        });

        this._dates_observable = {
            startDate: null,
            endDate: null
        };

        this._datesError_observable = undefined;
        this.defaultFocusedInput = 'startDate';
        this._focusedInput_observable = this.defaultFocusedInput;

        this.handleDatesChange = this.handleDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);
        this.onCloseCalendar = this.onCloseCalendar.bind(this);

        makeObservable<this,
            | '_dates_observable'
            | '_datesError_observable'
            | '_focusedInput_observable'
            | '_setDates_action'
            | '_setFocusedInput_action'
            | '_setDatesError_action'>(this, {
            _dates_observable: observable,
            _focusedInput_observable: observable,
            _datesError_observable: observable,
            _setDates_action: action,
            _setFocusedInput_action: action,
            _setDatesError_action: action,
            dates: computed,
            focusedInput: computed,
            datesError: computed
        });
    }
}
