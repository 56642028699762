import {StoreRouter} from "./storeRouter";
import {StoreApiUser} from "../../api/storeApiUser";
import {StoreUserHotelLogo} from "../user/hotelLogo/storeUserHotelLogo";

interface InitDataStoreTopMenu<RouterPagesKeys extends string> {
    readonly storeRouter: StoreRouter<RouterPagesKeys>;
    readonly storeApi: StoreApiUser;
    readonly linkToHomePage: string;
}

export class StoreSiteTopMenu<RouterPagesKeys extends string> {
    public readonly storeRouter: StoreRouter<RouterPagesKeys>;
    private readonly _storeApi: StoreApiUser
    public readonly linkToHomePage: string;
    public readonly storeUserHotelLogo: StoreUserHotelLogo;

    constructor(initData: InitDataStoreTopMenu<RouterPagesKeys>) {
        this.storeRouter = initData.storeRouter;
        this._storeApi = initData.storeApi;
        this.linkToHomePage = initData.linkToHomePage;

        this.storeUserHotelLogo = new StoreUserHotelLogo({
            storeApi: this._storeApi
        });
    }
}
