import {StoreUserPageContentBooking} from "./storeUserPageContentBooking";
import {BaseStoreUserPage, InitDataBaseStoreUserPage} from "../base/baseStoreUserPage";

export class StoreUserPageBooking extends BaseStoreUserPage<StoreUserPageContentBooking> {
    constructor(initData: InitDataBaseStoreUserPage) {
        super(initData);
    }

    public eventPageShown() {
        super.eventPageShown();

        const storeContentPage = new StoreUserPageContentBooking({
            storeRouter: this._storeRouter,
            storeSitePageTitle: this._storeSitePageTitle,
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup,
            linkBack: this._linkBack
        });

        // Получение информации о номере
        storeContentPage.serverRequestGetAllStoreData();
        // Устанавливаем данные
        this._setStoreContentPage(storeContentPage);
    }
}
