import React from "react";
import styles from './popupDataEditorStyle.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition, faTimes} from "@fortawesome/free-solid-svg-icons";
import {StandardButton} from "../../standardButton";
import {ButtonsContainer} from "../../buttonsContainer";

const TEXT_BUTTON_SAVE: string = 'Сохранить';
const TEXT_BUTTON_CANCEL: string = 'Отмена';

interface PopupDataGridEditorProps {
    title: string;
    children: React.ReactNode;
    eventSaveData?: () => void;
    eventCancel: () => void;
    eventClose:() => void;
    className?: string;
    publicTextButtonSave?: string;
    publicTextButtonCancel?: string;
    iconSave?: IconDefinition;
    iconCancel?: IconDefinition;
    helperText?: string;
}

export default function PopupDataEditor(props: PopupDataGridEditorProps) {
    return (
        <div className={styles.bodyPopupContainer}>
            <div className={props.className ? `${styles.popupWindowContainer} ${props.className}`: styles.popupWindowContainer}>
                <div className={styles.topPopupContainer}>
                    <div className={styles.containerButtonClose}>
                        <span
                            onClick={props.eventClose}
                            className={styles.buttonClosePopup}
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                className={styles.resetIcon}
                            />
                        </span>
                    </div>
                    <div className={styles.popupWindowTitle}>{props.title}</div>
                </div>
                <div className={styles.contentContainer}>
                    {props.children}
                </div>
                <ButtonsContainer className={styles.buttonsContainer}>
                    {
                        props.eventSaveData ?
                            <StandardButton
                                text={props.publicTextButtonSave ? props.publicTextButtonSave : TEXT_BUTTON_SAVE}
                                type={'ok'}
                                eventClick={props.eventSaveData}
                            /> : null
                    }
                    <StandardButton
                        text={props.publicTextButtonCancel ? props.publicTextButtonCancel : TEXT_BUTTON_CANCEL}
                        type={'cancel'}
                        eventClick={props.eventCancel}
                    />
                </ButtonsContainer>
            </div>
        </div>
    );
}
