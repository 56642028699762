import {action, computed, makeObservable, observable} from "mobx";
import React from "react";
import {ErrorText} from "../../interfaces/errorText";
import {ValidationValueResult} from "../../interfaces/validationValueResult";

type CallbackValidValue = (value?: string | null) => ValidationValueResult<string>;

interface InitDataStoreComponentInputText {
    // Текст когда поле пустое
    placeholder?: string;
    // Название поля
    name?: string;
    // Внешнее событие изменение данных
    externalEventChangeValue?: (value: string) => void;
    // Внешняя функция проверить текущее значение
    externalFunctionValidValue?: CallbackValidValue
    // Значение input
    value?: string;
    // Сбросить ошибку при изменении данных
    isResetErrorOnChangeValue?: boolean;
}

export class StoreComponentInputText {
    private readonly _isResetErrorOnChangeValue?: boolean;
    private readonly _externalEventChangeValue?: (value: string) => void;
    private readonly _externalFunctionValidValue?: CallbackValidValue;

    public readonly placeholder?: string;
    public readonly name?: string;

    private _value_observable?: string;
    private _errorText_observable: ErrorText;

    public eventChangeValue(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
        const newValue = e.currentTarget.value
        this._value_observable = newValue;

        if (this._isResetErrorOnChangeValue) {
            // Сбрасываем ошибку если есть
            if (this._errorText_observable) {
                this._errorText_observable = undefined;
            }
        }

        if (typeof this._externalEventChangeValue === 'function') {
            this._externalEventChangeValue(newValue);
        }
    }

    public eventOnKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }

    public resetErrorText() {
        this._errorText_observable = undefined;
    }

    public setErrorText(inputErrorText: ErrorText) {
        if (this._errorText_observable === inputErrorText) {
            return;
        }

        this._errorText_observable = inputErrorText;
    }

    public resetDefaultData(value: string | undefined) {
        this._value_observable = value;
        this._errorText_observable = undefined;
    }

    /**
     * Проверить значение
     */
    public validValue(): ValidationValueResult<string> {
        if (typeof this._externalFunctionValidValue !== 'function') {
            return {
                errorText: undefined,
                validValue: this._value_observable ?? ''
            };
        }

        const validValue = this._externalFunctionValidValue(this._value_observable);

        if (validValue.errorText) {
            if (this._errorText_observable !== validValue.errorText) {
                this._errorText_observable = validValue.errorText;
            }

            return validValue;
        }

        if (this._errorText_observable) {
            this._errorText_observable = undefined;
        }

        return validValue
    }

    get errorText() {
        return this._errorText_observable
    }

    get value() {
        return this._value_observable;
    }

    get isHasError() {
        return !!this._errorText_observable;
    }

    constructor(initData?: InitDataStoreComponentInputText) {
        this.eventChangeValue = this.eventChangeValue.bind(this);
        this.eventOnKeyPress = this.eventOnKeyPress.bind(this);
        this._value_observable = initData?.value;
        this._isResetErrorOnChangeValue = initData?.isResetErrorOnChangeValue;
        this.placeholder = initData?.placeholder;
        this.name = initData?.name;
        this._externalEventChangeValue = undefined;
        this._externalFunctionValidValue = undefined;
        this._errorText_observable = undefined;

        if (typeof initData?.externalEventChangeValue === 'function') {
            this._externalEventChangeValue = initData.externalEventChangeValue;
        }

        if (typeof initData?.externalFunctionValidValue === 'function') {
            this._externalFunctionValidValue = initData.externalFunctionValidValue;
        }

        makeObservable<this,
            | '_value_observable'
            | '_errorText_observable'>(this, {
            _value_observable: observable.ref,
            _errorText_observable: observable.ref,
            eventChangeValue: action,
            resetErrorText: action,
            setErrorText: action,
            validValue: action,
            resetDefaultData: action,
            errorText: computed,
            value: computed,
            isHasError: computed,
        });
    }
}
