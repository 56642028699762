import {BaseStoreUserPageContent, InitDataBaseStoreUserPageContent} from "../base/baseStoreUserPageContent";
import {getRequestErrorText} from "../../../api/helpers/getRequestErrorText";
import {RoomVMExtended} from "../../../api/src";
import {RoomItemData} from "../../../interfaces/api/roomItemData";
import {StoreUserRoomPhotos} from "./storeUserRoomPhotos";
import {StoreApiUser} from "../../../api/storeApiUser";
import {RouterPagesKeysUser} from "../../../staticData/routerPages/routerPagesUser";
import {StoreCommonFilter} from "../../common/filter/storeCommonFilter";
import {convertServerRoomsListModelToClientModel} from "../../../helpers/convertServerRoomsModelToClientModel";
import getFilterParametersOnUrl from "../../../helpers/getFilterParametersOnUrl";
import {StoreUserRoomsRecommended} from "./recommended/storeUserRoomsRecommended";
import {StoreBookingRoom} from "../../common/bookingRoom/storeBookingRoom";

export class StoreUserPageContentRooms extends BaseStoreUserPageContent<RoomItemData, StoreApiUser, RouterPagesKeysUser> {
    public readonly storeCommonFilter: StoreCommonFilter;
    public readonly storeUserRoomPhotos: StoreUserRoomPhotos;
    public readonly storeUserRoomsRecommended: StoreUserRoomsRecommended;
    public readonly storeBookingRoom: StoreBookingRoom;

    constructor(initData: InitDataBaseStoreUserPageContent<RoomItemData, StoreApiUser, RouterPagesKeysUser>) {
        super(initData);

        this.eventShowPopupBooking = this.eventShowPopupBooking.bind(this);

        this.storeCommonFilter = new StoreCommonFilter({
            storeApi: this._storeApi,
            storeRouter: this._storeRouter,
            storeFullScreenPopup: this._storeFullScreenPopup
        });

        this.storeUserRoomPhotos = new StoreUserRoomPhotos({
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup
        });

        this.storeUserRoomsRecommended = new StoreUserRoomsRecommended({
            storeApi: this._storeApi,
            storeRouter: this._storeRouter
        });

        this.storeBookingRoom = new StoreBookingRoom({
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup
        });

        this.serverRequestGetAllStoreData = this.serverRequestGetAllStoreData.bind(this);
    }

    public async serverRequestGetAllStoreData() {
        const filterOptions = getFilterParametersOnUrl();

        this._storeApi.apiBooking.bookingSearchFreeHotelRoomsGet(
            filterOptions.checkIn ? new Date(filterOptions.checkIn).toISOString() : undefined,
            filterOptions.checkOut ? new Date(filterOptions.checkOut).toISOString() : undefined,
            filterOptions.roomCount,
            filterOptions.capacity,
            filterOptions.withKids,
            filterOptions.categoryId
        )
            .then((inputData) => {
                const rooms: RoomVMExtended[] = inputData.data;
                // Запоминаем данные
                this._storeDataManager.dataForManage = convertServerRoomsListModelToClientModel(rooms);
                // Устанавливаем данные
                this.storeDataPagination.setDataListForPagination({
                    dataListForPagination: this._storeDataManager.dataForManage,
                    isResetSelectedCurrentPage: true,
                    isResetSelectedElementsOnPage: true
                });
            })
            .catch(error => {
                // Получаем текст ошибки
                const errorText: string = getRequestErrorText(error);
                // Показываем ошибку получения данных
                this._setErrorWhileGettingData(errorText);
            });
    }

    /**
     * Показать окно
     * @param internalId
     */
    public eventShowPopupBooking(internalId: string) {
        const item: RoomItemData | undefined = this._getItemByInternalId(internalId);
        this.storeBookingRoom.showPopupBookingRoom(item)
    }
}
