import {
    BookingApi,
    Configuration,
    FeedbackApi,
    HotelApi,
    HotelInfoVM,
    HotelSpecificationVM,
    RoomApi,
    TokenResultVM
} from "./src";
import {connectKeys} from "./connectKeys";
import {action, computed, makeObservable, observable} from "mobx";
import {StoreHotelSpecificationInfo} from "../stores/common/storeHotelSpecificationInfo";
import {StoreUserHotelInfo} from "../stores/user/hotelInfo/storeUserHotelInfo";

export interface AuthApi {
    readonly access_token: string;
    readonly expires_in: number;
    readonly token_type: string;
    readonly scope: string;
    readonly tokensGetTimestamp: number;
}

export class BaseApiStore {
    public apiBooking: BookingApi;
    public apiFeedback: FeedbackApi;
    public apiRoom: RoomApi;
    public apiHotel: HotelApi;

    // Данные токена
    public _authApi: AuthApi | null;
    // Url для коннекта к Id-Server
    private readonly _connectUrl: string | null;
    // Ошибка определения Url для коннекта к Id-Server
    private  _errorConnectUrl: string | undefined;
    // Идентификатор клиента из Url адреса
    private _clientId: string | null;
    // Ошибка определения идентификатора клиента
    private _errorSearchClientId: string | undefined;
    // Ошибка получения данных для авторизации
    private _errorAuthApi: string | undefined;

    // Ключи для localStorage
    private readonly _localStorageKeyAuthApi: string;
    private readonly _localStorageClientId: string;
    private readonly _localStorageConnectUrl: string;

    public readonly storeHotelSpecificationInfo: StoreHotelSpecificationInfo;
    public readonly storeUserHotelInfo: StoreUserHotelInfo;

    constructor() {
        this._localStorageKeyAuthApi = 'authApi';
        this._localStorageClientId = 'clientId';
        this._localStorageConnectUrl = 'connectUrl';

        this._errorSearchClientId = undefined;
        this._errorConnectUrl = undefined;

        // Читаем данные из LocalStorage
        this._authApi = this._readAuthApiToLocalStorage();
        this._clientId = this._readClientIdToLocalStorage();
        this._connectUrl = this._readConnectUrlToLocalStorage();

        this.apiBooking = new BookingApi();
        this.apiFeedback = new FeedbackApi();
        this.apiRoom = new RoomApi();
        this.apiHotel = new HotelApi();

        this.storeHotelSpecificationInfo = new StoreHotelSpecificationInfo();
        this.storeUserHotelInfo = new StoreUserHotelInfo();

        makeObservable<this,
            '_authApi'
            | '_setValidAuthApi'>(this, {
            _authApi: observable,
            _setValidAuthApi: action,
            isSetAuthApiData: computed
        });
    }

    private _getClientIdFromUrl(): string | null {
        const urlWithHash: string = document.location.href;
        const validUrl: string = urlWithHash.replace(/(#)|(\/#)/g, '');
        const url: URL = new URL(validUrl);
        return url.searchParams.get('clientId');
    }

    /**
     * Установить данные ClientId из Url
     * @private
     */
    private _setClientIdToLocalStorageFromUrl() {
        const clientId = this._getClientIdFromUrl();
        if (clientId) {
            localStorage.setItem(this._localStorageClientId, JSON.stringify(clientId));
        }
    }

    get errorSearchClientId(): string | undefined {
        return this._errorSearchClientId;
    }

    get errorConnectUrl(): string | undefined {
        return this._errorConnectUrl;
    }

    protected _setErrorAuthApi(error: string | undefined) {
        this._errorAuthApi = error;
    }

    get errorAuthApi(): string | undefined {
        return this._errorAuthApi;
    }

    private _readConnectUrlToLocalStorage(): string | null {
        const lsItem: string | null = localStorage.getItem(this._localStorageConnectUrl);

        if (!lsItem) {
            return null;
        }

        return lsItem;
    }

    /**
     * Прочитать данные ClientId из LocalStorage
     * @private
     */
    private _readClientIdToLocalStorage(): string | null {
        const lsItem: string | null = localStorage.getItem(this._localStorageClientId);

        if (!lsItem) {
            return null;
        }

        return lsItem;
    }

    /**
     * Прочитать данные AuthApi из LocalStorage
     * @private
     */
    private _readAuthApiToLocalStorage(): AuthApi | null {
        const lsItem: string | null = localStorage.getItem(this._localStorageKeyAuthApi);

        if (!lsItem) {
            return null;
        }

        const parseData: AuthApi = JSON.parse(lsItem);

        //Так как берем данные из LocalStorage пользоватWQAAAA==ель может изменить их, проводим полную проверку
        if (parseData.hasOwnProperty('expires_in')) {
            if (typeof parseData.expires_in !== 'number') {
                return null
            }
        } else {
            return null;
        }

        if (parseData.hasOwnProperty('token_type')) {
            if (typeof parseData.token_type !== 'string') {
                return null;
            }
        } else {
            return null;
        }

        if (parseData.hasOwnProperty('access_token')) {
            if (typeof parseData.access_token !== 'string') {
                return null;
            }
        } else {
            return null;
        }

        if (parseData.hasOwnProperty('scope')) {
            if (typeof parseData.scope !== 'string') {
                return null;
            }
        } else {
            return null;
        }

        if (parseData.hasOwnProperty('tokensGetTimestamp')) {
            if (typeof parseData.tokensGetTimestamp !== 'number') {
                return null;
            }
        } else {
            return null;
        }

        const currentDateTimestamp: number = +new Date();
        const tokenDateExpire = parseData.tokensGetTimestamp + parseData.expires_in * 1000;

        //Если истек tokenRefresh очищаем LocalStorage
        if (tokenDateExpire <= currentDateTimestamp) {
            //Очищаем LocalStorage, ключ истек
            this._clearAuthApiToLocalStorage();
            return null;
        }

        return parseData;
    }

    /**
     * Возвращает текущую конфигурацию API
     * @protected
     */
    protected async _getApiConfiguration() {
        let getAuthApi = !this._authApi; // Флаг необходимости запроса токена
        let clientIdFromUrl: string | null = this._getClientIdFromUrl(); // clientId из url

        if (this._clientId) {
            this._clientId = this._clientId.replace(/["']/g,'');
        }

        if (!this._clientId && clientIdFromUrl || this._clientId && clientIdFromUrl && this._clientId !== clientIdFromUrl) {
            getAuthApi = true;
            this._clientId = clientIdFromUrl;
            this._setClientIdToLocalStorageFromUrl();
        }

        if (!this._clientId) {
            this._errorSearchClientId = 'Не найден идентификатор отеля';
            return;
        }

        if (!this._connectUrl) {
            this._errorConnectUrl = 'Не найден Url-адрес для подключения';
            return;
        }

        if (getAuthApi) {
            const response = await fetch(this._connectUrl + '/connect/token', {
                method: 'POST',
                body: "grant_type=" + connectKeys.grant_type + "&scope=" + connectKeys.scope + "&client_id=" + this._clientId + "&client_secret=" + connectKeys.client_secret,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            });

            if (!response.ok) {
                this._errorAuthApi = 'Некорректный идентификатор отеля';
            }

            const data = await response.json();
            const validAuthData: AuthApi | undefined = this._validationApiAuthData(data);

            if (!validAuthData) {
                this._errorAuthApi = 'Невалидные данные авторизации';
                return;
            }

            // Устанавливаем новые токены
            this._setValidAuthApi(validAuthData);
        }

        const apiConfig = new Configuration({
            basePath: location.origin,
            apiKey: this._authApi ? `Bearer ${this._authApi.access_token}` : ''
        });

        this._setApiConfig(apiConfig);
        this._serverRequestGetHotelSpecification();
        this._serverRequestGetHotelInfo();
    }

    private _setApiConfig(apiConfig: Configuration) {
        this.apiBooking = new BookingApi(apiConfig);
        this.apiFeedback = new FeedbackApi(apiConfig);
        this.apiRoom = new RoomApi(apiConfig);
        this.apiHotel = new HotelApi(apiConfig);
    }

    private _serverRequestGetHotelSpecification() {
        this.apiHotel.hotelGetHotelSpecificationGet()
            .then((inputData) => {
                const hotelSpecification: HotelSpecificationVM = inputData.data;
                // Валидация данных
                const validHotelSpecification = this.storeHotelSpecificationInfo.validationHotelSpecification(hotelSpecification);
                // Запоминаем данные спецификации отеля
                this.storeHotelSpecificationInfo.setHotelSpecificationInfo(validHotelSpecification);
            })
            .catch(() => {
                // Сбрасываем информацию о спецификации отеля
                this.storeHotelSpecificationInfo.resetHotelSpecificationInfo();
            });
    }

    /**
     * Получение информации об отеле
     * @private
     */
    private _serverRequestGetHotelInfo() {
        this.apiHotel.hotelGetHotelInfoGet()
            .then((inputHotelInfo) => {
                const hotelInfo: HotelInfoVM = inputHotelInfo.data;
                // Валидация данных
                const validHotelInfo = this.storeUserHotelInfo.validationHotelInfo(hotelInfo);
                // Запоминаем данные
                this.storeUserHotelInfo.setHotelInfo_action(validHotelInfo);
            })
            .catch((errorText) => {
                // Устанавливаем ошибку
                this.storeUserHotelInfo.resetHotelInfo();
            });
    }

    get isSetAuthApiData(): boolean {
        return !!this._authApi;
    }

    /**
     * Устанавливает валидные данные авторизации
     * @param validAuthData
     * @private
     */
    private _setValidAuthApi(validAuthData: AuthApi) {
        this._authApi = {
            access_token: validAuthData.access_token,
            expires_in: validAuthData.expires_in,
            token_type: validAuthData.token_type,
            scope: validAuthData.scope,
            tokensGetTimestamp: validAuthData.tokensGetTimestamp,
        };

        this._saveAuthApiToLocalStorage();
    }

    /**
     * Установить ключи API
     * Если вернет true ключи успешно установлены, false - валидация не пройдена
     * @param authApiData
     */
    public setAuthApi(authApiData: TokenResultVM): boolean {
        const validData = this._validationApiAuthData(authApiData);

        if (!validData) {
            return false;
        }

        this._setValidAuthApi(validData);
        return true;
    }

    /**
     * Сохранить данные AuthApi в LocalStorage
     * @private
     */
    private _saveAuthApiToLocalStorage() {
        localStorage.setItem(this._localStorageKeyAuthApi, JSON.stringify(this._authApi));
    }

    /**
     * Очистить данные авторизации из LocalStorage
     * @private
     */
    private _clearAuthApiToLocalStorage() {
        localStorage.removeItem(this._localStorageKeyAuthApi);
    }

    /**
     * Проводит проверку данных полученных от API, возвращает валидный формат
     * @param authData
     * @private
     */
    private _validationApiAuthData(authData: TokenResultVM): AuthApi | undefined {
        if (!authData.access_token || !authData.scope || !authData.token_type || !authData.expires_in) {
            return undefined;
        }

        return {
            access_token: authData.access_token,
            expires_in: authData.expires_in,
            token_type: authData.token_type,
            scope: authData.scope,
            tokensGetTimestamp: +new Date(),
        }
    }
}
