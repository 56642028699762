import {BaseStoreUserPageContent, InitDataBaseStoreUserPageContent} from "../base/baseStoreUserPageContent";
import {v4 as uuidv4} from "uuid";
import {RouterPagesKeysUser} from "../../../staticData/routerPages/routerPagesUser";
import {StoreApiUser} from "../../../api/storeApiUser";
import {CategoryItemData} from "../../../interfaces/api/categoryItemData";
import {getRequestErrorText} from "../../../api/helpers/getRequestErrorText";
import {RoomCategoryVMExtended} from "../../../api/src";
import {StoreUserHotelInfo} from "../hotelInfo/storeUserHotelInfo";
import {StoreCommonFilter} from "../../common/filter/storeCommonFilter";

interface InitDataStoreUserPageContentHome extends InitDataBaseStoreUserPageContent<CategoryItemData, StoreApiUser, RouterPagesKeysUser> {
    storeUserHotelInfo: StoreUserHotelInfo;
}

export class StoreUserPageContentHome extends BaseStoreUserPageContent<CategoryItemData, StoreApiUser, RouterPagesKeysUser> {
    public readonly storeCommonFilter: StoreCommonFilter;
    private readonly _storeUserHotelInfo: StoreUserHotelInfo;

    constructor(initData: InitDataStoreUserPageContentHome) {
        super(initData);

        this._storeUserHotelInfo = initData.storeUserHotelInfo;

        this.storeCommonFilter = new StoreCommonFilter({
            storeApi: this._storeApi,
            storeRouter: this._storeRouter,
            storeFullScreenPopup: this._storeFullScreenPopup
        });
    }

    get storeUserHotelInfo() {
        return this._storeUserHotelInfo;
    }

    public serverRequestGetAllStoreData() {
        this._storeApi.apiRoom.roomGetRoomCategoriesGet()
            .then((inputData) => {
                const categories: RoomCategoryVMExtended[] = inputData.data;
                // Запоминаем данные
                this._storeDataManager.dataForManage = this._validationItemsList(categories);
                // Устанавливаем данные
                this.storeDataPagination.setAllDataList(this._storeDataManager.dataForManage);
            })
            .catch(error => {
                // Получаем текст ошибки
                const errorText: string = getRequestErrorText(error);
                // Показываем ошибку получения данных
                this._setErrorWhileGettingData(errorText);
            });
    }

    protected _validationItemOverride(item: RoomCategoryVMExtended): CategoryItemData | undefined {
        const {
            id,
            title,
            description,
            hotelID
        } = item;

        if (typeof id !== 'number') {
            return undefined;
        }

        return {
            internalId: uuidv4(),
            id: id,
            title: title ?? 'Неизвестно',
            description: description ?? 'Неизвестно',
            hotelId: hotelID ?? -1,
        }
    }
}
