export function getRoomIdByUrl(): number | undefined {
    const url: string = location.href;

    if (!url) {
        return -1;
    }

    const splitArrTmp: string[] = url.split('/');

    if (!Array.isArray(splitArrTmp)) {
        return -1;
    }

    if (!splitArrTmp.length) {
        return -1;
    }

    const lastItem: number = Number(splitArrTmp[splitArrTmp.length - 1]);
    // Пробуем получить id комнаты
    return isNaN(lastItem) ? undefined : lastItem;
}
