import React from "react";
import {ButtonsContainer} from "../../buttonsContainer";
import PopupFullScreenWithEvents, {PopupFullScreenBasicProps} from "../../popupFullscreen/popupFullScreenWithEvents";
import {StandardButton} from "../../standardButton";
import styles from "../standardsPopupsFullscreenStyles.scss";
import {LinkAsButton} from "../../linkAsButton";
import {Player} from "@lottiefiles/react-lottie-player";
import success from "../../../../lottieAnimation/success.json";

const PLAYER_LOTTIE_STYLE = {height: '200px', width: '250px'};

const TEXT_BUTTON_CLOSE: string = 'Закрыть';

export interface PopupFullScreenSuccessProps extends PopupFullScreenBasicProps {
    successText: string;
    textBtnClose?: string;
    buttonCloseLinkHref?: string;
}

function PopupFullScreenSuccess(props: PopupFullScreenSuccessProps) {
    return (
        <PopupFullScreenWithEvents
            eventClose={props.eventClose}
        >
            <Player
                autoplay
                loop={false}
                src={success}
                keepLastFrame={true}
                style={PLAYER_LOTTIE_STYLE}
            />
            <div className={styles.alertTextContainer}>{props.successText}</div>
            <ButtonsContainer>
                {
                    props.buttonCloseLinkHref ?
                        <LinkAsButton
                            eventClick={props.eventClose}
                            href={props.buttonCloseLinkHref}
                            text={props.textBtnClose ?? TEXT_BUTTON_CLOSE}
                            type={'ok'}
                            className={styles.linkAsButton}
                        /> :
                        <StandardButton
                            type={'ok'}
                            text={props.textBtnClose ?? TEXT_BUTTON_CLOSE}
                            eventClick={props.eventClose}
                        />
                }
            </ButtonsContainer>
        </PopupFullScreenWithEvents>
    );
}

export default React.memo(PopupFullScreenSuccess);
