import {action, computed, makeObservable, observable} from "mobx";
import {StoreDataManager} from "../../common/storeDataManager";
import {StoreDataPagination} from "../../common/storeDataPagination";
import {StoreRouter} from "../../common/storeRouter";
import {StoreSitePageTitle} from "../../common/storeSitePageTitle";
import {StoreFullScreenPopup} from "../../common/storeFullScreenPopup";
import {DataWithInternalId} from "../../../interfaces/dataWithInternalId";
import {BaseApiStore} from "../../../api/baseApiStore";

export interface InitDataBaseStoreUserPageContent<DataItemType extends DataWithInternalId, StoreApi extends BaseApiStore, RouterPagesKeys extends string> {
    readonly storeRouter: StoreRouter<RouterPagesKeys>;
    readonly storeApi: StoreApi;
    readonly storeFullScreenPopup: StoreFullScreenPopup;
    readonly storeSitePageTitle: StoreSitePageTitle;
    readonly linkBack?: string;
}

/**
 * Базовый класс Store контента страницы
 */
export class BaseStoreUserPageContent<DataItemType extends DataWithInternalId, StoreApi extends BaseApiStore, RouterPagesKeys extends string> {
    protected readonly _storeApi: StoreApi;
    protected readonly _storeRouter: StoreRouter<RouterPagesKeys>;
    protected readonly _storeFullScreenPopup: StoreFullScreenPopup;
    protected readonly _storeSitePageTitle: StoreSitePageTitle;
    protected readonly _linkBack?: string;
    // Справочные данные для редакторов получены или нет
    protected _referenceDataReceived: boolean;
    //endregion

    //region Управление данными
    protected readonly _storeDataManager: StoreDataManager<DataItemType>;
    public readonly storeDataPagination: StoreDataPagination<DataItemType>;
    //endregion

    //region Спрятать редактор данных
    private _isHideEditorData_observable: boolean;

    protected _setShowHideEditorDataStatus(isHide: boolean) {
        this._isHideEditorData_observable = isHide;
    }

    //endregion

    //region Ошибка при получении данных
    /**
     * Ошибка при получении данных
     * @private
     */
    private _errorWhileGettingData_observable?: string;

    /**
     * Установить ошибку при получении данных
     * @param errorText
     * @protected
     */
    protected _setErrorWhileGettingData(errorText: string | undefined) {
        this._errorWhileGettingData_observable = errorText;
    }

    /**
     * Ошибка при получении данных
     */
    get errorWhileGettingData() {
        return this._errorWhileGettingData_observable;
    }

    //endregion

    //region Проверка элементов
    /**
     * Возвращает корректный список элементов справочника
     * @param itemsList
     * @protected
     */
    protected _validationItemsList(itemsList: any[]): DataItemType[] {
        const resultItemsList: DataItemType[] = [];

        if (!itemsList.length) {
            return resultItemsList;
        }

        for (let i = 0; i < itemsList.length; ++i) {
            const validItem = this._validationItemOverride(itemsList[i]);

            if (!validItem) {
                continue;
            }

            resultItemsList.push(validItem);
        }

        return resultItemsList;
    }

    //endregion

    /**
     * Событие обновить отображаемые данные
     * @private
     */
    protected _eventUpdateDisplayedData() {
        this.storeDataPagination.setDataListForPagination({
            isResetSelectedElementsOnPage: true,
            isResetSelectedCurrentPage: true,
            dataListForPagination: this._storeDataManager.dataForManage
        });
    }

    /**
     * Событие применить все фильтры и сортировку
     * @param inputData
     * @private
     */
    private _eventApplyAllFiltersAndSorting(inputData: DataItemType[]): DataItemType[] {
        return this._applyAllFiltersAndSortingOverride(inputData);
    }

    //endregion

    /**
     * Проверка элемента справочника
     * @param item - Элемент для проверки
     * @protected
     */
    protected _validationItemOverride(item: any): DataItemType | undefined {
        return undefined;
    }

    /**
     * Запрос на сервер, на получение всех необходимых данных
     */
    public serverRequestGetAllStoreData() {
        this._storeFullScreenPopup.loading = {
            loadingText: 'Загрузка данных'
        };
    }

    /**
     * Применить все активные фильтры и сортировку
     * @param inputData
     * @protected
     */
    protected _applyAllFiltersAndSortingOverride(inputData: DataItemType[]): DataItemType[] {
        return inputData;
    }

    //endregion

    //region Прочее
    /**
     * Получить элемент по data атрибуту
     * @param element
     * @private
     */
    protected _getItemByDataAttribute(element: HTMLElement): DataItemType | undefined {
        const uuid: string | null = element.getAttribute('data-id');

        if (!uuid) {
            return undefined;
        }

        const targetItem = this._storeDataManager.getItemByInternalId(uuid);

        if (!targetItem) {
            return undefined;
        }

        return Object.assign({}, targetItem);
    }

    get storeRouter() {
        return this._storeRouter;
    }

    get linkBack() {
        return this._linkBack;
    }

    /**
     * Получить элемент
     * @param internalId
     * @private
     */
    protected _getItemByInternalId(internalId: string): DataItemType | undefined {
        const targetItem = this._storeDataManager.getItemByInternalId(internalId);

        if (!targetItem) {
            return undefined;
        }

        return Object.assign({}, targetItem);
    }

    constructor(initData: InitDataBaseStoreUserPageContent<DataItemType, StoreApi, RouterPagesKeys>) {
        this._eventUpdateDisplayedData = this._eventUpdateDisplayedData.bind(this);
        this._eventApplyAllFiltersAndSorting = this._eventApplyAllFiltersAndSorting.bind(this);

        this._errorWhileGettingData_observable = undefined;
        this._isHideEditorData_observable = false;

        this._storeApi = initData.storeApi;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;
        this._storeRouter = initData.storeRouter;
        this._storeSitePageTitle = initData.storeSitePageTitle;
        this._linkBack = initData.linkBack;
        this._storeDataManager = new StoreDataManager();
        this.storeDataPagination = new StoreDataPagination<DataItemType>();
        this._referenceDataReceived = false;

        makeObservable<this,
            // Ошибка при получении данных
            '_errorWhileGettingData_observable'
            | '_setErrorWhileGettingData'>(this, {
            // Ошибка при получении данных
            _errorWhileGettingData_observable: observable.ref,
            _setErrorWhileGettingData: action,
            errorWhileGettingData: computed
        });
    }
}
