export function convertDateToEndToStringUTC(date: Date | null): string | undefined {
    if (!date) {
        return undefined;
    }

    return new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59
    )).toISOString();
}
