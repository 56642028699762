export default function dateFormatMonthDayYear(d: Date):string {
    let day: string = String(d.getDate());

    if (day.length === 1) {
        day = "0" + day;
    }

    let month: string = String(d.getMonth() + 1);
    if (month.length === 1) {
        month = "0" + month;
    }

    const year: string = String(d.getFullYear());
    return `${month}.${day}.${year}`;
}
