import {action, computed, makeObservable, observable} from "mobx";
import {DataWithInternalId} from "../../interfaces/dataWithInternalId";

interface InitDataStoreEditPhotosDataInPopup<DataItem, StoreEditor> {
    storeEditor: StoreEditor
}

export class StoreEditDataInPopup<DataItem extends DataWithInternalId, StoreEditor> {

    //region Store редактирования данных
    private readonly _storeEditor: StoreEditor;

    get storeEditor() {
        return this._storeEditor;
    }
    //endregion

    //region Показать/скрыть окно
    /**
     * Показать/скрыть popup.
     * ВАЖНО, только скрывает окно не удаляя данные
     * @private
     */
    private _isShowPopup_observable: boolean;

    public setIsShowPopup(isShow: boolean){
        this._isShowPopup_observable = isShow;
    }

    get isShowPopup(){
        return this._isShowPopup_observable;
    }
    //endregion

    constructor(initData: InitDataStoreEditPhotosDataInPopup<DataItem[], StoreEditor>) {
        this._storeEditor = initData.storeEditor;
        this._isShowPopup_observable = true;

        makeObservable<this,
            '_isShowPopup_observable'>(this, {
            _isShowPopup_observable: observable,
            setIsShowPopup: action,
            isShowPopup: computed,
        });
    }
}
