import {BookingRoom} from "../../../interfaces/api/bookingRoom";
import {StoreComponentInputText} from "../storeComponentInputText";
import validationStringStandard from "../../../helpers/validation/validationStringStandard";
import {StoreComponentDateRangePicker} from "../storeComponentDateRangePicker";
import {StoreComponentStandardCheckbox} from "../storeComponentStandardCheckbox";
import {StoreComponentDatesRange} from "../storeComponentDatesRange";

interface InitDataStoreAdminPopupTopUpBalance {
    readonly eventSaveData: (data: BookingRoom) => void;
    readonly eventClosePopup: () => void;
    readonly roomId: number;
}

export class StorePopupBooking {
    private readonly _externalEventClosePopup: () => void;
    private readonly _externalEventSaveData: (data: BookingRoom) => void;
    private readonly _roomId: number;

    public readonly storeDateRangePicker: StoreComponentDatesRange;
    public readonly storeEditName: StoreComponentInputText;
    public readonly storeEditPhone: StoreComponentInputText;
    public readonly storeEditIsCheckPersonalData: StoreComponentStandardCheckbox;

    public eventClosePopup() {
        this._externalEventClosePopup();
    }

    public eventSaveData() {
        const {dateStart, dateEnd} = this.storeDateRangePicker.getSelectedDateRange();
        const validName = this.storeEditName.validValue();
        const validPhone = this.storeEditPhone.validValue();
        const validFeedbackCheckPersonalData = this.storeEditIsCheckPersonalData.isCheck;

        if (!validFeedbackCheckPersonalData) {
            this.storeEditIsCheckPersonalData.setErrorText('Согласитесь с условиями')
        }

        if (!dateStart) {
            this.storeDateRangePicker.setErrorText('Дата заезда не задана');
            return;
        }

        if (!dateEnd) {
            this.storeDateRangePicker.setErrorText('Дата выезда не задана');
            return;
        }

        if (
            validName.errorText
            || validPhone.errorText
            || !dateStart
            || !dateEnd
            || this.storeEditIsCheckPersonalData.errorText
        ) {
            return;
        }

        this._externalEventSaveData({
            roomId: this._roomId,
            name: validName.validValue,
            phone: validPhone.validValue,
            checkIn: dateStart,
            checkOut: dateEnd
        });
    }

    constructor(initData: InitDataStoreAdminPopupTopUpBalance) {
        this.eventClosePopup = this.eventClosePopup.bind(this);
        this.eventSaveData = this.eventSaveData.bind(this);
        this._externalEventClosePopup = initData.eventClosePopup;
        this._externalEventSaveData = initData.eventSaveData;

        this._roomId = initData.roomId;

        this.storeDateRangePicker = new StoreComponentDatesRange({
            dateRange: {
                dateStart: null,
                dateEnd: null,
            },
            isResetErrorOnChangeValue: true
        });

        this.storeEditName = new StoreComponentInputText({
            value: undefined,
            isResetErrorOnChangeValue: true,
            externalFunctionValidValue: (inputName) =>
                validationStringStandard({
                    minLength: 5,
                    maxLength: 30,
                    stringToCheck: inputName
                })
        });

        this.storeEditPhone = new StoreComponentInputText({
            value: undefined,
            isResetErrorOnChangeValue: true,
            externalFunctionValidValue: (inputPhone) =>
                validationStringStandard({
                    minLength: 11,
                    maxLength: 30,
                    stringToCheck: inputPhone
                })
        });

        this.storeEditIsCheckPersonalData = new StoreComponentStandardCheckbox({
            isCheck: false,
            isResetErrorOnChangeValue: true
        });
    }
}
