import {ValidationValueResult} from "../../interfaces/validationValueResult";

const TEXT_ERROR_EMPTY_EMAIL: string = 'Поле email не может быть пустым';
const TEXT_ERROR_INCORRECT_EMAIL: string = 'Некорректный email';

export function validationEmail(inputEmail: string| null | undefined): ValidationValueResult<string> {
    if(typeof inputEmail!== 'string') {
        return  {
            errorText: TEXT_ERROR_EMPTY_EMAIL,
            validValue: ''
        };
    }

    let copyEmailValue: string = inputEmail.trim();
    if (!copyEmailValue) {
        return {
            errorText: TEXT_ERROR_EMPTY_EMAIL,
            validValue: ''
        };
    }

    const regExpStr: string = `[a-zA-Z0-9.!#$%&'*\\+\\-\\/=?^_\`{|}~]`;
    const regExpGeneralStr: string = `^(${regExpStr}{3,}[@]{1}${regExpStr}{2,}[.]{1}${regExpStr}+)$`;
    const regExpEmail = new RegExp(regExpGeneralStr, 'g');
    const checkEmail: boolean = regExpEmail.test(inputEmail);

    if (!checkEmail) {
        return {
            errorText: TEXT_ERROR_INCORRECT_EMAIL,
            validValue: ''
        };
    }

    return {
        errorText: undefined,
        validValue: copyEmailValue
    };
}