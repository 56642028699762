import React from "react";
import {HeaderDesktop, HeaderMobileUnAuthorized} from "../header";
import {SiteTopMenuContainer} from "../../siteTopMenuContainer";

function SiteTopMenuUnauthorizedUser() {
    return (
        <SiteTopMenuContainer>
            <HeaderDesktop/>
            <HeaderMobileUnAuthorized/>
        </SiteTopMenuContainer>
    );
}

export default React.memo(SiteTopMenuUnauthorizedUser);
