import React from "react";
import styles from './pageHeaderItemLinkStyle.scss';
import {NavLink} from "react-router-dom";
import {SitePageInfo} from "../../../../../interfaces/sitePageInfo";

interface PageHeaderItemLinkProps {
    pageInfo: SitePageInfo;
}

function getClassNameRouterLink(inputOptions: { isActive: boolean }): string {
    if (inputOptions.isActive) {
        return `${styles.routerLinkActive} ${styles.componentContainer}`
    } else {
        return styles.componentContainer;
    }
}

function PageHeaderItemLink(props: PageHeaderItemLinkProps) {
    if (props.pageInfo.menu) {
        return (
            <NavLink
                className={getClassNameRouterLink}
                to={props.pageInfo.fullPath}
            >
                <div className={styles.icoContainer}>
                    {props.pageInfo.menu.icon}
                </div>
                <span>{props.pageInfo.menu.publicName}</span>
            </NavLink>
        );
    }

    return null;
}

export default React.memo(PageHeaderItemLink);
