import React from "react";
import styles from './pageLinkBackStyle.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

interface PageLinkBackProps {
    linkTitle: string;
    eventBackClick?: () => void;
    linkBack?: string;
}

export default function PageLinkBack(props: PageLinkBackProps) {
    return (
        <div className={styles.linkTitle}>
            {
                props.eventBackClick ?
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        className={styles.backIcon}
                        onClick={props.eventBackClick}
                    /> :
                    props.linkBack ?
                        <Link to={props.linkBack}>
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                className={styles.backIcon}
                            />
                        </Link> : null
            }
            <span>{props.linkTitle}</span>
        </div>
    );
}
