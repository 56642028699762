import {Feedback} from "../../../interfaces/api/feedback";
import {StoreComponentInputText} from "../../common/storeComponentInputText";
import {StoreComponentStandardCheckbox} from "../../common/storeComponentStandardCheckbox";
import {validationEmail} from "../../../helpers/validation/validationEmail";
import validationStringStandard from "../../../helpers/validation/validationStringStandard";

type CallbackSaveEditDataType = (editableData: Feedback) => void;
type EventClosePopupType = () => void;

interface InitDataStoreEditorHotelColorScheme {
    callbackSaveEditData: CallbackSaveEditDataType;
    eventClosePopup: EventClosePopupType;
}

export class StoreEditorFeedback {
    public readonly storeInputSubject: StoreComponentInputText;
    public readonly storeInputName: StoreComponentInputText;
    public readonly storeInputEmail: StoreComponentInputText;
    public readonly storeInputMessage: StoreComponentInputText;
    public readonly storeEditIsCheckPersonalData: StoreComponentStandardCheckbox;

    protected _internalEventSaveEditData() {
        const validFeedbackSubject = this.storeInputSubject.validValue();
        const validFeedbackName = this.storeInputName.validValue();
        const validFeedbackEmail = this.storeInputEmail.validValue();
        const validFeedbackMessage = this.storeInputMessage.validValue();
        const validFeedbackCheckPersonalData = this.storeEditIsCheckPersonalData.isCheck;

        if (!validFeedbackCheckPersonalData) {
            this.storeEditIsCheckPersonalData.setErrorText('Согласитесь с условиями')
        }

        if (
            validFeedbackSubject.errorText
            || validFeedbackName.errorText
            || validFeedbackEmail.errorText
            || validFeedbackMessage.errorText
            || this.storeEditIsCheckPersonalData.errorText
        ) {
            return;
        }

        const editItem: Feedback = {
            internalId: '',
            name: validFeedbackName.validValue,
            subject: validFeedbackMessage.validValue,
            email: validFeedbackEmail.validValue,
            message: validFeedbackMessage.validValue
        };

        this._callbackSaveEditData(editItem);
    }

    private readonly _eventClosePopup: EventClosePopupType;
    protected readonly _callbackSaveEditData: CallbackSaveEditDataType;

    //region События
    public eventClosePopup() {
        this._eventClosePopup();
    }

    public eventSaveEditData() {
        this._internalEventSaveEditData();
    }
    //endregion

    constructor(initData: InitDataStoreEditorHotelColorScheme) {
        this._callbackSaveEditData = initData.callbackSaveEditData;
        this._eventClosePopup = initData.eventClosePopup;
        this.eventClosePopup = this.eventClosePopup.bind(this);
        this.eventSaveEditData = this.eventSaveEditData.bind(this);

        this.storeInputSubject = new StoreComponentInputText({
            placeholder: 'Тема письма',
            isResetErrorOnChangeValue: true,
            value: '',
            externalFunctionValidValue: (inputSubject) =>
                validationStringStandard({
                    minLength: 3,
                    maxLength: 80,
                    stringToCheck: inputSubject
                })
        });

        this.storeInputName = new StoreComponentInputText({
            placeholder: 'Имя',
            isResetErrorOnChangeValue: true,
            value: '',
            externalFunctionValidValue: (inputName) =>
                validationStringStandard({
                    minLength: 3,
                    maxLength: 80,
                    stringToCheck: inputName
                })
        });

        this.storeInputEmail = new StoreComponentInputText({
            placeholder: 'Электронная почта',
            isResetErrorOnChangeValue: true,
            externalFunctionValidValue: validationEmail,
            value: ''
        });

        this.storeInputMessage = new StoreComponentInputText({
            placeholder: 'Сообщение',
            isResetErrorOnChangeValue: true,
            value: '',
            externalFunctionValidValue: (inputEmail) =>
                validationStringStandard({
                    minLength: 3,
                    maxLength: 1500,
                    stringToCheck: inputEmail
                })
        });

        this.storeEditIsCheckPersonalData = new StoreComponentStandardCheckbox({
            isCheck: false,
            isResetErrorOnChangeValue: true
        });
    }
}
