import React from "react";
import styles from './loaderSuspenseStyle.scss';

interface LoaderSuspenseProps {
    className?: string;
}

/**
 * Очень легкий статус загрузки
 * ВАЖНО: использовать только с Suspense
 * @constructor
 */
export default function LoaderSuspense(props: LoaderSuspenseProps) {
    return (
        <div className={props.className ? `${styles.container} ${props.className}` : styles.container}>
            <div className={styles.innerContainer}>
                <svg className={styles.spinner} viewBox={'0 0 50 50'}>
                    <circle
                        className={styles.path}
                        cx={'25'}
                        cy={'25'}
                        r={'20'}
                        fill={'none'}
                        strokeWidth={4}
                    />
                </svg>
            </div>
        </div>
    );
}
