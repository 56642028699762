import {RoomPhotoVM} from "../../api/src";
import {RoomFirstPhotoItem} from "../../interfaces/api/roomPhotoCollection";
import {v4 as uuidv4} from "uuid";

export function convertServerRoomFirstPhotoModelToClientModel(photoItem: RoomPhotoVM): RoomFirstPhotoItem | undefined {
    const {
        roomId,
        photo
    } = photoItem;

    if (typeof roomId !== 'number') {
        return undefined;
    }

    return ({
        internalId: uuidv4(),
        roomId: roomId,
        photo: photo?.id && photo?.photo ? {
            internalId: uuidv4(),
            id: photo.id,
            photo: photo.photo
        } : null
    });
}
