import {StoreFullScreenPopup} from "./storeFullScreenPopup";
import {StoreFeedback} from "../user/feedback/storeFeedback";
import {StoreApiUser} from "../../api/storeApiUser";
import {StoreUserHotelCoordinates} from "../user/hotelCoordinates/storeUserHotelCoordinates";
import {StoreUserHotelInfo} from "../user/hotelInfo/storeUserHotelInfo";

interface InitDataStoreSiteBottomMenu {
    readonly storeFullScreenPopup: StoreFullScreenPopup;
    readonly storeApi: StoreApiUser;
    readonly storeUserHotelInfo: StoreUserHotelInfo;
}

export class StoreSiteBottomMenu {
    private readonly _storeFullScreenPopup: StoreFullScreenPopup;
    private readonly _storeApi: StoreApiUser;

    public readonly storeUserHotelInfo: StoreUserHotelInfo;
    public readonly storeFeedback: StoreFeedback;
    public readonly storeUserHotelCoordinates: StoreUserHotelCoordinates;

    constructor(initData: InitDataStoreSiteBottomMenu) {
        this._storeFullScreenPopup = initData.storeFullScreenPopup;
        this._storeApi = initData.storeApi;
        this.storeUserHotelInfo = initData.storeUserHotelInfo;

        this.storeFeedback = new StoreFeedback({
            storeFullScreenPopup: this._storeFullScreenPopup,
            storeApi: this._storeApi
        });

        this.storeUserHotelCoordinates = new StoreUserHotelCoordinates({
            storeApi: initData.storeApi
        });
    }
}
