import {StoreFullScreenPopup} from "../../../common/storeFullScreenPopup";
import {RoomItemData} from "../../../../interfaces/api/roomItemData";
import {action, computed, makeObservable, observable} from "mobx";
import {ErrorText} from "../../../../globalType/ErrorText";
import {RoomVMExtended} from "../../../../api/src";
import {getRequestErrorText} from "../../../../api/helpers/getRequestErrorText";
import {v4 as uuidv4} from "uuid";
import {StoreUserRoomPhotosDetail} from "../photos/storeUserRoomPhotosDetail";
import {StoreUserRoomBooking} from "../booking/storeUserRoomBooking";
import {StoreApiUser} from "../../../../api/storeApiUser";
import {StoreBookingRoom} from "../../../common/bookingRoom/storeBookingRoom";
import {StoreRouter} from "../../../common/storeRouter";
import {RouterPagesKeysUser} from "../../../../staticData/routerPages/routerPagesUser";
import {convertServerRoomModelToClientModel} from "../../../../helpers/validation/convertServerRoomModelToClientModel";

interface InitDataStoreUserRoomDetail {
    storeApi: StoreApiUser;
    storeFullScreenPopup: StoreFullScreenPopup;
    storeRouter: StoreRouter<RouterPagesKeysUser>;
}

export class StoreUserRoomDetail {
    private readonly _storeApi: StoreApiUser;
    private readonly _storeFullScreenPopup: StoreFullScreenPopup;
    private readonly _storeRouter: StoreRouter<RouterPagesKeysUser>;

    public readonly storeUserRoomBooking: StoreUserRoomBooking;
    public readonly storeUserRoomPhotos: StoreUserRoomPhotosDetail;
    public readonly storeBookingRoom: StoreBookingRoom;

    private _roomInfo_observable?: RoomItemData | null;

    private _setRoomInfo_action(roomInfo?: RoomItemData | null) {
        this._roomInfo_observable = roomInfo;
    }

    get roomInfo(): RoomItemData | null | undefined {
        return this._roomInfo_observable;
    }

    private _errorRoomInfo_observable: ErrorText;

    private _setErrorRoomInfo_action(error: ErrorText) {
        this._errorRoomInfo_observable = error;
    }

    get errorRoomInfo(): ErrorText | undefined {
        return this._errorRoomInfo_observable;
    }

    /**
     * Получения данных номера
     * @param roomId
     */
    public serverRequestGetRoomById(roomId: number) {
        this._storeApi.apiRoom.roomGetRoomByIdGet(roomId)
            .then((inputData) => {
                const roomData: RoomVMExtended = inputData.data;
                // Валидация данных
                const validData = convertServerRoomModelToClientModel(roomData);
                // Запоминаем данные
                this._setRoomInfo_action(validData);
                this._setErrorRoomInfo_action(undefined);
            })
            .catch(error => {
                // Получаем текст ошибки
                const errorText: string = getRequestErrorText(error);
                // Показываем ошибку получения данных
                this._setErrorRoomInfo_action(errorText);
            });
    }

    get storeRouter() {
        return this._storeRouter;
    }

    constructor(initData: InitDataStoreUserRoomDetail) {
        this._storeApi = initData.storeApi;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;
        this._storeRouter = initData.storeRouter;

        this.storeUserRoomBooking = new StoreUserRoomBooking({
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup
        });

        this.storeUserRoomPhotos = new StoreUserRoomPhotosDetail({
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup
        });

        this.storeBookingRoom = new StoreBookingRoom({
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup
        });

        this._roomInfo_observable = undefined;
        this._errorRoomInfo_observable = undefined;

        makeObservable<this,
            '_roomInfo_observable'
            | '_errorRoomInfo_observable'
            | '_setRoomInfo_action'
            | '_setErrorRoomInfo_action'>(this, {
            _roomInfo_observable: observable,
            _errorRoomInfo_observable: observable,
            _setRoomInfo_action: action,
            _setErrorRoomInfo_action: action,
            roomInfo: computed,
            errorRoomInfo: computed
        });
    }
}
