import React, {Suspense} from "react";
import styles from '../templatePageAuthUserStyle.scss';
import {Outlet} from "react-router-dom";
import {LoaderSuspense} from "../../../common/loaderSuspense";
import {SubHeaderContainer} from "../../../common/subHeaderContainer";

interface TemplatePageAuthUserProps {
    readonly siteTopMenu?: React.ReactNode;
    readonly siteBottomMenu?: React.ReactNode;
}

function TemplatePageAuthUser(props: TemplatePageAuthUserProps) {
    return (
        <>
            {props.siteTopMenu ? props.siteTopMenu : null}
            <SubHeaderContainer/>
            <div className={styles.contentPageContainer}>
                <Suspense fallback={<LoaderSuspense/>}>
                    <Outlet/>
                </Suspense>
            </div>
            {props.siteBottomMenu ? props.siteBottomMenu : null}
        </>
    );
}

export default React.memo(TemplatePageAuthUser);
