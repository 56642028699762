/**
 * Получение параметров из URL
 */
export default function getFilterParametersOnUrl() {
    const urlWithHash: string = document.location.href;
    const validUrl: string = urlWithHash.replace(/(#)|(\/#)/g, '');
    const url: URL = new URL(validUrl);

    const checkIn: string | null = url.searchParams.get('checkIn');
    const checkOut: string | null = url.searchParams.get('checkOut');
    const roomCount: string | null = url.searchParams.get('roomCount');
    const capacity: string | null = url.searchParams.get('capacity');
    const category: string | null = url.searchParams.get('categoryId');

    return {
        checkIn: checkIn ? checkIn : undefined,
        checkOut: checkOut ? checkOut : undefined,
        roomCount: roomCount ? Number(roomCount) : undefined,
        capacity: capacity ? Number(capacity) : undefined,
        categoryId: category ? Number(category) : undefined,
        withKids: url.searchParams.get('withKids') === 'true'
    }
}
