import React from "react";
import PopupFullscreen from "../../popupFullscreen/popupFullScreen";
import loaderAuthorization from "../../../../lottieAnimation/loadingAnimation.json";
import {Player} from "@lottiefiles/react-lottie-player";
import styles from "../standardsPopupsFullscreenStyles.scss";

export interface PopupFullScreenLoadingProps {
    loadingText: string;
}

function PopupFullScreenLoading(props: PopupFullScreenLoadingProps) {
    return (
        <PopupFullscreen>
            <Player
                autoplay
                loop={true}
                speed={1}
                src={loaderAuthorization}
                style={{
                    height: '250px',
                    width: '250px'
                }}
            />
            <div className={styles.loadingTextContainer}>{props.loadingText}</div>
        </PopupFullscreen>
    );
}

export default React.memo(PopupFullScreenLoading);
