import React from "react";
import {observer} from "mobx-react";
import {StoreFeedback} from "../../../stores/user/feedback/storeFeedback";
import {Feedback} from "../../../interfaces/api/feedback";
import {SmartComponentProps} from "../../../interfaces/smartComponentProps";
import {StoreComponentInputText} from "../../../stores/common/storeComponentInputText";
import {StoreEditDataInPopup} from "../../../stores/common/storeEditDataInPopup";
import {StoreEditorFeedback} from "../../../stores/user/feedback/storeEditorFeedback";
import {InputText} from "../../common/inputText";
import {TextEditorMultiline} from "../../common/textEditorMultiline";
import {PopupDataEditor} from "../../common/popup/popupDataEditor";
import {PopupItemsContainer} from "../../common/popup/popupItemsContainer";
import {PopupItemContainer} from "../../common/popup/popupItemContainer";
import {EditorPersonalData} from "../../common/editorPersonalData";
import styles from "./userSendFeedbackEditorStyle.scss";

const TEXT_POPUP_TITLE: string = 'Обратная связь';
const TEXT_SUBJECT_TITLE: string = 'Тема письма';
const TEXT_NAME_TITLE: string = 'Имя';
const TEXT_EMAIL_TITLE: string = 'Email';
const TEXT_MESSAGE_TITLE: string = 'Сообщение';

const InputEditorText = observer((props: SmartComponentProps<StoreComponentInputText>) => {
    return (
        <InputText
            value={props.store.value}
            eventChange={props.store.eventChangeValue}
            errorText={props.store.errorText}
        />
    );
});

const InputEditorTextArea = observer((props: SmartComponentProps<StoreComponentInputText>) => {
    return (
        <TextEditorMultiline
            value={props.store.value}
            eventChange={props.store.eventChangeValue}
            errorText={props.store.errorText}
        />
    );
});

type StorePopupEditorType = StoreEditDataInPopup<Feedback, StoreEditorFeedback>;

const PopupEditor = React.memo((props: SmartComponentProps<StorePopupEditorType>) => {
    if (!props.store.isShowPopup) {
        return null;
    }

    return (
        <PopupDataEditor
            title={TEXT_POPUP_TITLE}
            eventSaveData={props.store.storeEditor.eventSaveEditData}
            eventCancel={props.store.storeEditor.eventClosePopup}
            eventClose={props.store.storeEditor.eventClosePopup}
            className={styles.popupContainer}
        >
            <PopupItemsContainer>
                <PopupItemContainer title={TEXT_SUBJECT_TITLE} isRequired>
                    <InputEditorText store={props.store.storeEditor.storeInputSubject}/>
                </PopupItemContainer>

                <PopupItemContainer title={TEXT_NAME_TITLE} isRequired>
                    <InputEditorText store={props.store.storeEditor.storeInputName}/>
                </PopupItemContainer>

                <PopupItemContainer title={TEXT_EMAIL_TITLE} isRequired>
                    <InputEditorText store={props.store.storeEditor.storeInputEmail}/>
                </PopupItemContainer>

                <PopupItemContainer title={TEXT_MESSAGE_TITLE} isRequired>
                    <InputEditorTextArea store={props.store.storeEditor.storeInputMessage}/>
                </PopupItemContainer>

                <EditorPersonalData store={props.store.storeEditor.storeEditIsCheckPersonalData}/>
            </PopupItemsContainer>
        </PopupDataEditor>
    );
});

function UserSendFeedbackEditor(props: SmartComponentProps<StoreFeedback>) {
    if (!props.store.storeEditDataInPopup) {
        return null;
    }

    if (!props.store.storeEditDataInPopup.isShowPopup) {
        return null;
    }

    return (
        <PopupEditor store={props.store.storeEditDataInPopup}/>
    );
}

export default observer(UserSendFeedbackEditor);
