import React from "react";
import styles from "./popupItemsContainerStyle.scss";

interface PopupItemsContainerProps {
    readonly children: React.ReactNode;
    readonly className?: string;
}

function PopupItemsContainer(props: PopupItemsContainerProps) {
    return (
        <div className={props.className ? `${props.className} ${styles.container}` : styles.container}>
            {props.children}
        </div>
    );
}

export default React.memo(PopupItemsContainer);
