import {RoomVMExtended} from "../../api/src";
import {RoomItemData} from "../../interfaces/api/roomItemData";
import {v4 as uuidv4} from "uuid";

export function convertServerRoomModelToClientModel(item: RoomVMExtended): RoomItemData | undefined {
    const {
        id,
        hotelID,
        categoryID,
        title,
        description,
        number,
        capacity,
        space,
        price,
        withKids,
        isActive,
        isRecommended,
        shortDescription,
        childAge,
        discount
    } = item;

    if (typeof id !== 'number') {
        return undefined;
    }

    return {
        internalId: uuidv4(),
        id: id,
        hotelId: hotelID ?? -1,
        categoryId: categoryID ?? -1,
        title: title ?? 'Неизвестно',
        description: description ?? 'Неизвестно',
        number: number ?? -1,
        capacity: capacity ?? 0,
        space: space ?? 0,
        price: price ?? 0,
        withKids: withKids ?? false,
        isActive: isActive ?? false,
        isRecommended: isRecommended ?? false,
        shortDescription: shortDescription ?? '',
        childAge: childAge ?? undefined,
        discount: discount ?? undefined
    }
}
