import {getRequestErrorText} from "../../../api/helpers/getRequestErrorText";
import {v4 as uuidv4} from "uuid";
import {RoomEquipmentVMExtended} from "../../../api/src";
import {EquipmentItem, RoomEquipmentItemData} from "../../../interfaces/api/roomEquipmentItemData";
import {StoreUserRoomDetail} from "./room/storeUserRoomDetail";
import {BaseStoreUserPageContent, InitDataBaseStoreUserPageContent} from "../base/baseStoreUserPageContent";
import {StoreApiUser} from "../../../api/storeApiUser";
import {RouterPagesKeysUser} from "../../../staticData/routerPages/routerPagesUser";
import {getRoomIdByUrl} from "../../../helpers/getRoomIdByUrl";

export class StoreUserPageContentDetailRoom extends BaseStoreUserPageContent<RoomEquipmentItemData, StoreApiUser, RouterPagesKeysUser> {
    public readonly storeUserRoomDetail: StoreUserRoomDetail;
    private readonly _roomId?: number;

    constructor(initData: InitDataBaseStoreUserPageContent<RoomEquipmentItemData, StoreApiUser, RouterPagesKeysUser>) {
        super(initData);

        this._roomId = getRoomIdByUrl();

        this.storeUserRoomDetail = new StoreUserRoomDetail({
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup,
            storeRouter: this._storeRouter
        });
    }

    public async serverRequestGetRoomData() {
        if (!this._roomId) {
            this._setErrorWhileGettingData('Номер не найден');
            return;
        }

        this.storeUserRoomDetail.serverRequestGetRoomById(this._roomId);
    }

    public async serverRequestGetEquipmentsStoreData() {
        if (!this._roomId) {
            this._setErrorWhileGettingData('Номер не найден');
            return;
        }

        this._storeApi.apiRoom.roomGetRoomEquipmentsGet(this._roomId)
            .then((inputData) => {
                const roomEquipments: RoomEquipmentVMExtended[] = inputData.data;
                // Запоминаем данные
                this._storeDataManager.dataForManage = this._validationItemsList(roomEquipments);
                this.storeDataPagination.setDataListForPagination({
                    dataListForPagination: this._storeDataManager.dataForManage,
                    isResetSelectedCurrentPage: true,
                    isResetSelectedElementsOnPage: true
                });
            })
            .catch(error => {
                // Получаем текст ошибки
                const errorText: string = getRequestErrorText(error);
                // Показываем ошибку получения данных
                this._setErrorWhileGettingData(errorText);
            });
    }

    protected _validationItemOverride(item: RoomEquipmentVMExtended): RoomEquipmentItemData | undefined {
        const {id, name, roomId, equipment} = item;

        if (typeof id !== 'number') {
            return undefined;
        }

        if (typeof roomId !== 'number') {
            return undefined;
        }

        const validEquipments: EquipmentItem[] = [];

        if (equipment) {
            for (let i = 0; i < equipment.length; ++i) {
                const id: number | undefined = equipment[i].id;

                if (!id) {
                    continue;
                }

                validEquipments.push({
                    internalId: uuidv4(),
                    id: id,
                    name: equipment[i].name ?? 'Неизвестно',
                    price: equipment[i].price ?? undefined
                });
            }
        }

        return {
            internalId: uuidv4(),
            id: id,
            roomId: roomId,
            name: name ?? 'Неизвестно',
            equipment: validEquipments,
        }
    }
}
