import React from "react";
import styles from './buttonsContainerStyle.scss';

interface ButtonsContainerProps {
    children: React.ReactNode;
    className?: string;
    buttonsAlign?: 'center' | 'left' | 'right';
}

/**
 * Контейнер для кнопок
 * Выравнивание кнопок по центру и добавление к ним margin
 * @param props
 * @constructor
 */
function ButtonsContainer(props: ButtonsContainerProps) {
    let cssClass: string = styles.buttonsContainer;

    if (props.buttonsAlign === 'right') {
        cssClass += `${styles.buttonsAlignRight}`;
    } else if (props.buttonsAlign === 'left') {
        cssClass += `${styles.buttonsAlignLeft}`;
    } else {
        cssClass += `${styles.buttonsAlignCenter}`;
    }

    if (props.className) {
        cssClass +=` ${props.className}`;
    }

    return (
        <div className={cssClass}>{props.children}</div>
    );
}

export default React.memo(ButtonsContainer);
