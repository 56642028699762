import {action, computed, makeObservable, observable} from "mobx";
import {ErrorText} from "../../interfaces/errorText";
import {DropdownSelectCurrentSelectedItem, DropdownSelectItem} from "../../interfaces/dropdownSelectItem";

interface InitDataStoreFilterDropdownSelect {
    // Текст когда поле пустое
    placeholder?: string;
    // Внешнее событие изменение данных
    externalEventChangeValue?: (value: DropdownSelectCurrentSelectedItem) => void;
    // Список значений для списка
    optionsList?: DropdownSelectItem[];
    // Сбросить ошибку при изменении данных
    isResetErrorOnChangeValue?: boolean;
    // Выбранное значение
    selectedValue?: DropdownSelectCurrentSelectedItem;
    // Наименование поля
    name?: string;
}

export class StoreComponentDropdownSelect {
    private _optionsList_observable: DropdownSelectItem[];
    private _errorText_observable: ErrorText;
    private _selectedValue_observable?: DropdownSelectCurrentSelectedItem;
    private readonly _isResetErrorOnChangeValue?: boolean;
    private readonly _name?: string;

    private readonly _externalEventChangeValue?: (value: DropdownSelectCurrentSelectedItem) => void;
    public readonly placeholder?: string;

    public eventChangeValue(value: DropdownSelectCurrentSelectedItem) {
        this._selectedValue_observable = value;

        if(this._isResetErrorOnChangeValue) {
            // Сбрасываем ошибку если есть
            if(this._errorText_observable) {
                this._errorText_observable = undefined;
            }
        }

        if (typeof this._externalEventChangeValue === 'function') {
            this._externalEventChangeValue(value);
        }
    }

    public setOptionsList(optionsList: DropdownSelectItem[]) {
        this._optionsList_observable = optionsList;
    }

    public setErrorText(inputErrorText: string) {
        this._errorText_observable = inputErrorText;
    }

    public getSelectedValue() {
        return this._selectedValue_observable;
    }

    public resetError(){
        this._errorText_observable = undefined;
    }

    public resetSelectedValue(){
        this._selectedValue_observable = null;
    }

    get optionsList() {
        return this._optionsList_observable;
    }

    public setSelectedValue(value?: DropdownSelectCurrentSelectedItem) {
        this._selectedValue_observable = value;
    }

    get selectedValue() {
        return this._selectedValue_observable;
    }

    get errorText() {
        return this._errorText_observable;
    }

    get isHasError(): boolean {
        return !!this._errorText_observable;
    }

    public resetStoreData() {
        this._selectedValue_observable = null;
        this._errorText_observable = undefined;
        this._optionsList_observable = [];
    }

    public resetDefaultData(value?: DropdownSelectCurrentSelectedItem) {
        this._selectedValue_observable = value;
        this._errorText_observable = undefined;
        this._optionsList_observable = [];
    }

    constructor(initData: InitDataStoreFilterDropdownSelect) {
        this.eventChangeValue = this.eventChangeValue.bind(this);
        this._isResetErrorOnChangeValue = initData.isResetErrorOnChangeValue;
        this._externalEventChangeValue = initData.externalEventChangeValue;
        this.placeholder = initData.placeholder;
        this._optionsList_observable = Array.isArray(initData.optionsList) ? initData.optionsList : [];
        this._selectedValue_observable = initData.selectedValue;
        this._errorText_observable = undefined;
        this._name = initData.name;

        // observable.ref action computed
        makeObservable<this,
            | '_optionsList_observable'
            | '_selectedValue_observable'
            | '_errorText_observable'
            | 'setSelectedValue'>(this, {
            _optionsList_observable: observable.ref,
            _selectedValue_observable: observable.ref,
            _errorText_observable: observable.ref,
            eventChangeValue: action,
            setOptionsList: action,
            setErrorText: action,
            resetError: action,
            resetSelectedValue: action,
            setSelectedValue: action,
            resetStoreData: action,
            resetDefaultData: action,
            optionsList: computed,
            selectedValue: computed,
            errorText: computed,
            isHasError: computed
        });
    }
}
