import {StoreApiUser} from "../../../api/storeApiUser";

interface InitDataStoreUserHotelLogo {
    storeApi: StoreApiUser;
}

export class StoreUserHotelLogo {
    private readonly _storeApi: StoreApiUser;

    constructor(initData: InitDataStoreUserHotelLogo) {
        this._storeApi = initData.storeApi;
    }

    get hotelLogo(): string | null {
        const logo: string | undefined = this._storeApi.storeHotelSpecificationInfo.hotelSpecificationInfo?.logo;

        if (logo) {
            return logo;
        }

        return null;
    }
}
