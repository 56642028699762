import React from "react";
import styles from './inputTextStyle.scss'

interface InputTextProps {
    value?: string;
    dataAttributeId?: string;
    eventChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    defaultValue?: string;
    className?: string;
    classNameContainer?: string;
    errorText?: string;
}

export default function InputText(props: InputTextProps) {
    const cssClass: string = props.className ? `${styles.editorInputText} ${props.className}` : styles.editorInputText;
    return (
        <>
            <input
                defaultValue={props.defaultValue}
                data-error={props.errorText ? 'true' : 'false'}
                className={props.errorText ? `${cssClass} ${styles.editorInputTextError}` : cssClass}
                onChange={props.eventChange ? props.eventChange : undefined}
                value={props.value}
                data-id={props.dataAttributeId}
            />
            {
                props.errorText ? <div className={styles.errorText}>{props.errorText}</div> : null
            }
        </>
    );
}
