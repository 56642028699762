import {BookingResultVM} from "../../api/src";
import {BookingRoomSuccess} from "../../interfaces/api/bookingRoom";

export function convertServerBookingModelToClientModel(inputData: BookingResultVM): BookingRoomSuccess | undefined {
    const {
        id,
        roomId,
        checkIn,
        checkOut
    } = inputData;

    if (typeof id !== 'number') {
        return undefined;
    }

    return {
        id: id,
        roomId: roomId ?? -1,
        checkIn: checkIn ? new Date(checkIn) : null,
        checkOut: checkOut ? new Date(checkOut) : null,
    }
}
