import React, {Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ErrorBoundary} from "../../components/common/errorBoundary";
import {LoaderSuspense} from "../../components/common/loaderSuspense";
import {StandardsPopupsFullscreen} from "../../components/common/standardsPopupsFullscreen";
import {PageComponentProps} from "../../interfaces/pageComponentProps";
import TemplatePageAuthUser
    from "../../components/pageTemplates/templatePageAuthUser/templatePageAuthUser/templatePageAuthUser";
import {observer} from "mobx-react";
import {StoreMainUser} from "../../stores/storeMainUser";
import {SiteTopMenuUser} from "../../components/pageTemplates/siteTopMenu/siteTopMenuUser";
import {SiteBottomMenu} from "../../components/pageTemplates/siteBottomMenu";
import {TemplatePageUnAuthUser} from "../../components/pageTemplates/templatePageAuthUser";
import {UserPageContainer} from "../../components/user/userPageContainer";
import {PageLoading} from "../../components/common/pageLoading";
import {BlockContainer} from "../../components/common/blockContainer";
import {StubErrorHotel} from "../../components/common/stubErrorHotel";
import {SiteTopMenuUnauthorizedUser} from "../../components/pageTemplates/siteTopMenu/siteTopMenuUnauthorizedUser";

const TEXT_AUTHORIZE_TITLE: string = 'Авторизация в системе';
const TEXT_GET_SPECIFICATION_TITLE: string = 'Получение настроек отеля';
const TEXT_ERROR_GET_SPECIFICATION_TITLE: string = 'Настройки отеля не определены';
const TEXT_GET_INFO_TITLE: string = 'Получение информации об отеле';
const TEXT_ERROR_GET_INFO_TITLE: string = 'Информация об отеле не найдена';

//region Страницы const Page = React.lazy(() => import(''));
const PageUserHome = React.lazy(() => import('../../componentsPages/user/pageUserHome'));
const PageUserRooms = React.lazy(() => import('../../componentsPages/user/pageUserRooms'));
const PageUserDetailRoom = React.lazy(() => import('../../componentsPages/user/pageUserDetailRoom'));
const PageUserBooking = React.lazy(() => import('../../componentsPages/user/pageUserBooking'));

// Страница не найдена
const ErrorPage404 = React.lazy(() => import('../../components/common/errorPage404/errorPage404'));
//endregion

interface UnAuthorizedUserContainerProps {
    errorText: string;
}

function UnAuthorizedUserContainer(props: UnAuthorizedUserContainerProps) {
    return (
        <TemplatePageUnAuthUser
            siteTopMenu={
                <SiteTopMenuUnauthorizedUser/>
            }
            siteBottomMenu={
                <SiteBottomMenu isUnAuthorized/>
            }
        >
            <UserPageContainer>
                <BlockContainer>
                    <StubErrorHotel errorText={props.errorText}/>
                </BlockContainer>
            </UserPageContainer>
        </TemplatePageUnAuthUser>
    );
}

function RealPageEnterPointUser(props: PageComponentProps<StoreMainUser>) {
    if (props.storePage.errorSearchClientId) {
        return (
            <UnAuthorizedUserContainer errorText={props.storePage.errorSearchClientId}/>
        );
    }

    if (props.storePage.errorConnectUrl) {
        return (
            <UnAuthorizedUserContainer errorText={props.storePage.errorConnectUrl}/>
        );
    }

    if (props.storePage.errorAuthApi) {
        return (
            <UnAuthorizedUserContainer errorText={props.storePage.errorAuthApi}/>
        );
    }

    if (!props.storePage.isSetAuthApiData) {
        return (
            <PageLoading text={TEXT_AUTHORIZE_TITLE}/>
        );
    }

    if (props.storePage.hotelSpecificationInfo === undefined) {
        return (
            <PageLoading text={TEXT_GET_SPECIFICATION_TITLE}/>
        );
    }

    if (props.storePage.hotelSpecificationInfo === null) {
        return (
            <UnAuthorizedUserContainer errorText={TEXT_ERROR_GET_SPECIFICATION_TITLE}/>
        );
    }

    if (props.storePage.hotelInfo === undefined) {
        return (
            <PageLoading text={TEXT_GET_INFO_TITLE}/>
        );
    }

    if (props.storePage.hotelInfo === null) {
        return (
            <UnAuthorizedUserContainer errorText={TEXT_ERROR_GET_INFO_TITLE}/>
        );
    }

    return (
        <BrowserRouter>
            <Suspense fallback={<LoaderSuspense/>}>
                <Routes>
                    <Route
                        element={
                            <TemplatePageAuthUser
                                siteTopMenu={
                                    <SiteTopMenuUser
                                        storeSiteTopMenu={props.storePage.storeSiteTopMenu}
                                        pageTitle={props.storePage.storeSitePageTitle.pageTitle}
                                        headerTitle={props.storePage.hotelInfo.title}
                                    />
                                }
                                siteBottomMenu={
                                    <SiteBottomMenu storeSiteBottomMenu={props.storePage.storeSiteBottomMenu}/>
                                }
                            />
                        }
                    >
                        <Route
                            path={props.storePage.storeRouter.getPagePath('userHome')}
                            element={
                                <ErrorBoundary key={'userHome'}>
                                    <PageUserHome storePage={props.storePage.storeUserPageHome}/>
                                </ErrorBoundary>
                            }
                        />
                        <Route
                            path={props.storePage.storeRouter.getPagePath('rooms')}
                            element={
                                <ErrorBoundary key={'rooms'}>
                                    <PageUserRooms storePage={props.storePage.storeUserPageRooms}/>
                                </ErrorBoundary>
                            }
                        />
                        <Route
                            path={`${props.storePage.storeRouter.getPagePath('detail')}/:id`}
                            element={
                                <ErrorBoundary key={'detail'}>
                                    <PageUserDetailRoom storePage={props.storePage.storeUserPageDetailRoom}/>
                                </ErrorBoundary>
                            }
                        />
                        <Route
                            path={`${props.storePage.storeRouter.getPagePath('booking')}/:id`}
                            element={
                                <ErrorBoundary key={'booking'}>
                                    <PageUserBooking storePage={props.storePage.storeUserPageBooking}/>
                                </ErrorBoundary>
                            }
                        />
                    </Route>
                    <Route
                        path={'*'}
                        element={<ErrorPage404 hrefGoHome={props.storePage.storeRouter.getPagePath('userHome')}/>}
                    />
                </Routes>
            </Suspense>
            <StandardsPopupsFullscreen store={props.storePage.storeFullScreenPopup}/>
        </BrowserRouter>
    );
}

export default observer(RealPageEnterPointUser);
