import {StoreFullScreenPopup} from "../../../common/storeFullScreenPopup";
import {StoreApiUser} from "../../../../api/storeApiUser";

interface InitDataStoreUserRoomBooking {
    storeApi: StoreApiUser;
    storeFullScreenPopup: StoreFullScreenPopup;
}

export class StoreUserRoomBooking {
    private readonly _storeApi: StoreApiUser;
    private readonly _storeFullScreenPopup: StoreFullScreenPopup;

    constructor(initData: InitDataStoreUserRoomBooking) {
        this._storeApi = initData.storeApi;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;
    }
}
