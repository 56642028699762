import {StoreApiUser} from "../../../../api/storeApiUser";
import {StoreRouter} from "../../../common/storeRouter";
import {RouterPagesKeysUser} from "../../../../staticData/routerPages/routerPagesUser";
import {RoomVMExtended} from "../../../../api/src";
import {convertServerRoomsListModelToClientModel} from "../../../../helpers/convertServerRoomsModelToClientModel";
import {getRequestErrorText} from "../../../../api/helpers/getRequestErrorText";
import {RoomItemData} from "../../../../interfaces/api/roomItemData";
import {action, computed, makeObservable, observable} from "mobx";
import {StoreUserRecommendedRoomPhoto} from "./storeUserRecommendedRoomPhoto";

interface InitDataStoreUserRoomsRecommended {
    storeApi: StoreApiUser;
    storeRouter: StoreRouter<RouterPagesKeysUser>;
}

export class StoreUserRoomsRecommended {
    private readonly _storeApi: StoreApiUser;
    private readonly _storeRouter: StoreRouter<RouterPagesKeysUser>;
    public readonly storeUserRecommendedRoomPhoto: StoreUserRecommendedRoomPhoto;

    private _recommendedRooms_observable?: RoomItemData[];

    private _setRecommendedRooms_action(rooms?: RoomItemData[]) {
        this._recommendedRooms_observable = rooms;
    }

    get recommendedRooms(): RoomItemData[] | undefined {
        return this._recommendedRooms_observable;
    }

    private _errorGetRecommendedRooms_observable?: string;

    private _setErrorGetRecommendedRooms_action(error?: string) {
        this._errorGetRecommendedRooms_observable = error;
    }

    get errorGetRecommendedRooms(): string | undefined {
        return this._errorGetRecommendedRooms_observable;
    }

    get storeRouter() {
        return this._storeRouter;
    }

    constructor(initData: InitDataStoreUserRoomsRecommended) {
        this._storeApi = initData.storeApi;
        this._storeRouter = initData.storeRouter;

        this.storeUserRecommendedRoomPhoto = new StoreUserRecommendedRoomPhoto({
            storeApi: this._storeApi
        });

        this._recommendedRooms_observable = undefined;
        this._errorGetRecommendedRooms_observable = undefined;

        makeObservable<this,
            '_recommendedRooms_observable'
            | '_errorGetRecommendedRooms_observable'
            | '_setRecommendedRooms_action'
            | '_setErrorGetRecommendedRooms_action'>(this, {
            _recommendedRooms_observable: observable,
            _errorGetRecommendedRooms_observable: observable,
            _setRecommendedRooms_action: action,
            _setErrorGetRecommendedRooms_action: action,
            recommendedRooms: computed,
            errorGetRecommendedRooms: computed
        });
    }

    public serverRequestGetRecommendedRooms() {
        this._storeApi.apiBooking.bookingSearchFreeHotelRoomsGet()
            .then((inputData) => {
                const rooms: RoomVMExtended[] = inputData.data;
                // Валидация данных
                const validData: RoomItemData[] = convertServerRoomsListModelToClientModel(rooms, true);
                // Установка данных. Берем последние 8 элементов
                this._setRecommendedRooms_action(validData.splice(-8));
            })
            .catch(error => {
                // Получаем текст ошибки
                const errorText: string = getRequestErrorText(error);
                // Показываем ошибку получения данных
                this._setErrorGetRecommendedRooms_action(errorText);
            });
    }
}
