import React from "react";
import styles from './headerMobileUnAuthorizedStyle.scss';

function HeaderMobileUnAuthorized() {
    return (
        <div className={styles.mobileContainer}>
            <img
                src={require('../../../../../img/img_base64/bookSELogo.png')}
                alt={'bookSELogo'}
            />
        </div>
    );
}

export default React.memo(HeaderMobileUnAuthorized);
