import React from "react";
import styles from './pageHeaderItemLinkMobileStyle.scss';
import {NavLink} from "react-router-dom";

interface PageHeaderItemLinkProps {
    fullPath?: string;
    name: string;
    icon: JSX.Element | undefined;
    eventClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function getClassNameRouterLink(inputOptions: { isActive: boolean }): string {
    if (inputOptions.isActive) {
        return `${styles.routerLinkActive} ${styles.componentContainer}`
    } else {
        return styles.componentContainer;
    }
}

/**
 * Элемент мобильного меню
 * @param props
 * @constructor
 */
function PageHeaderItemLinkMobile(props: PageHeaderItemLinkProps) {
    return (
        <>
            {
                props.fullPath ?
                    <NavLink
                        className={getClassNameRouterLink}
                        to={props.fullPath}
                    >
                        <div className={styles.icoContainer}>
                            {props.icon}
                        </div>
                        {props.name}
                    </NavLink> : props.eventClick ?
                        <div
                            className={styles.componentContainer}
                            onClick={props.eventClick}
                        >
                            <div className={styles.icoContainer}>
                                {props.icon}
                            </div>
                            {props.name}
                        </div> : null
            }
        </>

    );
}

export default React.memo(PageHeaderItemLinkMobile);
