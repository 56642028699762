import {action, computed, makeObservable, observable} from "mobx";
import {PopupFullScreenErrorProps} from "../../components/common/standardsPopupsFullscreen/popupFullScreenError/popupFullScreenError";
import {PopupFullScreenLoadingProps} from "../../components/common/standardsPopupsFullscreen/popupFullScreenLoading/popupFullScreenLoading";
import {PopupFullScreenSuccessProps} from "../../components/common/standardsPopupsFullscreen/popupFullScreenSuccess/popupFullScreenSuccess";
import {PopupFullScreenStepSuccessProps} from "../../components/common/standardsPopupsFullscreen/popupFullScreenStepSuccess/popupFullScreenStepSuccess";
import {PopupFullScreenConfirmProps} from "../../components/common/standardsPopupsFullscreen/popupFullScreenConfirm/popupFullScreenConfirm/popupFullScreenConfirm";
import {PopupFullScreenConfirmExitProps} from "../../components/common/standardsPopupsFullscreen/popupFullScreenConfirm/popupFullScreenConfirmExit/popupFullScreenConfirmExit";

interface BasicFullScreenPopupType<FullScreenPopupType> {
    type: FullScreenPopupType
}

//region Типы окон

interface FullScreenPopupTypeConfirm extends PopupFullScreenConfirmProps, BasicFullScreenPopupType<'confirm'> {}

interface FullScreenPopupTypeConfirmExit extends PopupFullScreenConfirmExitProps, BasicFullScreenPopupType<'confirmExit'> {}

interface FullScreenPopupTypeConfirmExport extends PopupFullScreenConfirmExitProps, BasicFullScreenPopupType<'confirmExport'> {}

interface FullScreenPopupTypeError extends PopupFullScreenErrorProps, BasicFullScreenPopupType<'error'> {}

interface FullScreenPopupTypeLoading extends PopupFullScreenLoadingProps, BasicFullScreenPopupType<'loading'> {}

interface FullScreenPopupTypeSuccess extends  PopupFullScreenSuccessProps, BasicFullScreenPopupType<'success'>{}

interface FullScreenPopupTypeStepSuccess extends  PopupFullScreenStepSuccessProps, BasicFullScreenPopupType<'stepSuccess'>{}

type AllFullScreenPopupType = FullScreenPopupTypeConfirm | FullScreenPopupTypeError | FullScreenPopupTypeLoading | FullScreenPopupTypeSuccess | FullScreenPopupTypeStepSuccess | FullScreenPopupTypeConfirmExit | FullScreenPopupTypeConfirmExport;
//endregion

/**
 * Store стандартных всплывающих окон
 */
export class StoreFullScreenPopup {
    private _currentOpenPopup_observable?: AllFullScreenPopupType;

    private _currentOpenPopup_action(status?: AllFullScreenPopupType) {
        this._currentOpenPopup_observable = status;
    }

    constructor() {
        this._currentOpenPopup_observable = undefined;

        this.closeAllWindows = this.closeAllWindows.bind(this);

        makeObservable<this,
            '_currentOpenPopup_observable'
            | '_currentOpenPopup_action'>(this, {
            _currentOpenPopup_observable: observable,
            _currentOpenPopup_action: action,
            confirm: computed,
            confirmExit: computed,
            error: computed,
            loading: computed,
            success: computed,
            stepSuccess: computed
        });
    }

    /**
     * Закрыть все открытые окна
     * @public
     */
    public closeAllWindows() {
        this._currentOpenPopup_action(undefined);
    }

    //region Загрузка
    set loading(status: PopupFullScreenLoadingProps | undefined) {
        if (!status) {
            this._currentOpenPopup_action(undefined);
            return;
        }

        this._currentOpenPopup_action({
            type: 'loading',
            ...status
        });
    }

    get loading(): PopupFullScreenLoadingProps | undefined {
        if (!this._currentOpenPopup_observable) {
            return undefined;
        }

        if (this._currentOpenPopup_observable.type === 'loading') {
            return this._currentOpenPopup_observable
        }

        return undefined;
    }

    //endregion

    //region Ошибка
    set error(status: PopupFullScreenErrorProps | undefined) {
        if (!status) {
            this._currentOpenPopup_action(undefined);
            return;
        }

        this._currentOpenPopup_action({
            type: 'error',
            ...status
        });
    }

    get error(): PopupFullScreenErrorProps | undefined {
        if (!this._currentOpenPopup_observable) {
            return undefined;
        }

        if (this._currentOpenPopup_observable.type === 'error') {
            return this._currentOpenPopup_observable
        }

        return undefined;
    }
    //endregion

    //region Подтверждение
    set confirm(status: PopupFullScreenConfirmProps | undefined) {
        if (!status) {
            this._currentOpenPopup_action(undefined);
            return;
        }

        this._currentOpenPopup_action({
            type: 'confirm',
            ...status
        });
    }

    get confirm(): PopupFullScreenConfirmProps | undefined {
        if (!this._currentOpenPopup_observable) {
            return undefined;
        }

        if (this._currentOpenPopup_observable.type === 'confirm') {
            return this._currentOpenPopup_observable
        }

        return undefined;
    }
    //endregion

    //region Подтверждение для выхода
    set confirmExit(status: PopupFullScreenConfirmExitProps | undefined) {
        if (!status) {
            this._currentOpenPopup_action(undefined);
            return;
        }

        this._currentOpenPopup_action({
            type: 'confirmExit',
            ...status
        });
    }

    get confirmExit(): PopupFullScreenConfirmExitProps | undefined {
        if (!this._currentOpenPopup_observable) {
            return undefined;
        }

        if (this._currentOpenPopup_observable.type === 'confirmExit') {
            return this._currentOpenPopup_observable
        }

        return undefined;
    }
    //endregion

    //region Успех
    set success(status: PopupFullScreenSuccessProps | undefined) {
        if (!status) {
            this._currentOpenPopup_action(undefined);
            return;
        }

        this._currentOpenPopup_action({
            type: 'success',
            ...status
        });
    }

    get success(): PopupFullScreenSuccessProps | undefined {
        if (!this._currentOpenPopup_observable) {
            return undefined;
        }

        if (this._currentOpenPopup_observable.type === 'success') {
            return this._currentOpenPopup_observable
        }

        return undefined;
    }

    //region Успех c шагом
    set stepSuccess(status: PopupFullScreenStepSuccessProps | undefined) {
        if (!status) {
            this._currentOpenPopup_action(undefined);
            return;
        }

        this._currentOpenPopup_action({
            type: 'stepSuccess',
            ...status
        });
    }

    get stepSuccess(): PopupFullScreenStepSuccessProps | undefined {
        if (!this._currentOpenPopup_observable) {
            return undefined;
        }

        if (this._currentOpenPopup_observable.type === 'stepSuccess') {
            return this._currentOpenPopup_observable
        }

        return undefined;
    }
    //endregion
}
