import {RoomCategoryVMExtended} from "../../../api/src";
import {StoreApiUser} from "../../../api/storeApiUser";
import {getRequestErrorText} from "../../../api/helpers/getRequestErrorText";
import {action, computed, makeObservable, observable} from "mobx";
import {DropdownSelectItem} from "../../../interfaces/dropdownSelectItem";
import {StoreComponentDropdownSelect} from "../storeComponentDropdownSelect";
import {StoreComponentStandardCheckbox} from "../storeComponentStandardCheckbox";
import {SearchRoomsOptions} from "../../../interfaces/api/searchRoomsOptions";
import validationStringRangeStandard from "../../../helpers/validation/validationStringRangeStandard";
import {StoreRouter} from "../storeRouter";
import {RouterPagesKeysUser} from "../../../staticData/routerPages/routerPagesUser";
import getFilterParametersOnUrl from "../../../helpers/getFilterParametersOnUrl";
import {StoreFullScreenPopup} from "../storeFullScreenPopup";
import dateFormatMonthDayYear from "../../../helpers/dateFormatMonthDayYear";
import moment, {Moment} from "moment";

interface FilterDirectoryList {
    roomNumbers: DropdownSelectItem[];
    roomCapacities: DropdownSelectItem[];
    roomCategories: DropdownSelectItem[];
}

interface InitDataStoreCommonFilter {
    storeApi: StoreApiUser;
    storeRouter: StoreRouter<RouterPagesKeysUser>;
    storeFullScreenPopup: StoreFullScreenPopup;
}

export class StoreCommonFilter {
    private readonly _storeApi: StoreApiUser;
    private readonly _storeRouter: StoreRouter<RouterPagesKeysUser>;
    private readonly _storeFullScreenPopup: StoreFullScreenPopup;

    public readonly storeDropdownSelectRoomsNumber: StoreComponentDropdownSelect;
    public readonly storeDropdownSelectRoomsCapacity: StoreComponentDropdownSelect;
    public readonly storeDropdownSelectRoomsCategory: StoreComponentDropdownSelect;
    public readonly storeEditWithKids: StoreComponentStandardCheckbox;

    private _directoryList_observable: FilterDirectoryList | undefined;

    private _setDirectoryList_action(inputDirectoryList?: FilterDirectoryList) {
        this._directoryList_observable = inputDirectoryList;
    }

    get roomNumbersList(): DropdownSelectItem[] {
        if (!this._directoryList_observable) {
            return [];
        }

        return this._directoryList_observable.roomNumbers;
    }

    get roomCapacitiesList(): DropdownSelectItem[] {
        if (!this._directoryList_observable) {
            return [];
        }

        return this._directoryList_observable.roomCapacities;
    }

    get roomCategoriesList(): DropdownSelectItem[] {
        if (!this._directoryList_observable) {
            return [];
        }

        return this._directoryList_observable.roomCategories;
    }

    private _errorGetFilterOptions_observable?: string;

    private _setErrorGetFilterOptions_action(error?: string) {
        this._errorGetFilterOptions_observable = error;
    }

    get errorGetFilterOptions() {
        return this._errorGetFilterOptions_observable;
    }

    //region date - Диапазон бронирования
    private _startDate_observable: Moment | null;

    private _setStartDate_action(startDate: Moment | null) {
        this._startDate_observable = startDate;
    }

    get startDate() {
        return this._startDate_observable;
    }

    private _endDate_observable: Moment | null;

    private _setEndDate_action(endDate: Moment | null) {
        this._endDate_observable = endDate;
    }

    get endDate() {
        return this._endDate_observable;
    }

    public eventHandleDatesChange({ startDate, endDate }: {startDate: any, endDate: any}) {
        this._setStartDate_action(startDate);
        this._setEndDate_action(endDate);
    }
    //endregion

    //region focusedInput - Для дат
    private _focusedInput_observable: any;

    public setFocusedInput(focusedInput: any) {
        this._focusedInput_observable = focusedInput;
    }

    get focusedInput() {
        return this._focusedInput_observable;
    }
    //endregion

    /**
     * Блокировка дней
     * @param day
     * @public
     */
    public isOutsideRange(day: any): boolean {
        return day._d.setHours(12, 0, 0, 0) < new Date().setHours(12, 0, 0, 0);
    }

    constructor(initData: InitDataStoreCommonFilter) {
        this._storeApi = initData.storeApi;
        this._storeRouter = initData.storeRouter;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;

        this._directoryList_observable = undefined;
        this._errorGetFilterOptions_observable = undefined;

        const filterOptions = getFilterParametersOnUrl();

        this.storeDropdownSelectRoomsNumber = new StoreComponentDropdownSelect({
            isResetErrorOnChangeValue: true,
            selectedValue: filterOptions.roomCount ? {
                value: filterOptions.roomCount,
                label: String(filterOptions.roomCount)
            } : undefined
        });

        this.storeDropdownSelectRoomsCapacity = new StoreComponentDropdownSelect({
            isResetErrorOnChangeValue: true,
            selectedValue: filterOptions.capacity ? {
                value: filterOptions.capacity,
                label: String(filterOptions.capacity)
            } : undefined
        });

        this.storeDropdownSelectRoomsCategory = new StoreComponentDropdownSelect({
            isResetErrorOnChangeValue: true
        });

        this.storeEditWithKids = new StoreComponentStandardCheckbox({
            isCheck: filterOptions.withKids
        });

        this._startDate_observable = filterOptions.checkIn ? moment(filterOptions.checkIn) : null;
        this._endDate_observable = filterOptions.checkOut ? moment(filterOptions.checkOut) : null;
        this._focusedInput_observable = null;

        this.eventHandleDatesChange = this.eventHandleDatesChange.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);
        this.setFocusedInput = this.setFocusedInput.bind(this);
        this.eventCancelDataFilter = this.eventCancelDataFilter.bind(this);
        this.getFilterDataPath = this.getFilterDataPath.bind(this);

        makeObservable<this,
            '_directoryList_observable'
            | '_errorGetFilterOptions_observable'
            | '_startDate_observable'
            | '_endDate_observable'
            | '_focusedInput_observable'
            | '_setErrorGetFilterOptions_action'
            | '_setDirectoryList_action'
            | '_setStartDate_action'
            | '_setEndDate_action'>(this, {
            _directoryList_observable: observable,
            _errorGetFilterOptions_observable: observable,
            _startDate_observable: observable,
            _endDate_observable: observable,
            _focusedInput_observable: observable,
            _setDirectoryList_action: action,
            _setErrorGetFilterOptions_action: action,
            _setStartDate_action: action,
            _setEndDate_action: action,
            eventHandleDatesChange: action,
            isOutsideRange: action,
            setFocusedInput: action,
            roomNumbersList: computed,
            roomCapacitiesList: computed,
            roomCategoriesList: computed,
            errorGetFilterOptions: computed,
            startDate: computed,
            endDate: computed,
            focusedInput: computed
        });
    }

    /**
     * Фильтрация
     * @private
     */
    private _validationSearchOptions(): SearchRoomsOptions | undefined {
        const validRangeDate = validationStringRangeStandard({
            dateStartToCheck: this._startDate_observable,
            dateEndToCheck: this._endDate_observable,
            errorText: 'Некорректно указан диапазон бронирования'
        });

        const validRoomsNumber = this.storeDropdownSelectRoomsNumber.getSelectedValue();
        const validRoomsCapacity = this.storeDropdownSelectRoomsCapacity.getSelectedValue();
        const validRoomsCategory = this.storeDropdownSelectRoomsCategory.getSelectedValue();
        const withKids = this.storeEditWithKids.isCheck;

        if (validRangeDate.errorText) {
            this._storeFullScreenPopup.error = {
                errorText: validRangeDate.errorText,
                eventClose: this._storeFullScreenPopup.closeAllWindows
            };

            return;
        }

        const checkIn: Date | null = validRangeDate.validValueStart;
        const checkOut: Date | null = validRangeDate.validValueEnd;

        return {
            checkIn: checkIn ? dateFormatMonthDayYear(checkIn) : undefined,
            checkOut: checkOut ? dateFormatMonthDayYear(checkOut) : undefined,
            roomCount: validRoomsNumber ? validRoomsNumber.value : undefined,
            capacity: validRoomsCapacity ? validRoomsCapacity.value : undefined,
            categoryId: validRoomsCategory ? validRoomsCategory.value : undefined,
            withKids: withKids
        }
    }

    /**
     * Фильтрация номеров на главной странице
     */
    public getFilterDataPath(): string | undefined {
        const validData: SearchRoomsOptions | undefined = this._validationSearchOptions();

        if (!validData) {
            return;
        }

        const filterDataPath: string[] = [];

        if (validData.checkIn) {
            filterDataPath.push('checkIn=' + validData.checkIn);
        }

        if (validData.checkOut) {
            filterDataPath.push('checkOut=' + validData.checkOut);
        }

        if (validData.roomCount) {
            filterDataPath.push('roomCount=' + validData.roomCount);
        }

        if (validData.capacity) {
            filterDataPath.push('capacity=' + validData.capacity);
        }

        if (validData.categoryId) {
            filterDataPath.push('categoryId=' + validData.categoryId);
        }

        filterDataPath.push('withKids=' + validData.withKids)

        return this._storeRouter.getPagePath('rooms') + '?' + filterDataPath.join('&')
    }

    /**
     * Сброс значений фильтра
     */
    public eventCancelDataFilter() {
        this._setStartDate_action(null);
        this._setEndDate_action(null);
        this.storeDropdownSelectRoomsNumber.resetSelectedValue();
        this.storeDropdownSelectRoomsCapacity.resetSelectedValue();
        this.storeDropdownSelectRoomsCategory.resetSelectedValue();
        this.storeEditWithKids.resetIsCheck();
        this.setFocusedInput(null);
    }

    /**
     * Валидация комнат и мест для фильтра (выпадающий список)
     * @param inputData
     * @private
     */
    private _validationRoomNumbersOrRoomCapacities(inputData: number[]): DropdownSelectItem[] {
        const resultData: DropdownSelectItem[] = [];

        for (let i = 0; i < inputData.length; ++i) {
            const value = inputData[i];

            if (typeof value !== 'number') {
                continue;
            }

            resultData.push({
                value: value,
                label: String(value) ?? ''
            });
        }

        const sortedResult = resultData.slice(0);
        sortedResult.sort(function(firstItem: DropdownSelectItem, secondItem: DropdownSelectItem) {
            return firstItem.value - secondItem.value;
        });

        return sortedResult;
    }

    /**
     * Валидация категорий для фильтра (выпадающий список)
     * @param inputData
     * @private
     */
    private _validationRoomCategories(inputData: RoomCategoryVMExtended[]): DropdownSelectItem[] {
        const resultData: DropdownSelectItem[] = [];

        for (let i = 0; i < inputData.length; ++i) {
            const id = inputData[i].id;

            if (typeof id !== 'number') {
                continue;
            }

            resultData.push({
                value: id,
                label: inputData[i].title ?? ''
            });
        }

        return resultData;
    }

    public serverRequestGetFilterOptions() {
        const promiseRoomsNumbersList = this._storeApi.apiRoom.roomGetRoomNumbersGet();
        const promiseRoomsCapacitiesList = this._storeApi.apiRoom.roomGetRoomCapacitiesGet();
        const promiseRoomsCategoriesList = this._storeApi.apiRoom.roomGetRoomCategoriesGet();

        Promise.all([promiseRoomsNumbersList, promiseRoomsCapacitiesList, promiseRoomsCategoriesList])
            .then(inputAllData => {
                const roomsNumbersList: number[]  = inputAllData[0].data;
                const roomsCapacitiesList: number[] = inputAllData[1].data;
                const roomsCategoriesList: RoomCategoryVMExtended[]  = inputAllData[2].data;

                const validRoomNumbers: DropdownSelectItem[] = this._validationRoomNumbersOrRoomCapacities(roomsNumbersList);
                const validRoomCapacities: DropdownSelectItem[] = this._validationRoomNumbersOrRoomCapacities(roomsCapacitiesList);
                const validRoomCategories: DropdownSelectItem[] = this._validationRoomCategories(roomsCategoriesList);

                // Проверяем и запоминаем справочники
                this._setDirectoryList_action({
                    roomNumbers: validRoomNumbers,
                    roomCapacities: validRoomCapacities,
                    roomCategories: validRoomCategories
                });

                // Устанавливаем данные для выпадающих списков
                this.storeDropdownSelectRoomsNumber.setOptionsList(validRoomNumbers);
                this.storeDropdownSelectRoomsCapacity.setOptionsList(validRoomCapacities);
                this.storeDropdownSelectRoomsCategory.setOptionsList(validRoomCategories);
            })
            .catch(error => {
                const errorText: string = getRequestErrorText(error);
                this._setErrorGetFilterOptions_action(errorText);
            });
    }
}
