import React from "react";
import styles from "./popupFullScreenStyle.scss";
import {showHiddenDocumentBodyOverflow} from "./showHiddenDocumentBodyOverflow";

interface PopupFullScreenProps {
    children: React.ReactNode;
}

/**
 * Простой вариант всплывающего окно
 * Не содержит событий и кнопок закрытия
 * Можно закрыть только из вне
 */
export default class PopupFullScreen extends React.PureComponent<PopupFullScreenProps> {
    constructor(props: PopupFullScreenProps) {
        super(props);
    }

    componentDidMount() {
        showHiddenDocumentBodyOverflow(false);
    }

    componentWillUnmount() {
        showHiddenDocumentBodyOverflow(true);
    }

    render() {
        return (
            <div className={styles.popupBackground}>
                <div className={styles.popupBody}>
                    <div className={styles.popupContentContainer}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
