import {SitePageInfo} from "../../interfaces/sitePageInfo";
import {ROUTER_REAL_PAGES_PATHS, RouterRealPagesPaths} from "../../staticData/routerPages/routerRealPages";
import {clientRouterRedirectToPageByPath} from "../../helpers/redirectToPageByPath";

export type SitePagesDictionary<PagesKeys extends string> = { [key in PagesKeys]: SitePageInfo }

interface InitData<PagesKeys extends string> {
    readonly pages: SitePagesDictionary<PagesKeys>;
}

/**
 * Store для роутера
 */
export class StoreRouter<PagesKeys extends string> {
    // Список страниц роутера
    private readonly _routerPagesDictionary: Readonly<SitePagesDictionary<PagesKeys>>;
    //Реальные страницы
    public readonly realPagesPaths: Readonly<RouterRealPagesPaths>;

    constructor(initData: InitData<PagesKeys>) {
        // Запоминаем список страниц
        this._routerPagesDictionary = Object.freeze(initData.pages);
        this.realPagesPaths = Object.freeze(ROUTER_REAL_PAGES_PATHS);
    }

    //region Публичные методы

    /**
     * Получить список страниц
     * @param pagesKeysList
     */
    public getPagesListByKeys(pagesKeysList: PagesKeys[]): SitePageInfo[] {
        const resultPagesList: SitePageInfo[] = [];

        if (!pagesKeysList.length) {
            return resultPagesList;
        }

        for (let i = 0; i < pagesKeysList.length; ++i) {
            if (this._routerPagesDictionary.hasOwnProperty(pagesKeysList[i])) {
                const page = this._routerPagesDictionary[pagesKeysList[i]];
                resultPagesList.push(page);
            }
        }

        return resultPagesList;
    }

    /**
     * Получить путь страницы по имени
     * @param pageName
     */
    public getPagePath(pageName: PagesKeys): string {
        if (!this._routerPagesDictionary.hasOwnProperty(pageName)) {
            throw new Error(`Page with key '${pageName}' not found`);
        }

        return this._routerPagesDictionary[pageName].fullPath;
    }

    /**
     * Получить публичное название страницы по ключу
     * @param pageName
     */
    public getPagePublicName(pageName: PagesKeys): string {
        if (!this._routerPagesDictionary.hasOwnProperty(pageName)) {
            throw new Error(`Page with key '${pageName}' not found`);
        }

        return this._routerPagesDictionary[pageName].pagePublicName;
    }

    /**
     * Получить информацию о странице по имени
     * @param pageName
     */
    public getPageInfo(pageName: PagesKeys): SitePageInfo {
        if (!this._routerPagesDictionary.hasOwnProperty(pageName)) {
            throw new Error(`Page with key '${pageName}' not found`);
        }
        const currentPage = this._routerPagesDictionary[pageName];
        return Object.assign({},currentPage);
    }

    //endregion

    /**
     * Перейти на страницу
     * @param pageName
     */
    public redirectToPage(pageName: PagesKeys) {
        if (!this._routerPagesDictionary.hasOwnProperty(pageName)) {
            throw new Error(`Page with key '${pageName}' not found`);
        }

        const pageFullPath = this._routerPagesDictionary[pageName].fullPath;
        clientRouterRedirectToPageByPath(pageFullPath)
    }
}
