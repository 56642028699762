import React from "react";
import {PageLinkBack} from "../../common/pageLinkBack";
import {MainPageContainer} from "../../common/mainPageContainer";

interface UserPageContainerProps {
    children: React.ReactNode;
    linkBack?: string;
    linkTitle?: string;
}

function UserPageContainer(props: UserPageContainerProps) {
    return (
        <MainPageContainer>
            {
                props.linkTitle && props.linkBack ?
                    <PageLinkBack
                        linkTitle={props.linkTitle}
                        linkBack={props.linkBack}
                    /> : null
            }
            {props.children}
        </MainPageContainer>
    );
}

export default React.memo(UserPageContainer)
