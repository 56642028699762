import React from "react";
import styles from "./subHeaderContainerStyle.scss";

function SubHeaderContainer() {
    return (
        <div className={styles.componentContainer}/>
    )
}

export default React.memo(SubHeaderContainer);
