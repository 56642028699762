import {v4 as uuidv4} from "uuid";
import {ROUTER_REAL_PAGES_PATHS} from "./routerRealPages";
import {SitePagesDictionary} from "../../stores/common/storeRouter";
import {HotelRoomsIcon} from "../../components/common/icons/hotelRoomsIcon";
import {HomeIcon} from "../../components/common/icons/homeIcon";

export type RouterPagesKeysUser = 'userHome' | 'rooms' | 'detail' | 'booking';

export const ROUTER_PAGES_USER: SitePagesDictionary<RouterPagesKeysUser> = {
    userHome: {
        internalId: uuidv4(),
        fullPath: `${ROUTER_REAL_PAGES_PATHS.user}/`,
        pagePublicName: 'Бронирование номеров',
        pageKey: 'userHome',
        menu: {
            publicName: 'Главная',
            icon: HomeIcon()
        }
    },
    rooms: {
        internalId: uuidv4(),
        fullPath: `${ROUTER_REAL_PAGES_PATHS.user}/rooms`,
        pagePublicName: 'Номера отеля',
        pageKey: 'rooms',
        menu: {
            publicName: 'Номера отеля',
            icon: HotelRoomsIcon()
        }
    },
    detail: {
        internalId: uuidv4(),
        fullPath: `${ROUTER_REAL_PAGES_PATHS.user}/rooms/detail`,
        pagePublicName: 'Детальная информация',
        pageKey: 'detail'
    },
    booking: {
        internalId: uuidv4(),
        fullPath: `${ROUTER_REAL_PAGES_PATHS.user}/rooms/booking`,
        pagePublicName: 'Бронирование номера',
        pageKey: 'booking'
    }
};
