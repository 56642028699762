import {action, computed, makeObservable, observable} from "mobx";
import {ErrorText} from "../../interfaces/errorText";
import moment, { Moment } from "moment";
import {FocusedInputShape} from "react-dates";

export interface DateRange {
    dateStart: Moment | null;
    dateEnd: Moment | null;
}

interface InitDataStoreComponentDateRangePicker {
    // Внешнее событие изменения данных
    externalEventChangeValue?: (period: DateRange)=> void;
    // Даты периоды
    dateRange: DateRange;
    // Можно сбрасывать значения или нет
    isClearable?: boolean;
    // Сбросить ошибку при изменении данных
    isResetErrorOnChangeValue?: boolean;
}

export class StoreComponentDatesRange {
    private readonly _isResetErrorOnChangeValue?: boolean;
    private readonly _externalEventChangeValue?:(period: DateRange)=> void;
    public readonly isClearable?: boolean;

    //region Ошибка
    private _errorText_observable: ErrorText;

    get errorText() {
        return this._errorText_observable;
    }

    public setErrorText(inputErrorText: string){
        this._errorText_observable = inputErrorText;
    }

    public resetError(){
        this._errorText_observable = undefined;
    }

    //endregion

    //region Период
    private _dateRange_observable: DateRange;

    get dateRange() {
        return this._dateRange_observable;
    }

    public eventChangeDateStart(dateStart: Date | null) {
        if(!this.isClearable) {
            if (!dateStart) {
                return;
            }
        }

        const currentPeriod = Object.assign({}, this._dateRange_observable);
        //currentPeriod.dateStart = dateStart;
        this._dateRange_observable = currentPeriod;

        if(typeof this._externalEventChangeValue === 'function') {
            this._externalEventChangeValue(currentPeriod);
        }
    }

    public eventChangeDateEnd(dateEnd: Date | null) {
        if(!this.isClearable) {
            if (!dateEnd) {
                return;
            }
        }

        const currentPeriod = Object.assign({}, this._dateRange_observable);

        //currentPeriod.dateEnd = dateEnd;
        this._dateRange_observable = currentPeriod;

        if(typeof this._externalEventChangeValue === 'function') {
            this._externalEventChangeValue(currentPeriod);
        }
    }

    public resetDateRange(){
        this._dateRange_observable = {
          dateStart: null,
          dateEnd: null
        };
    }

    public getSelectedDateRange(): any {
        const dateStartTimestamp: number | null = this._dateRange_observable.dateStart ? +this._dateRange_observable.dateStart : null;
        const dateEndTimestamp: number | null = this._dateRange_observable.dateEnd ? +this._dateRange_observable.dateEnd : null;

        // На всякий случай клонируем объекты даты
        const dateStart: Date | null = dateStartTimestamp ? new Date(dateStartTimestamp) : null;
        const dateEnd: Date | null = dateEndTimestamp ? new Date(dateEndTimestamp) : null;

        if (dateStart) {
            // Обнуляем время
            dateStart.setHours(0,0,0,0);
        }

        if (dateEnd) {
            // Ставим 23:59:59 текущего дня
            dateEnd.setHours(23, 59, 0,0);
        }

        return {
            dateStart: dateStart,
            dateEnd: dateEnd
        };
    }
    //endregion

    //region date - Диапазон бронирования
    private _startDate_observable: Moment | null;

    private _setStartDate_action(startDate: Moment | null) {
        this._startDate_observable = startDate;
    }

    get startDate(): Moment | null {
        return this._startDate_observable;
    }

    private _endDate_observable: Moment | null;

    private _setEndDate_action(endDate: Moment | null) {
        this._endDate_observable = endDate;
    }

    get endDate(): Moment | null {
        return this._endDate_observable;
    }

    public eventHandleDatesChange(arg: { startDate: Moment | null; endDate: Moment | null; }) {
        //this._setStartDate_action(startDate);
        //this._setEndDate_action(endDate);

        this._setStartDate_action(moment(arg.startDate));
        this._setEndDate_action(moment(arg.endDate));
    }
    //endregion

    //region focusedInput - Для дат
    private _focusedInput_observable: FocusedInputShape | null;

    public setFocusedInput(focusedInput: FocusedInputShape | null) {
        this._focusedInput_observable = focusedInput;
    }

    get focusedInput(): FocusedInputShape | null {
        return this._focusedInput_observable;
    }
    //endregion


    constructor(initData: InitDataStoreComponentDateRangePicker) {
        this.eventChangeDateStart = this.eventChangeDateStart.bind(this);
        this.eventChangeDateEnd = this.eventChangeDateEnd.bind(this);
        this.eventHandleDatesChange = this.eventHandleDatesChange.bind(this);

        this._isResetErrorOnChangeValue = initData.isResetErrorOnChangeValue;
        this._externalEventChangeValue = undefined;
        this._errorText_observable = undefined;
        this.isClearable = initData.isClearable;
        this._dateRange_observable = initData.dateRange;

        if (typeof initData.externalEventChangeValue === 'function') {
            this._externalEventChangeValue = initData.externalEventChangeValue;
        }


        this._startDate_observable = null;
        this._endDate_observable = null;
        this._focusedInput_observable = null;

        this.eventHandleDatesChange = this.eventHandleDatesChange.bind(this);
        this.setFocusedInput = this.setFocusedInput.bind(this);

        makeObservable<this,
            '_dateRange_observable'
            | '_errorText_observable'>(this, {
            _dateRange_observable: observable.ref,
            _errorText_observable: observable.ref,
            eventChangeDateStart: action,
            eventChangeDateEnd: action,
            setErrorText: action,
            resetError: action,
            resetDateRange: action,
            dateRange: computed,
            errorText: computed,
        });

        makeObservable<this,
            | '_startDate_observable'
            | '_endDate_observable'
            | '_focusedInput_observable'
            | '_setStartDate_action'
            | '_setEndDate_action'>(this, {
            _startDate_observable: observable,
            _endDate_observable: observable,
            _focusedInput_observable: observable,
            _setStartDate_action: action,
            _setEndDate_action: action,
            eventHandleDatesChange: action,
            setFocusedInput: action,
            startDate: computed,
            endDate: computed,
            focusedInput: computed,
        });
    }
}
