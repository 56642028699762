import React from "react";
import styles from "../standardsPopupsFullscreenStyles.scss";
import PopupFullScreenWithEvents, {PopupFullScreenBasicProps} from "../../popupFullscreen/popupFullScreenWithEvents";
import alert from '../../../../lottieAnimation/error.json';
import {Player} from "@lottiefiles/react-lottie-player";
import {ButtonsContainer} from "../../buttonsContainer";
import {StandardButton} from "../../standardButton";

const TEXT_BUTTON_CLOSE: string = 'Закрыть';

export interface PopupFullScreenErrorProps extends PopupFullScreenBasicProps {
    errorText: string;
    textBtnClose?: string;
}

function PopupFullScreenError(props: PopupFullScreenErrorProps) {
    return (
        <PopupFullScreenWithEvents
            eventClose={props.eventClose}
        >
            <Player
                autoplay
                loop={false}
                keepLastFrame={true}
                src={alert}
                style={{
                    height: '180px',
                    width: '180px',
                    margin: '0 auto'
            }}
            />
            <div className={styles.messageContainer}>
                {props.errorText}
            </div>
            <ButtonsContainer>
                <StandardButton
                    type={'cancel'}
                    text={props.textBtnClose ?? TEXT_BUTTON_CLOSE}
                    eventClick={props.eventClose}
                />
            </ButtonsContainer>
        </PopupFullScreenWithEvents>
    );
}

export default React.memo(PopupFullScreenError);
