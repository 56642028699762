import {observer} from "mobx-react";
import {SmartComponentProps} from "../../../interfaces/smartComponentProps";
import {StoreComponentStandardCheckbox} from "../../../stores/common/storeComponentStandardCheckbox";
import styles from "./editorPersonalDataStyle.scss";
import {Checkbox} from "../checkbox";
import PdfForPersonalData from "../../../pdf_files/processingPersonalData.pdf";
import React from "react";

const TEXT_I_AGREE_TO: string = 'Я согласен на ';
const TEXT_PROCESSING_PERSONAL_DATA: string = 'обработку персональных данных';

function InputEditorPersonalData(props: SmartComponentProps<StoreComponentStandardCheckbox>) {
    return (
        <div className={styles.personalDataContainer}>
            <div className={styles.checkBoxContainer}>
                <Checkbox
                    eventChange={props.store.eventChangeValue}
                    checked={props.store.isCheck}
                />
                <span>
                    {TEXT_I_AGREE_TO}
                    <a
                        href={PdfForPersonalData}
                        target={'_blank'}
                    >
                        {TEXT_PROCESSING_PERSONAL_DATA}
                    </a>
                </span>
            </div>
            {
                props.store.errorText ?
                    <div className={styles.errorTextContainer}>{props.store.errorText}</div>
                    : null
            }
        </div>
    );
}

export default observer(InputEditorPersonalData);
