import React from "react";
import errorFuseAnimation from '../../../lottieAnimation/errorFuse.json';
import styles from './errorFuseStyle.scss';
import {Player} from "@lottiefiles/react-lottie-player";

const TEXT_ERROR: string = 'Ой, кажется что-то пошло не так...';

interface ErrorFuseProps {
    errorText?: string;
}

/**
 * Компонент будет показан, когда в предохранителе сработает ошибка.
 * ВАЖНО, данный компонент должен использоваться только в предохранителе !
 * @constructor
 */
function ErrorFuse(props: ErrorFuseProps) {
    return (
        <div className={styles.componentContainer}>
            <Player
                autoplay
                src={errorFuseAnimation}
                loop={false}
                keepLastFrame={true}
                style={{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                    zIndex: '10',
                    marginTop: '-20px',
                    marginBottom: '-20px'
                }}
            />
            <div className={styles.oopsTextContainer}>{TEXT_ERROR}</div>
            {
                props.errorText ?
                    <div className={styles.errorTextContainer}>{props.errorText}</div>
                    : null
            }
        </div>
    );
}

export default React.memo(ErrorFuse);
