import {StoreApiUser} from "../../../api/storeApiUser";
import {action, computed, makeObservable, observable} from "mobx";
import {StorePopupBooking} from "./storePopupBooking";
import {RoomItemData} from "../../../interfaces/api/roomItemData";
import {BookingRoom, BookingRoomSuccess} from "../../../interfaces/api/bookingRoom";
import {convertDateToStringUTC} from "../../../helpers/convertDateToStringUTC";
import {convertDateToEndToStringUTC} from "../../../helpers/convertDateToEndToStringUTC";
import {BookingResultVM} from "../../../api/src";
import {
    convertServerBookingModelToClientModel
} from "../../../helpers/validation/convertServerBookingModelToClientModel";
import dateFormatDayMonthYear from "../../../helpers/dateFormatDayMonthYear";
import {getRequestErrorText} from "../../../api/helpers/getRequestErrorText";
import {StoreFullScreenPopup} from "../storeFullScreenPopup";

interface InitDataStoreBookingRoom {
    storeApi: StoreApiUser;
    storeFullScreenPopup: StoreFullScreenPopup;
}

export class StoreBookingRoom {
    private readonly _storeApi: StoreApiUser;
    private readonly _storeFullScreenPopup: StoreFullScreenPopup;

    private _fullyBookedDays_observable: string[];

    private _setFullyBookedDays_action(days: string[]) {
        this._fullyBookedDays_observable = days;
    }

    get fullyBookedDays() {
        return this._fullyBookedDays_observable;
    }

    private _storePopupBooking_observable?: StorePopupBooking;

    private _setStorePopupBooking(inputStore?: StorePopupBooking) {
        this._storePopupBooking_observable = inputStore;
    }

    get storePopupBooking() {
        return this._storePopupBooking_observable;
    }

    constructor(initData: InitDataStoreBookingRoom) {
        this._storeApi = initData.storeApi;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;

        this._fullyBookedDays_observable = [];
        this._storePopupBooking_observable = undefined;

        this._eventClosePopupBookingRoom = this._eventClosePopupBookingRoom.bind(this);
        this.isOutsideRange = this.isOutsideRange.bind(this);

        makeObservable<this,
            | '_fullyBookedDays_observable'
            | '_storePopupBooking_observable'
            | '_setFullyBookedDays_action'
            | '_setStorePopupBooking'>(this, {
            _fullyBookedDays_observable: observable,
            _storePopupBooking_observable: observable,
            _setFullyBookedDays_action: action,
            _setStorePopupBooking: action,
            isOutsideRange: action,
            fullyBookedDays: computed,
            storePopupBooking: computed
        });
    }

    /**
     * Показать popup для бронирования номера пользователем
     * @param item
     */
    public async showPopupBookingRoom(item?: RoomItemData) {
        if (!item) {
            this._storeFullScreenPopup.error = {
                errorText: 'Номер не найден',
                eventClose: this._storeFullScreenPopup.closeAllWindows
            };
            return;
        }

        const roomId: number = item.id;

        const store: StorePopupBooking = new StorePopupBooking({
            roomId: roomId,
            eventClosePopup: this._eventClosePopupBookingRoom,
            eventSaveData: (data: BookingRoom) => {
                // Делаем запрос
                this._serverRequestBookingRoom(data);
                // Удаляем окно выбора файла
                this._setStorePopupBooking(undefined);
            }
        });

        const getBookedDays: boolean = await this._serverRequestGetFullyBookedDays(roomId);

        if (!getBookedDays) {
            return;
        }

        this._setStorePopupBooking(store);
    }

    private _eventClosePopupBookingRoom() {
        this._setStorePopupBooking(undefined);
    }

    /**
     * Блокировка дней
     * @param day
     * @public
     */
    public isOutsideRange(day: any): boolean {
        if (this.fullyBookedDays) {
            for (let i = 0; i < this.fullyBookedDays.length; ++i) {
                if (day._d.setHours(12,0,0,0) === new Date(this.fullyBookedDays[i]).setHours(12,0,0,0)) {
                    return true;
                }
            }
        }

        if (day._d.setHours(12, 0, 0, 0) < new Date().setHours(12, 0, 0, 0)) {
            return true;
        }

        return false;
    }

    private _checkBlockedDays() {
        // Проверка выбора диапазона дат
        if (this._fullyBookedDays_observable) {
            for (let i = 0; i < this._fullyBookedDays_observable.length; ++i) {
                // if (new Date(this.fullyBookedDays[i]) > new Date(cloneNewRowData.checkIn) && new Date(this.fullyBookedDays[i]) < new Date(cloneNewRowData.checkOut)) {
                //     this._storeFullScreenPopup.error = {
                //         errorText: 'Ошибка выбора диапазона дат. В диапазоне содержатся недоступные для бронирования дни',
                //         eventClose: () => {
                //             //Закрываем загрузку
                //             this._storeFullScreenPopup.closeAllWindows();
                //             //Снова показываем окно редактирования
                //             this._showPopupAddNewBooking(cloneNewRowData);
                //         }
                //     }
                //     return;
                // }
            }
        }
    }

    /**
     * Получить занятые дни для комнаты (номера)
     * @param roomId
     * @private
     */
    public async _serverRequestGetFullyBookedDays(roomId: number): Promise<boolean> {
        let getBookedDays = false;

        this._storeFullScreenPopup.loading = {
            loadingText: 'Загрузка забронированный дней'
        }

        await this._storeApi.apiBooking.bookingGetFullyBookedDaysGet(roomId)
            .then((inputDataDays) => {
                const bookedDays: string[] = inputDataDays.data;
                // Установка данных
                this._setFullyBookedDays_action(bookedDays);
                getBookedDays = true;
                // Закрываем окно загрузки
                this._storeFullScreenPopup.closeAllWindows();
            })
            .catch((errorText) => {
                //Показываем окно ошибки
                this._storeFullScreenPopup.error = {
                    errorText: getRequestErrorText(errorText),
                    eventClose: this._storeFullScreenPopup.closeAllWindows,
                }
            });

        return getBookedDays;
    }

    /**
     * Бронирование номера пользователем - запрос к серверу
     * @param inputData
     * @private
     */
    private _serverRequestBookingRoom(inputData: BookingRoom) {
        // Показываем загрузку
        this._storeFullScreenPopup.loading = {
            loadingText: 'Бронирование номера'
        };

        this._storeApi.apiBooking.bookingBookRoomPost({
            roomId: inputData.roomId,
            checkIn: convertDateToStringUTC(inputData.checkIn, true),
            checkOut: convertDateToEndToStringUTC(inputData.checkOut),
            name: inputData.name,
            phone: inputData.phone
        })
            .then((serverSuccessBookRoom) => {
                const bookRoomInfo: BookingResultVM = serverSuccessBookRoom.data;
                // Валидация данных
                const validData: BookingRoomSuccess | undefined = convertServerBookingModelToClientModel(bookRoomInfo);

                if (!validData) {
                    return;
                }

                let successText: string = 'Заявка на бронирование успешно отправлена';
                if (validData.checkIn && validData.checkOut) {
                    successText = 'Заявка на бронирование с ' + dateFormatDayMonthYear(validData.checkIn) + ' по ' + dateFormatDayMonthYear(validData.checkOut) + ' успешно отправлена';
                }
                // Закрываем окно загрузки
                this._storeFullScreenPopup.success = {
                    eventClose: this._storeFullScreenPopup.closeAllWindows,
                    successText: successText,
                };
            })
            .catch(error => {
                // Показываем окно с ошибкой
                this._storeFullScreenPopup.error = {
                    errorText: getRequestErrorText(error),
                    eventClose: this._storeFullScreenPopup.closeAllWindows
                };
            });
    }
    // endregion
}
