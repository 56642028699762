import React, {useEffect} from "react";
import styles from './headerMobileStyle.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";

interface HeaderMobileProps {
    pageTitle?: string;
    mobileMenuItems?: JSX.Element[];
}

function HeaderMobile(props: HeaderMobileProps) {
    useEffect(() => {
        let hamburger: Element | null = document.querySelector('#hamburger');
        let menu: Element | null = document.querySelector('#menu');

        const toggleMenu = () => {
            if (menu && hamburger) {
                menu.classList.toggle(styles.active);
                hamburger.classList.toggle(styles.activeHamburger)
            }
        }

        hamburger?.addEventListener('click', e => {
            e.stopPropagation();
            toggleMenu();
        });

        document.addEventListener('click', e => {
            if (menu && hamburger) {
                menu.classList.remove(styles.active);
                hamburger.classList.remove(styles.activeHamburger);

                const target: EventTarget | null = e.target;
                const itsMenu = target == menu || menu.contains(target as Node);
                const itsHamburger = target == hamburger;
                const menuIsActive = menu?.classList.contains(styles.active);

                if (!itsMenu && !itsHamburger && menuIsActive) {
                    toggleMenu();
                }
            }
        })
    }, []);

    return (
        <div className={styles.mobileContainer}>
            <div className={styles.container}>
                {
                    props.pageTitle ? <span className={styles.pageTitle}>{props.pageTitle}</span> : null
                }
                {
                    props.mobileMenuItems ?
                        <div className={styles.dropdown}>
                            <FontAwesomeIcon
                                id={'hamburger'}
                                icon={faEllipsisVertical}
                                className={styles.menuIconWrap}
                            />
                            <div
                                id={'menu'}
                                className={styles.dropdownContent}
                            >
                                {props.mobileMenuItems}
                            </div>
                        </div> : null
                }
            </div>
        </div>
    );
}

export default React.memo(HeaderMobile);
