import {action, computed, makeObservable, observable} from "mobx";
import {HotelSpecification} from "../../interfaces/api/hotelSpecification";
import {HotelSpecificationVM} from "../../api/src";

export class StoreHotelSpecificationInfo {
    private _hotelSpecificationInfo_observable?: Readonly<HotelSpecification> | null;

    constructor() {
        this._hotelSpecificationInfo_observable = undefined;

        makeObservable<this,
            '_hotelSpecificationInfo_observable'>(this, {
            _hotelSpecificationInfo_observable: observable.ref,
            setHotelSpecificationInfo: action,
            resetHotelSpecificationInfo: action,
            hotelSpecificationInfo: computed,
        });
    }

    get hotelSpecificationInfo(): HotelSpecification | null | undefined {
        if (this._hotelSpecificationInfo_observable === undefined) {
            return undefined;
        }

        if (this._hotelSpecificationInfo_observable === null) {
            return null;
        }

        return Object.assign({}, this._hotelSpecificationInfo_observable);
    }

    /**
     * Установить информацию о пользователе
     * @param inputHotelSpecificationInfo
     */
    public setHotelSpecificationInfo(inputHotelSpecificationInfo?: HotelSpecification | null) {
        if (inputHotelSpecificationInfo === undefined) {
            this._hotelSpecificationInfo_observable = undefined;
            return;
        }

        if (inputHotelSpecificationInfo === null) {
            this._hotelSpecificationInfo_observable = null;
            return;
        }

        this._hotelSpecificationInfo_observable = Object.freeze(Object.assign({}, inputHotelSpecificationInfo));
    }

    /**
     * Сбросить информацию о спецификации отеля
     */
    public resetHotelSpecificationInfo() {
        this._hotelSpecificationInfo_observable = null;
    }

    public validationHotelSpecification(hotelSpecification: HotelSpecificationVM): HotelSpecification {
        const logo: string | null | undefined =  hotelSpecification.logo;
        const colors: string[] | null | undefined = hotelSpecification.colors;
        const filters: number[] | null | undefined = hotelSpecification.filters;
        const longitude: number | null | undefined  = hotelSpecification.longitude;
        const latitude: number | null | undefined = hotelSpecification.latitude;

        return ({
            logo: logo ?? undefined,
            colors: colors ?? undefined,
            filters: filters ?? undefined,
            longitude: longitude ?? undefined,
            latitude: latitude ?? undefined
        });
    }
}
