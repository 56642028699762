import {ValidationRangeValueResult} from "../../globalType/ValidationResultRange";
import {Moment} from "moment";

interface ValidationStringStandardOptions {
    dateStartToCheck: Moment | null;
    dateEndToCheck: Moment | null;
    errorText: string;
}

export default function validationStringRangeStandard(inputOptions: ValidationStringStandardOptions): ValidationRangeValueResult<Date | null, Date | null> {
    if (inputOptions.dateStartToCheck === null && inputOptions.dateEndToCheck === null) {
        return {
            errorText: undefined,
            validValueStart: null,
            validValueEnd: null
        }
    }

    if (inputOptions.dateStartToCheck === null && !inputOptions.dateEndToCheck === null || !inputOptions.dateStartToCheck === null && inputOptions.dateEndToCheck === null) {
        return {
            errorText: inputOptions.errorText,
            validValueStart: null,
            validValueEnd: null
        }
    }

    let dateStartToCheck: Date | undefined = inputOptions.dateStartToCheck?.toDate();
    let dateEndToCheck: Date | undefined = inputOptions.dateEndToCheck?.toDate();

    if (!dateStartToCheck || !dateEndToCheck) {
        return {
            errorText: inputOptions.errorText,
            validValueStart: null,
            validValueEnd: null
        }
    }

    return {
        errorText: undefined,
        validValueStart: dateStartToCheck,
        validValueEnd: dateEndToCheck
    }
}
