import {BaseStoreUserPageContent, InitDataBaseStoreUserPageContent} from "../base/baseStoreUserPageContent";
import {RouterPagesKeysUser} from "../../../staticData/routerPages/routerPagesUser";
import {StoreApiUser} from "../../../api/storeApiUser";
import {RoomItemData} from "../../../interfaces/api/roomItemData";
import {getRoomIdByUrl} from "../../../helpers/getRoomIdByUrl";
import {BookingResultVM, RoomPhotoVM, RoomVMExtended} from "../../../api/src";
import {getRequestErrorText} from "../../../api/helpers/getRequestErrorText";
import {convertServerRoomModelToClientModel} from "../../../helpers/validation/convertServerRoomModelToClientModel";
import {action, computed, makeObservable, observable} from "mobx";
import {RoomFirstPhotoItem, RoomPhotoItem} from "../../../interfaces/api/roomPhotoCollection";
import {
    convertServerRoomFirstPhotoModelToClientModel
} from "../../../helpers/validation/convertServerRoomFirstPhotoModelToClientModel";
import {ErrorText} from "../../../globalType/ErrorText";
import {Moment} from "moment";
import {BookingRoom, BookingRoomSuccess} from "../../../interfaces/api/bookingRoom";
import {convertDateToStringUTC} from "../../../helpers/convertDateToStringUTC";
import {convertDateToEndToStringUTC} from "../../../helpers/convertDateToEndToStringUTC";
import {
    convertServerBookingModelToClientModel
} from "../../../helpers/validation/convertServerBookingModelToClientModel";
import dateFormatDayMonthYear from "../../../helpers/dateFormatDayMonthYear";
import {StoreUserBookingRoom} from "./storeUserBookingRoom";

export interface Dates {
    startDate: Moment | null;
    endDate: Moment | null;
}

export type FocusedInputShape = 'startDate' | 'endDate';

export class StoreUserPageContentBooking extends BaseStoreUserPageContent<RoomItemData, StoreApiUser, RouterPagesKeysUser> {
    private _storeUserBookingRoom?: StoreUserBookingRoom;

    get storeUserBookingRoom() {
        return this._storeUserBookingRoom;
    }

    private readonly _roomId?: number;

    private _roomInfo_observable?: RoomItemData;

    private _setRoomInfo_action(roomInfo?: RoomItemData) {
        this._roomInfo_observable = roomInfo;
    }

    get roomInfo(): RoomItemData | undefined {
        return this._roomInfo_observable;
    }

    // region Первое фото номера
    private _firstRoomPhoto_observable?: RoomPhotoItem | null;

    private _setFirstRoomPhoto_action(photo?: RoomPhotoItem | null) {
        this._firstRoomPhoto_observable = photo;
    }

    get firstRoomPhoto() {
        return this._firstRoomPhoto_observable;
    }
    // endregion

    // region Ошибка получения фото
    private _firstRoomPhotoError_observable: ErrorText;

    private _setFirstRoomPhotoError_action(error: ErrorText) {
        this._firstRoomPhotoError_observable = error;
    }

    get firstRoomPhotoError() {
        return this._firstRoomPhotoError_observable;
    }
    // endregion

    private _fullyBookedDays_observable?: string[];

    private _setFullyBookedDays_action(days?: string[]) {
        this._fullyBookedDays_observable = days;
    }

    get fullyBookedDays() {
        return this._fullyBookedDays_observable;
    }

    private _fullyBookedDaysError_observable: ErrorText;

    private _setFullyBookedDaysError_action(error: ErrorText) {
        this._fullyBookedDaysError_observable = error;
    }

    get fullyBookedDaysError() {
        return this._fullyBookedDaysError_observable;
    }

    constructor(initData: InitDataBaseStoreUserPageContent<RoomItemData, StoreApiUser, RouterPagesKeysUser>) {
        super(initData);

        this._storeUserBookingRoom = undefined;

        this._roomId = getRoomIdByUrl();
        this._roomInfo_observable = undefined;
        this._firstRoomPhoto_observable = undefined;
        this._firstRoomPhotoError_observable = undefined;
        this._fullyBookedDays_observable = undefined;
        this._fullyBookedDaysError_observable = undefined;

        makeObservable<this,
            '_roomInfo_observable'
            | '_firstRoomPhoto_observable'
            | '_firstRoomPhotoError_observable'
            | '_fullyBookedDays_observable'
            | '_fullyBookedDaysError_observable'
            | '_setRoomInfo_action'
            | '_setFirstRoomPhoto_action'
            | '_setFirstRoomPhotoError_action'
            | '_setFullyBookedDays_action'
            | '_setFullyBookedDaysError_action'>(this, {
            _roomInfo_observable: observable,
            _firstRoomPhoto_observable: observable,
            _firstRoomPhotoError_observable: observable,
            _fullyBookedDays_observable: observable,
            _fullyBookedDaysError_observable: observable,
            _setRoomInfo_action: action,
            _setFirstRoomPhoto_action: action,
            _setFirstRoomPhotoError_action: action,
            _setFullyBookedDays_action: action,
            _setFullyBookedDaysError_action: action,
            roomInfo: computed,
            firstRoomPhoto: computed,
            firstRoomPhotoError: computed,
            fullyBookedDays: computed,
            fullyBookedDaysError: computed
        });
    }

    public async serverRequestGetAllStoreData() {
        if (!this._roomId) {
            this._setErrorWhileGettingData('Номер не найден');
            return;
        }

        await this._serverRequestGetRoomInfo(this._roomId);
        await this._serverRequestGetFullyBookedDays(this._roomId);

        this._storeUserBookingRoom = new StoreUserBookingRoom({
            fullyBookedDays: this.fullyBookedDays ?? [],
            storeFullScreenPopup: this._storeFullScreenPopup,
            roomId: this._roomId,
            eventSaveData: (data: BookingRoom) => {
                this._serverRequestBookingRoom(data);
            }
        });
    }

    /**
     * Получить информацию о номере
     * @param roomId
     * @private
     */
    private async _serverRequestGetRoomInfo(roomId: number) {
        await this._storeApi.apiRoom.roomGetRoomByIdGet(this._roomId)
            .then((inputData) => {
                const roomData: RoomVMExtended = inputData.data;
                // Валидация данных
                const validData = convertServerRoomModelToClientModel(roomData);
                // Запоминаем данные
                this._setRoomInfo_action(validData);
            })
            .catch(error => {
                // Получаем текст ошибки
                const errorText: string = getRequestErrorText(error);
                // Показываем ошибку получения данных
                this._setErrorWhileGettingData(errorText);
            });
    }

    /**
     * Получение изображения номера
     * @param roomId
     * @public
     */
    public serverRequestGetFirstRoomPhoto(roomId: number) {
        this._storeApi.apiRoom.roomGetRoomPhotoFirstGet(roomId)
            .then((inputData) => {
                const inputPhoto: RoomPhotoVM = inputData.data;
                // Валидация данных
                const validPhotosData: RoomFirstPhotoItem | undefined = convertServerRoomFirstPhotoModelToClientModel(inputPhoto);
                if (!validPhotosData) {
                    return;
                }
                // Установка данных
                this._setFirstRoomPhoto_action(validPhotosData.photo)
            })
            .catch((errorText) => {
                this._setFirstRoomPhotoError_action(getRequestErrorText(errorText))
            });
    }

    /**
     * Получить занятые дни для комнаты (номера)
     * @param roomId
     * @private
     */
    private async _serverRequestGetFullyBookedDays(roomId: number) {
        await this._storeApi.apiBooking.bookingGetFullyBookedDaysGet(roomId)
            .then((inputDataDays) => {
                const bookedDays: string[] = inputDataDays.data;
                // Установка данных
                this._setFullyBookedDays_action(bookedDays);
            })
            .catch((errorText) => {
                this._setFullyBookedDaysError_action(getRequestErrorText(errorText));
            });
    }

    /**
     * Бронирование номера пользователем - запрос к серверу
     * @param inputData
     * @private
     */
    private _serverRequestBookingRoom(inputData: BookingRoom) {
        // Показываем загрузку
        this._storeFullScreenPopup.loading = {
            loadingText: 'Бронирование номера'
        };

        this._storeApi.apiBooking.bookingBookRoomPost({
            roomId: inputData.roomId,
            checkIn: convertDateToStringUTC(inputData.checkIn, true),
            checkOut: convertDateToEndToStringUTC(inputData.checkOut),
            name: inputData.name,
            phone: inputData.phone
        })
            .then((serverSuccessBookRoom) => {
                const bookRoomInfo: BookingResultVM = serverSuccessBookRoom.data;
                // Валидация данных
                const validData: BookingRoomSuccess | undefined = convertServerBookingModelToClientModel(bookRoomInfo);

                if (!validData) {
                    return;
                }

                let successText: string = 'Заявка на бронирование успешно отправлена';
                if (validData.checkIn && validData.checkOut) {
                    successText = 'Заявка на бронирование с ' + dateFormatDayMonthYear(validData.checkIn) + ' по ' + dateFormatDayMonthYear(validData.checkOut) + ' успешно отправлена';
                }
                // Закрываем окно загрузки
                this._storeFullScreenPopup.success = {
                    buttonCloseLinkHref: `${this._storeRouter.getPagePath('detail')}/${this._roomId}`,
                    successText: successText,
                };
            })
            .catch(error => {
                // Показываем окно с ошибкой
                this._storeFullScreenPopup.error = {
                    errorText: getRequestErrorText(error),
                    eventClose: this._storeFullScreenPopup.closeAllWindows
                };
            });
    }
}
