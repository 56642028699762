import React from "react";
import {StoreSiteTopMenu} from "../../../../stores/common/storeSiteTopMenu";
import {HeaderDesktop, HeaderMobile} from "../header";
import {PageHeaderItemLink, PageHeaderItemLinkMobile} from "../itemLink";
import {SitePageInfo} from "../../../../interfaces/sitePageInfo";
import {SiteTopMenuContainer} from "../../siteTopMenuContainer";
import {RouterPagesKeysUser} from "../../../../staticData/routerPages/routerPagesUser";

interface SiteTopMenuUserProps {
    readonly storeSiteTopMenu: StoreSiteTopMenu<RouterPagesKeysUser>;
    readonly pageTitle: string;
    readonly headerTitle: string;
}

function SiteTopMenuUser(props: SiteTopMenuUserProps) {
    const menuItems: JSX.Element[] = [];
    const pagesListForMenu = props.storeSiteTopMenu.storeRouter.getPagesListByKeys(['userHome', 'rooms']);
    const pagesListForMenuMobile: JSX.Element[] = [];

    for (let i = 0; i < pagesListForMenu.length; ++i) {
        const currentItem: SitePageInfo = pagesListForMenu[i];

        if (!currentItem.menu) {
            continue;
        }

        menuItems.push(
            <PageHeaderItemLink
                key={pagesListForMenu[i].internalId}
                pageInfo={pagesListForMenu[i]}
            />
        );

        pagesListForMenuMobile.push(
            <PageHeaderItemLinkMobile
                key={pagesListForMenu[i].internalId}
                fullPath={pagesListForMenu[i].fullPath}
                name={currentItem.menu.publicName}
                icon={currentItem.menu.icon}
            />
        );
    }

    return (
        <SiteTopMenuContainer>
            <HeaderDesktop
                linkHomePage={props.storeSiteTopMenu.storeRouter.getPagePath('userHome')}
                menuItems={menuItems}
                logo={props.storeSiteTopMenu.storeUserHotelLogo.hotelLogo}
                headerTitle={props.headerTitle}
            />
            <HeaderMobile
                mobileMenuItems={pagesListForMenuMobile}
                pageTitle={props.pageTitle}
            />
        </SiteTopMenuContainer>
    );
}

export default React.memo(SiteTopMenuUser);
