import React from "react";
import styles from "./yandexMapsStyle.scss";
import { YMaps, Map, Placemark, ZoomControl} from 'react-yandex-maps';
import {observer} from "mobx-react";
import {SmartComponentProps} from "../../../interfaces/smartComponentProps";
import {StoreUserHotelCoordinates} from "../../../stores/user/hotelCoordinates/storeUserHotelCoordinates";

interface YandexMapsProps extends SmartComponentProps<StoreUserHotelCoordinates>{
    balloonText?: string;
    className?: string;
}

function YandexMaps(props: YandexMapsProps) {
    if (!props.store.hotelCoordinates) {
        return null;
    }

    return (
        <div className={props.className ? `${styles.yandexMapContainer} ${props.className}` : styles.yandexMapContainer}>
            <YMaps>
                <Map
                    defaultState={{ center: [props.store.hotelCoordinates.longitude, props.store.hotelCoordinates.latitude], zoom: 15 }}
                    className={styles.yandexMapInner}
                >
                    <Placemark
                        geometry={[props.store.hotelCoordinates.longitude, props.store.hotelCoordinates.latitude]}
                        options={{
                            preset: 'islands#nightDotIcon',
                            iconColor: '#3396CE',
                        }}
                        properties={{
                            balloonContentHeader: '',
                            balloonContentBody: props.balloonText,
                        }}
                        modules={['geoObject.addon.balloon']}
                    />
                    <ZoomControl
                        options={{ float: 'left' }}
                    />
                </Map>
            </YMaps>
        </div>
    );
}

export default observer(YandexMaps);
