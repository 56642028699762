import React from "react";
import {faHeadset} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {observer} from "mobx-react";
import ChatIco from "../../../img/svg_component/chat.svg";
import {StoreSiteBottomMenu} from "../../../stores/common/storeSiteBottomMenu";
import {UserSendFeedbackEditor} from "../../user/userSendFeedbackEditor";
import styles from "./siteBottomMenuStyle.scss";
import {YandexMaps} from "../../common/yandexMaps";

const TEXT_TECHNICAL_SUPPORT: string = 'Тех. поддержка';
const TEXT_SUPPORT_EMAIL: string = 'helpService@sfx-tula.ru';
const CONTACT_US: string = 'Связаться с нами';

interface SiteBottomMenuProps {
    readonly storeSiteBottomMenu?: StoreSiteBottomMenu;
    readonly isUnAuthorized?: boolean;
}

function SiteBottomMenu(props: SiteBottomMenuProps) {
    return (
        <>
            {
                props.storeSiteBottomMenu && !props.isUnAuthorized ?
                    <YandexMaps
                        store={props.storeSiteBottomMenu.storeUserHotelCoordinates}
                        balloonText={props.storeSiteBottomMenu.storeUserHotelInfo.hotelInfo?.title}
                    /> : null
            }
            <footer className={props.isUnAuthorized ? `${styles.componentContainer} ${styles.unAuthComponentContainer}` : styles.componentContainer}>
                <div className={styles.innerContainer}>
                    <div className={styles.container}>
                        <FontAwesomeIcon
                            icon={faHeadset}
                            className={styles.supportIcon}
                        />
                        <div className={styles.bottomMenuInfo}>
                            <span>{TEXT_TECHNICAL_SUPPORT}</span>
                            <a
                                href={'mailto:' + TEXT_SUPPORT_EMAIL}
                                className={styles.numberForTechSupport}
                            >
                                {TEXT_SUPPORT_EMAIL}
                            </a>
                        </div>
                    </div>
                    {
                        props.storeSiteBottomMenu ?
                            <div
                                className={styles.container}
                                onClick={props.storeSiteBottomMenu.storeFeedback.eventSendFeedback}
                            >
                                <ChatIco/>
                                <div className={styles.contactContainer}>
                                    <span>{CONTACT_US}</span>
                                </div>
                            </div> : null
                    }
                </div>
            </footer>
            {
                props.storeSiteBottomMenu ? <UserSendFeedbackEditor store={props.storeSiteBottomMenu.storeFeedback}/> : null
            }
        </>
    );
}

export default observer(SiteBottomMenu);
