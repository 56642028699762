import React from "react";
import styles from "./blockContainerStyle.scss";

interface BlockContainerProps {
    children: React.ReactNode;
    className?: string;
}

function BlockContainer(props: BlockContainerProps) {
    return (
        <div className={props.className ? `${styles.componentContainer} ${props.className}` : styles.componentContainer}>
            {props.children}
        </div>
    )
}

export default React.memo(BlockContainer);
