import React from "react";
import styles from "./checkboxStyle.scss";

interface CheckboxProps {
    name?: string;
    value?: string | number;
    className?: string;
    checked?: boolean;
    eventChange?: (e: React.ChangeEvent<HTMLInputElement>, index?: number) => void;
    disabled?: boolean;
}

function Checkbox(props: CheckboxProps) {
    const cssClass = props.disabled ? `${styles.checkbox} ${styles.disabledCheckboxContainer}` : styles.checkbox;
    return (
        <div className={props.className ? `${cssClass} ${props.className}` : cssClass}>
            <label className={styles.customCheckbox}>
                <input
                    type={'checkbox'}
                    name={props.name}
                    value={props.value}
                    checked={props.checked}
                    onChange={props.eventChange}
                />
                <span>{props.name}</span>
            </label>
        </div>
    );
}

export default React.memo(Checkbox);
