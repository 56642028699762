/* tslint:disable */
/* eslint-disable */
/**
 * BookingAppClient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BookingRequestVM
 */
export interface BookingRequestVM {
    /**
     * 
     * @type {number}
     * @memberof BookingRequestVM
     */
    'roomId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingRequestVM
     */
    'checkIn'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequestVM
     */
    'checkOut'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRequestVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BookingRequestVM
     */
    'phone'?: string | null;
}
/**
 * 
 * @export
 * @interface BookingResultVM
 */
export interface BookingResultVM {
    /**
     * 
     * @type {number}
     * @memberof BookingResultVM
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof BookingResultVM
     */
    'roomId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingResultVM
     */
    'checkIn'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingResultVM
     */
    'checkOut'?: string;
}
/**
 * 
 * @export
 * @interface CheckInTimeShortVM
 */
export interface CheckInTimeShortVM {
    /**
     * 
     * @type {string}
     * @memberof CheckInTimeShortVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CheckInTimeShortVM
     */
    'hour'?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckInTimeShortVM
     */
    'minute'?: number;
}
/**
 * 
 * @export
 * @interface HotelInfoVM
 */
export interface HotelInfoVM {
    /**
     * 
     * @type {string}
     * @memberof HotelInfoVM
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HotelInfoVM
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<PhotoVM>}
     * @memberof HotelInfoVM
     */
    'photos'?: Array<PhotoVM> | null;
}
/**
 * 
 * @export
 * @interface HotelSpecificationVM
 */
export interface HotelSpecificationVM {
    /**
     * 
     * @type {string}
     * @memberof HotelSpecificationVM
     */
    'logo'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof HotelSpecificationVM
     */
    'colors'?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof HotelSpecificationVM
     */
    'filters'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof HotelSpecificationVM
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HotelSpecificationVM
     */
    'longitude'?: number | null;
}
/**
 * 
 * @export
 * @interface PhotoVM
 */
export interface PhotoVM {
    /**
     * 
     * @type {number}
     * @memberof PhotoVM
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PhotoVM
     */
    'photo'?: string | null;
}
/**
 * 
 * @export
 * @interface RoomCategoryVMExtended
 */
export interface RoomCategoryVMExtended {
    /**
     * 
     * @type {number}
     * @memberof RoomCategoryVMExtended
     */
    'hotelID'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomCategoryVMExtended
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomCategoryVMExtended
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RoomCategoryVMExtended
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface RoomEquipmentItemExtendedVM
 */
export interface RoomEquipmentItemExtendedVM {
    /**
     * 
     * @type {string}
     * @memberof RoomEquipmentItemExtendedVM
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RoomEquipmentItemExtendedVM
     */
    'price'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoomEquipmentItemExtendedVM
     */
    'isDeleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof RoomEquipmentItemExtendedVM
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface RoomEquipmentVMExtended
 */
export interface RoomEquipmentVMExtended {
    /**
     * 
     * @type {number}
     * @memberof RoomEquipmentVMExtended
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomEquipmentVMExtended
     */
    'roomId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomEquipmentVMExtended
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<RoomEquipmentItemExtendedVM>}
     * @memberof RoomEquipmentVMExtended
     */
    'equipment'?: Array<RoomEquipmentItemExtendedVM> | null;
}
/**
 * 
 * @export
 * @interface RoomPhotoVM
 */
export interface RoomPhotoVM {
    /**
     * 
     * @type {number}
     * @memberof RoomPhotoVM
     */
    'roomId'?: number;
    /**
     * 
     * @type {PhotoVM}
     * @memberof RoomPhotoVM
     */
    'photo'?: PhotoVM;
}
/**
 * 
 * @export
 * @interface RoomPhotoVMCollection
 */
export interface RoomPhotoVMCollection {
    /**
     * 
     * @type {number}
     * @memberof RoomPhotoVMCollection
     */
    'roomId'?: number;
    /**
     * 
     * @type {Array<PhotoVM>}
     * @memberof RoomPhotoVMCollection
     */
    'photos'?: Array<PhotoVM> | null;
}
/**
 * 
 * @export
 * @interface RoomVMExtended
 */
export interface RoomVMExtended {
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'hotelID'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomVMExtended
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'capacity'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'space'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'categoryID'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoomVMExtended
     */
    'withKids'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoomVMExtended
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoomVMExtended
     */
    'shortDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoomVMExtended
     */
    'isRecommended'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'childAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'discount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RoomVMExtended
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RoomVMExtended
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface TokenResultVM
 */
export interface TokenResultVM {
    /**
     * 
     * @type {string}
     * @memberof TokenResultVM
     */
    'access_token'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TokenResultVM
     */
    'expires_in'?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenResultVM
     */
    'token_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenResultVM
     */
    'scope'?: string | null;
}

/**
 * BookingApi - axios parameter creator
 * @export
 */
export const BookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Забронировать комнату
         * @param {BookingRequestVM} [bookingRequestVM] Данные
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingBookRoomPost: async (bookingRequestVM?: BookingRequestVM, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Booking/BookRoom`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingRequestVM, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingFormTokenPost: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Booking/FormToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить список полностью занятых дней
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingGetFullyBookedDaysGet: async (roomId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Booking/GetFullyBookedDays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (roomId !== undefined) {
                localVarQueryParameter['roomId'] = roomId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поиск свободных номеров
         * @param {string} [checkIn] 
         * @param {string} [checkOut] 
         * @param {number} [roomCount] 
         * @param {number} [capacity] 
         * @param {boolean} [withKids] 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSearchFreeHotelRoomsGet: async (checkIn?: string, checkOut?: string, roomCount?: number, capacity?: number, withKids?: boolean, categoryId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Booking/SearchFreeHotelRooms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (checkIn !== undefined) {
                localVarQueryParameter['checkIn'] = (checkIn as any instanceof Date) ?
                    (checkIn as any).toISOString() :
                    checkIn;
            }

            if (checkOut !== undefined) {
                localVarQueryParameter['checkOut'] = (checkOut as any instanceof Date) ?
                    (checkOut as any).toISOString() :
                    checkOut;
            }

            if (roomCount !== undefined) {
                localVarQueryParameter['roomCount'] = roomCount;
            }

            if (capacity !== undefined) {
                localVarQueryParameter['capacity'] = capacity;
            }

            if (withKids !== undefined) {
                localVarQueryParameter['withKids'] = withKids;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingApi - functional programming interface
 * @export
 */
export const BookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Забронировать комнату
         * @param {BookingRequestVM} [bookingRequestVM] Данные
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingBookRoomPost(bookingRequestVM?: BookingRequestVM, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResultVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingBookRoomPost(bookingRequestVM, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingFormTokenPost(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResultVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingFormTokenPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить список полностью занятых дней
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingGetFullyBookedDaysGet(roomId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingGetFullyBookedDaysGet(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Поиск свободных номеров
         * @param {string} [checkIn] 
         * @param {string} [checkOut] 
         * @param {number} [roomCount] 
         * @param {number} [capacity] 
         * @param {boolean} [withKids] 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingSearchFreeHotelRoomsGet(checkIn?: string, checkOut?: string, roomCount?: number, capacity?: number, withKids?: boolean, categoryId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomVMExtended>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingSearchFreeHotelRoomsGet(checkIn, checkOut, roomCount, capacity, withKids, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingApi - factory interface
 * @export
 */
export const BookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingApiFp(configuration)
    return {
        /**
         * 
         * @summary Забронировать комнату
         * @param {BookingRequestVM} [bookingRequestVM] Данные
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingBookRoomPost(bookingRequestVM?: BookingRequestVM, options?: any): AxiosPromise<BookingResultVM> {
            return localVarFp.bookingBookRoomPost(bookingRequestVM, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingFormTokenPost(id?: string, options?: any): AxiosPromise<TokenResultVM> {
            return localVarFp.bookingFormTokenPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить список полностью занятых дней
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingGetFullyBookedDaysGet(roomId?: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.bookingGetFullyBookedDaysGet(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поиск свободных номеров
         * @param {string} [checkIn] 
         * @param {string} [checkOut] 
         * @param {number} [roomCount] 
         * @param {number} [capacity] 
         * @param {boolean} [withKids] 
         * @param {number} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingSearchFreeHotelRoomsGet(checkIn?: string, checkOut?: string, roomCount?: number, capacity?: number, withKids?: boolean, categoryId?: number, options?: any): AxiosPromise<Array<RoomVMExtended>> {
            return localVarFp.bookingSearchFreeHotelRoomsGet(checkIn, checkOut, roomCount, capacity, withKids, categoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingApi - object-oriented interface
 * @export
 * @class BookingApi
 * @extends {BaseAPI}
 */
export class BookingApi extends BaseAPI {
    /**
     * 
     * @summary Забронировать комнату
     * @param {BookingRequestVM} [bookingRequestVM] Данные
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingBookRoomPost(bookingRequestVM?: BookingRequestVM, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingBookRoomPost(bookingRequestVM, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingFormTokenPost(id?: string, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingFormTokenPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить список полностью занятых дней
     * @param {number} [roomId] Идентификатор комнаты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingGetFullyBookedDaysGet(roomId?: number, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingGetFullyBookedDaysGet(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поиск свободных номеров
     * @param {string} [checkIn] 
     * @param {string} [checkOut] 
     * @param {number} [roomCount] 
     * @param {number} [capacity] 
     * @param {boolean} [withKids] 
     * @param {number} [categoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookingSearchFreeHotelRoomsGet(checkIn?: string, checkOut?: string, roomCount?: number, capacity?: number, withKids?: boolean, categoryId?: number, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookingSearchFreeHotelRoomsGet(checkIn, checkOut, roomCount, capacity, withKids, categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeedbackApi - axios parameter creator
 * @export
 */
export const FeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Обратная связь
         * @param {string} [name] Пользователь
         * @param {string} [email] Почта
         * @param {string} [subject] Заголовок
         * @param {string} [message] Текст
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackSendFeedbackPost: async (name?: string, email?: string, subject?: string, message?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Feedback/SendFeedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedbackApi - functional programming interface
 * @export
 */
export const FeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Обратная связь
         * @param {string} [name] Пользователь
         * @param {string} [email] Почта
         * @param {string} [subject] Заголовок
         * @param {string} [message] Текст
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedbackSendFeedbackPost(name?: string, email?: string, subject?: string, message?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedbackSendFeedbackPost(name, email, subject, message, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeedbackApi - factory interface
 * @export
 */
export const FeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedbackApiFp(configuration)
    return {
        /**
         * 
         * @summary Обратная связь
         * @param {string} [name] Пользователь
         * @param {string} [email] Почта
         * @param {string} [subject] Заголовок
         * @param {string} [message] Текст
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedbackSendFeedbackPost(name?: string, email?: string, subject?: string, message?: string, options?: any): AxiosPromise<void> {
            return localVarFp.feedbackSendFeedbackPost(name, email, subject, message, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedbackApi - object-oriented interface
 * @export
 * @class FeedbackApi
 * @extends {BaseAPI}
 */
export class FeedbackApi extends BaseAPI {
    /**
     * 
     * @summary Обратная связь
     * @param {string} [name] Пользователь
     * @param {string} [email] Почта
     * @param {string} [subject] Заголовок
     * @param {string} [message] Текст
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedbackApi
     */
    public feedbackSendFeedbackPost(name?: string, email?: string, subject?: string, message?: string, options?: AxiosRequestConfig) {
        return FeedbackApiFp(this.configuration).feedbackSendFeedbackPost(name, email, subject, message, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HotelApi - axios parameter creator
 * @export
 */
export const HotelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hotelGetCheckInTimesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Hotel/GetCheckInTimes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить информацию об отеле
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hotelGetHotelInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Hotel/GetHotelInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить спецификацию отеля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hotelGetHotelSpecificationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Hotel/GetHotelSpecification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HotelApi - functional programming interface
 * @export
 */
export const HotelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HotelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hotelGetCheckInTimesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckInTimeShortVM>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hotelGetCheckInTimesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить информацию об отеле
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hotelGetHotelInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HotelInfoVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hotelGetHotelInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить спецификацию отеля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hotelGetHotelSpecificationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HotelSpecificationVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hotelGetHotelSpecificationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HotelApi - factory interface
 * @export
 */
export const HotelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HotelApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hotelGetCheckInTimesGet(options?: any): AxiosPromise<Array<CheckInTimeShortVM>> {
            return localVarFp.hotelGetCheckInTimesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить информацию об отеле
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hotelGetHotelInfoGet(options?: any): AxiosPromise<HotelInfoVM> {
            return localVarFp.hotelGetHotelInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить спецификацию отеля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hotelGetHotelSpecificationGet(options?: any): AxiosPromise<HotelSpecificationVM> {
            return localVarFp.hotelGetHotelSpecificationGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HotelApi - object-oriented interface
 * @export
 * @class HotelApi
 * @extends {BaseAPI}
 */
export class HotelApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HotelApi
     */
    public hotelGetCheckInTimesGet(options?: AxiosRequestConfig) {
        return HotelApiFp(this.configuration).hotelGetCheckInTimesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить информацию об отеле
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HotelApi
     */
    public hotelGetHotelInfoGet(options?: AxiosRequestConfig) {
        return HotelApiFp(this.configuration).hotelGetHotelInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить спецификацию отеля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HotelApi
     */
    public hotelGetHotelSpecificationGet(options?: AxiosRequestConfig) {
        return HotelApiFp(this.configuration).hotelGetHotelSpecificationGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomApi - axios parameter creator
 * @export
 */
export const RoomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получить информацию о номере
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomByIdGet: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Room/GetRoomById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить вместимость отелей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomCapacitiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Room/GetRoomCapacities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить категории номеров отеля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Room/GetRoomCategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Оборудование помещения
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomEquipmentsGet: async (roomId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Room/GetRoomEquipments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (roomId !== undefined) {
                localVarQueryParameter['roomId'] = roomId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получить количество комнат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomNumbersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Room/GetRoomNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Первое фото комнаты
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomPhotoFirstGet: async (roomId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Room/GetRoomPhotoFirst`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (roomId !== undefined) {
                localVarQueryParameter['roomId'] = roomId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Фото комнаты
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomPhotosGet: async (roomId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Room/GetRoomPhotos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (roomId !== undefined) {
                localVarQueryParameter['roomId'] = roomId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomApi - functional programming interface
 * @export
 */
export const RoomApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получить информацию о номере
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomGetRoomByIdGet(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomVMExtended>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomGetRoomByIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить вместимость отелей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomGetRoomCapacitiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomGetRoomCapacitiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить категории номеров отеля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomGetRoomCategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomCategoryVMExtended>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomGetRoomCategoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Оборудование помещения
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomGetRoomEquipmentsGet(roomId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomEquipmentVMExtended>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomGetRoomEquipmentsGet(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получить количество комнат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomGetRoomNumbersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomGetRoomNumbersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Первое фото комнаты
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomGetRoomPhotoFirstGet(roomId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomPhotoVM>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomGetRoomPhotoFirstGet(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Фото комнаты
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async roomGetRoomPhotosGet(roomId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomPhotoVMCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.roomGetRoomPhotosGet(roomId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomApi - factory interface
 * @export
 */
export const RoomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomApiFp(configuration)
    return {
        /**
         * 
         * @summary Получить информацию о номере
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomByIdGet(id?: number, options?: any): AxiosPromise<RoomVMExtended> {
            return localVarFp.roomGetRoomByIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить вместимость отелей
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomCapacitiesGet(options?: any): AxiosPromise<Array<number>> {
            return localVarFp.roomGetRoomCapacitiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить категории номеров отеля
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomCategoriesGet(options?: any): AxiosPromise<Array<RoomCategoryVMExtended>> {
            return localVarFp.roomGetRoomCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Оборудование помещения
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomEquipmentsGet(roomId?: number, options?: any): AxiosPromise<Array<RoomEquipmentVMExtended>> {
            return localVarFp.roomGetRoomEquipmentsGet(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получить количество комнат
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomNumbersGet(options?: any): AxiosPromise<Array<number>> {
            return localVarFp.roomGetRoomNumbersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Первое фото комнаты
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomPhotoFirstGet(roomId?: number, options?: any): AxiosPromise<RoomPhotoVM> {
            return localVarFp.roomGetRoomPhotoFirstGet(roomId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Фото комнаты
         * @param {number} [roomId] Идентификатор комнаты
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        roomGetRoomPhotosGet(roomId?: number, options?: any): AxiosPromise<RoomPhotoVMCollection> {
            return localVarFp.roomGetRoomPhotosGet(roomId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomApi - object-oriented interface
 * @export
 * @class RoomApi
 * @extends {BaseAPI}
 */
export class RoomApi extends BaseAPI {
    /**
     * 
     * @summary Получить информацию о номере
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomGetRoomByIdGet(id?: number, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomGetRoomByIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить вместимость отелей
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomGetRoomCapacitiesGet(options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomGetRoomCapacitiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить категории номеров отеля
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomGetRoomCategoriesGet(options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomGetRoomCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Оборудование помещения
     * @param {number} [roomId] Идентификатор комнаты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomGetRoomEquipmentsGet(roomId?: number, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomGetRoomEquipmentsGet(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получить количество комнат
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomGetRoomNumbersGet(options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomGetRoomNumbersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Первое фото комнаты
     * @param {number} [roomId] Идентификатор комнаты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomGetRoomPhotoFirstGet(roomId?: number, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomGetRoomPhotoFirstGet(roomId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Фото комнаты
     * @param {number} [roomId] Идентификатор комнаты
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public roomGetRoomPhotosGet(roomId?: number, options?: AxiosRequestConfig) {
        return RoomApiFp(this.configuration).roomGetRoomPhotosGet(roomId, options).then((request) => request(this.axios, this.basePath));
    }
}


