import React from "react";
import styles from './textEditorMultilineStyle.scss';

interface TextEditorMultilineProps {
    value?: string | number | readonly string[];
    defaultValue?: string | number | readonly string[];
    eventChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
    classNameInput?: string;
    errorText?: string;
    readonly?: boolean;
    placeholder?: string;
}

/**
 * Редактор многострочного текста
 * @param props
 * @constructor
 */
function TextEditorMultiline(props: TextEditorMultilineProps) {
    const cssClass = props.classNameInput ? `${styles.textarea} ${props.classNameInput}` : styles.textarea;
    return (
        <div className={props.className ? props.className : styles.componentContainer}>
            <textarea
                className={props.errorText ? `${styles.textareaStatusError} ${cssClass}` : cssClass}
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={props.eventChange}
                readOnly={props.readonly}
                placeholder={props.placeholder}
            />
            {
                props.errorText ? <div className={styles.errorText}>{props.errorText}</div> : null
            }
        </div>
    );
}

export default React.memo(TextEditorMultiline);
