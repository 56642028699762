import React from "react";
import styles from './stubErrorHotelStyle.scss';
import mapFullPage from "../../../lottieAnimation/mapFullPage.json";
import {Player} from "@lottiefiles/react-lottie-player";

interface StubErrorDataProps {
    errorText: string;
}

function StubErrorHotel(props: StubErrorDataProps) {
    return (
        <div className={styles.componentContainer}>
            <div>
                <Player
                    autoplay
                    src={mapFullPage}
                    loop
                    style={{
                        height: '300px',
                        width: '100%',
                        position: 'relative',
                        zIndex: '10'
                    }}
                />
                <div className={styles.textContainer}>{props.errorText}</div>
            </div>
        </div>
    );
}

export default React.memo(StubErrorHotel);
