import {BaseStoreUserPage, InitDataBaseStoreUserPage} from "../base/baseStoreUserPage";
import {StoreUserPageContentRooms} from "./storeUserPageContentRooms";

/**
 * Страница "Номера отеля"
 */
export class StoreUserPageRooms extends BaseStoreUserPage<StoreUserPageContentRooms> {

    constructor(initData: InitDataBaseStoreUserPage) {
        super(initData);
    }

    public eventPageShown() {
        super.eventPageShown();
        const storeContentPage = new StoreUserPageContentRooms({
            storeRouter: this._storeRouter,
            storeSitePageTitle: this._storeSitePageTitle,
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup,
            linkBack: this._linkBack
        });

        // Получение параметров для фильтра
        storeContentPage.storeCommonFilter.serverRequestGetFilterOptions();
        // Получение комнат
        storeContentPage.serverRequestGetAllStoreData();
        // Получение рекомендованных комнат
        storeContentPage.storeUserRoomsRecommended.serverRequestGetRecommendedRooms();

        this._setStoreContentPage(storeContentPage);
    }
}
