import {StoreEditDataInPopup} from "../../common/storeEditDataInPopup";
import {Feedback} from "../../../interfaces/api/feedback";
import {StoreEditorFeedback} from "./storeEditorFeedback";
import {getRequestErrorText} from "../../../api/helpers/getRequestErrorText";
import {StoreFullScreenPopup} from "../../common/storeFullScreenPopup";
import {action, computed, makeObservable, observable} from "mobx";
import {StoreApiUser} from "../../../api/storeApiUser";

interface InitDataStoreFeedback {
    storeFullScreenPopup: StoreFullScreenPopup;
    storeApi: StoreApiUser;
}

export class StoreFeedback {
    private readonly _storeFullScreenPopup: StoreFullScreenPopup;
    private readonly _storeApi: StoreApiUser;

    private _storeEditDataInPopup_observable?: StoreEditDataInPopup<Feedback, StoreEditorFeedback>

    protected _setStoreEditDataInPopup(inputStore: StoreEditDataInPopup<Feedback, StoreEditorFeedback> | undefined) {
        this._storeEditDataInPopup_observable = inputStore;
    }

    get storeEditDataInPopup() {
        return this._storeEditDataInPopup_observable;
    }

    /**
     * Скрыть/показать окно редактирования
     * @param isShow
     * @protected
     */
    protected _showHidePopupEditData(isShow: boolean){
        if (!this._storeEditDataInPopup_observable) {
            return;
        }

        this._storeEditDataInPopup_observable.setIsShowPopup(isShow);
    }

    public eventSendFeedback() {
        this._internalEventStartEditItem();
    }

    private _internalEventStartEditItem() {
        const storeEditor = new StoreEditorFeedback({
            eventClosePopup: this._eventCloseEditPopup,
            callbackSaveEditData: this._internalEventSaveEditableElement,
        });

        const storeEditItem = new StoreEditDataInPopup<Feedback, StoreEditorFeedback>({
            storeEditor: storeEditor
        });

        this._setStoreEditDataInPopup(storeEditItem);
    }

    /**
     * Закрыть окно редактирования
     * @protected
     */
    protected _eventCloseEditPopup(){
        this._setStoreEditDataInPopup(undefined);
    }

    private _internalEventSaveEditableElement(item: Feedback) {
        // Скрываем окно
        this._showHidePopupEditData(false);
        // Запрос к серверу
        this._serverRequestSendFeedback(item);
    }

    private _serverRequestSendFeedback(feedBack: Feedback) {
        this._storeFullScreenPopup.loading = {
            loadingText: 'Отправка данных'
        };

        this._storeApi.apiFeedback.feedbackSendFeedbackPost(
            feedBack.name,
            feedBack.email,
            feedBack.subject,
            feedBack.message
        )
            .then(() => {
                this._storeFullScreenPopup.success = {
                    eventClose: this._storeFullScreenPopup.closeAllWindows,
                    successText: 'Сообщение успешно отправлено',
                    textBtnClose: 'Закрыть'
                };
            })
            .catch((error) => {
                //Показываем окно ошибки
                this._storeFullScreenPopup.error = {
                    errorText: getRequestErrorText(error),
                    eventClose: () => {
                        // Закрываем окно загрузки
                        this._storeFullScreenPopup.closeAllWindows();
                    }
                };
            });
    }

    constructor(initData: InitDataStoreFeedback) {
        this._storeApi = initData.storeApi;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;
        this._storeEditDataInPopup_observable = undefined;

        this.eventSendFeedback = this.eventSendFeedback.bind(this);
        this._eventCloseEditPopup = this._eventCloseEditPopup.bind(this);
        this._internalEventStartEditItem = this._internalEventStartEditItem.bind(this);
        this._internalEventSaveEditableElement = this._internalEventSaveEditableElement.bind(this);

        makeObservable<this,
            '_storeEditDataInPopup_observable'
            | '_setStoreEditDataInPopup'>(this, {
            _storeEditDataInPopup_observable: observable,
            _setStoreEditDataInPopup: action,
            storeEditDataInPopup: computed,
        });
    }
}
