import {RoomVMExtended} from "../api/src";
import {v4 as uuidv4} from "uuid";
import {RoomItemData} from "../interfaces/api/roomItemData";

export function convertServerRoomModelToClientModel(item: RoomVMExtended, isRecommendedItem?: boolean): RoomItemData | undefined {
    const {
        id,
        hotelID,
        categoryID,
        title,
        description,
        number,
        capacity,
        space,
        price,
        withKids,
        isActive,
        isRecommended,
        shortDescription,
        childAge,
        discount
    } = item;

    if (typeof id !== 'number') {
        return undefined;
    }

    if (!isActive) {
        return undefined;
    }

    if (isRecommendedItem && !isRecommended) {
        return undefined;
    }

    return {
        internalId: uuidv4(),
        id: id,
        hotelId: hotelID ?? -1,
        categoryId: categoryID ?? -1,
        title: title ?? 'Неизвестно',
        description: description ?? 'Неизвестно',
        number: number ?? -1,
        capacity: capacity ?? 0,
        space: space ?? 0,
        price: price ?? 0,
        withKids: withKids ?? false,
        isActive: isActive,
        isRecommended: isRecommended ?? false,
        shortDescription: shortDescription ?? '',
        childAge: childAge ?? undefined,
        discount: discount ?? undefined
    }
}

export function convertServerRoomsListModelToClientModel(roomsList: RoomVMExtended[], isRecommended?: boolean): RoomItemData[] {
    const resultRoomsList: RoomItemData[] = [];

    if (!roomsList) {
        return resultRoomsList;
    }

    if (!roomsList.length) {
        return resultRoomsList;
    }

    for (let i = 0; i < roomsList.length; ++i) {
        const roomTmp: RoomItemData | undefined = convertServerRoomModelToClientModel(roomsList[i], isRecommended);
        if (roomTmp) {
            resultRoomsList.push(roomTmp);
        }
    }

    return resultRoomsList;
}
