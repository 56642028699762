import React from "react";
import DOMContentLoaded from "../../globalEvents/DOMContentLoaded";
import RealPageEnterPointUser from "./realPageEnterPointUser";
import {StoreMainUser} from "../../stores/storeMainUser";

const storeMainUser = new StoreMainUser();

DOMContentLoaded({
    pageComponent: <RealPageEnterPointUser storePage={storeMainUser}/>,
});
