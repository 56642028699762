import {StoreRouter} from "../storeRouter";
import {StoreSitePageTitle} from "../storeSitePageTitle";
import {StoreFullScreenPopup} from "../storeFullScreenPopup";
import {StoreHotelSpecificationInfo} from "../storeHotelSpecificationInfo";
import {StoreUserHotelInfo} from "../../user/hotelInfo/storeUserHotelInfo";

export interface InitDataSuperStorePage<RouterPagesKeys extends string> {
    // Заголовок вкладки браузера
    readonly documentTitle: string;
    // Заголовок страницы
    readonly pageTitle: string;
    //Роутер страниц админа
    readonly storeRouter: StoreRouter<RouterPagesKeys>;
    // Заголовок страницы
    readonly storeSitePageTitle: StoreSitePageTitle;
    // Текст, который будет добавлен после заголовки страницы в окне браузера
    readonly addDocumentTitleText?: string;
    // Ссылка вернуться назад
    readonly linkBack?: string;
    // Всплывающие окна
    readonly storeFullScreenPopup: StoreFullScreenPopup;
    // Спецификация отеля
    readonly storeHotelSpecificationInfo: StoreHotelSpecificationInfo;
}

/**
 * Супер класс страницы
 */
export class SuperStorePage<RouterPagesKeys extends string> {
    protected readonly _documentTitle: string;
    protected readonly _pageTitle: string;
    protected readonly _storeRouter: StoreRouter<RouterPagesKeys>;
    protected readonly _storeSitePageTitle: StoreSitePageTitle;
    protected readonly _linkBack?: string;
    protected readonly _storeFullScreenPopup: StoreFullScreenPopup;
    protected readonly _storeHotelSpecificationInfo: StoreHotelSpecificationInfo;

    constructor(initData: InitDataSuperStorePage<RouterPagesKeys>) {
        this._documentTitle = initData.addDocumentTitleText ? `${initData.documentTitle} - ${initData.addDocumentTitleText}` : initData.documentTitle;
        this._pageTitle = initData.pageTitle;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;
        this._storeHotelSpecificationInfo = initData.storeHotelSpecificationInfo;
        this._storeRouter = initData.storeRouter;
        this._storeSitePageTitle = initData.storeSitePageTitle;
        this._linkBack = initData.linkBack;
    }

    /**
     * Роутер
     */
    get storeRouter() {
        return this._storeRouter;
    }

    /**
     * Событие ухода со страницы
     */
    public eventPageExit() {
    }

    protected _setPageAndDocumentTitleOverride() {
        // Меняем заголовок страницы
        this._storeSitePageTitle.setOptions({
            pageTitle: this._pageTitle,
            linkBack: this._linkBack,
            eventReturnBack: undefined
        });

        // Меняем заголовок окна браузера
        document.title = this._documentTitle;
    }

    /**
     * Событие, текущая страница показана
     */
    public eventPageShown() {
        this._setPageAndDocumentTitleOverride();
        // Тут можно вызывать другие события страницы, для этого нужно переопределить метод
    }
}
