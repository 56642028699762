import {ValidationValueResult} from "../../globalType/ValidationResult";

interface ValidationStringStandardOptions {
    stringToCheck: string | null | undefined;
    minLength?: number;
    maxLength?: number;
}

const TEXT_ERROR_STRING_IS_EMPTY = 'Поле не может быть пустым';

/**
 * Стандартная проверка строк
 * @param inputOptions
 */
export default function validationStringStandard(inputOptions: ValidationStringStandardOptions): ValidationValueResult<string> {
    const minLength: number = inputOptions.minLength ?? 3;
    const maxLength: number = inputOptions.maxLength ?? 255;

    if ( typeof inputOptions.stringToCheck !== 'string') {
        return {
            errorText: TEXT_ERROR_STRING_IS_EMPTY,
            validValue: ''
        }
    }

    let stringToCheck: string = inputOptions.stringToCheck.trim();

    if (!stringToCheck) {
        return {
            errorText: TEXT_ERROR_STRING_IS_EMPTY,
            validValue: ''
        }
    }

    if (stringToCheck.length < minLength) {
        return {
            errorText: `Поле не может быть короче ${minLength} символов`,
            validValue: stringToCheck
        };
    }

    if (stringToCheck.length > maxLength) {
        return {
            errorText: `Поле не может быть длиннее ${maxLength} символов`,
            validValue: stringToCheck
        };
    }

    return {
        errorText: undefined,
        validValue: stringToCheck
    }
}
