import React from "react";
import {observer} from "mobx-react";
import {SmartComponentProps} from "../../../interfaces/smartComponentProps";
import {StoreFullScreenPopup} from "../../../stores/common/storeFullScreenPopup";
import {
    PopupFullScreenConfirm,
    PopupFullScreenConfirmExit,
    PopupFullScreenError,
    PopupFullScreenLoading,
    PopupFullScreenStepSuccess,
    PopupFullScreenSuccess
} from "./index";

function StandardsPopupsFullscreen(props: SmartComponentProps<StoreFullScreenPopup>) {
    return (
        <>
            {props.store.confirm ?
                <PopupFullScreenConfirm {...props.store.confirm}/> : null}
            {props.store.confirmExit ?
                <PopupFullScreenConfirmExit {...props.store.confirmExit}/> : null}
            {props.store.loading ?
                <PopupFullScreenLoading {...props.store.loading}/> : null}
            {props.store.error ?
                <PopupFullScreenError {...props.store.error}/> : null}
            {props.store.success ?
                <PopupFullScreenSuccess {...props.store.success}/> : null}
            {props.store.stepSuccess ?
                <PopupFullScreenStepSuccess {...props.store.stepSuccess}/> : null}
        </>
    );
}

export default observer(StandardsPopupsFullscreen);
