import React from "react";
import styles from './homeIconStyle.scss';
import HomeIco from "../../../../img/svg_component/home.svg";

function HomeIcon() {
    return (
        <div className={styles.icoContainer}>
            <HomeIco/>
        </div>
    );
}

export default HomeIcon;
