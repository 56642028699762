import {action, computed, makeObservable, observable} from "mobx";
import {DataWithInternalId} from "../../interfaces/dataWithInternalId";
import {DropdownSelectCurrentSelectedItem, DropdownSelectItem} from "../../interfaces/dropdownSelectItem";

interface SearchingElementsForCurrentPageOptions<DataList extends DataWithInternalId> {
    dataList: DataList[];
    currentPage: number;
    elementsOnPage: number;
}

interface CountMaxPagesProps {
    totalElements: number;
    elementsOnPage: number;
}

interface StoreDataPaginationInitData {
    listElementsOnPage?: DropdownSelectItem[];
}

interface RefreshViewDataAfterSetDataOptions {
    //Сбросить текущее количество элементов на странице
    isResetSelectedElementsOnPage: boolean;
    // Сбросить текущую выбранную страницу
    isResetSelectedCurrentPage: boolean;
}

interface SetDataListForPaginationOptions<DataList extends DataWithInternalId> extends RefreshViewDataAfterSetDataOptions {
    dataListForPagination: DataList[];
}

/**
 * Хранилище, показывает данные по страницам
 */
export class StoreDataPagination<DataItem extends DataWithInternalId> {
    public numberElementsOnPageDefault: number;

    //region Общий набор данных
    private _dataListForPagination: DataItem[];

    /**
     * Установить данные
     * Вызывает полную перерисовку
     * @param options
     * @public
     */
    public setDataListForPagination(options: SetDataListForPaginationOptions<DataItem>) {
        if (!options.dataListForPagination.length) {
            //Данные пустые, сбрасываем все
            this.resetStoreData();
            return;
        }

        this._dataListForPagination = options.dataListForPagination.slice();

        this._refreshViewDataFull({
            isResetSelectedElementsOnPage: options.isResetSelectedElementsOnPage,
            isResetSelectedCurrentPage: options.isResetSelectedCurrentPage
        });
    }

    public setAllDataList(options: DataItem[], test?: boolean) {
        this._dataOnCurrentPage_observable = options;
    }

    public setCurrentDataItem(options: DataItem) {
        if (!this._dataOnCurrentPage_observable) {
            return;
        }

        const index = this._dataOnCurrentPage_observable.findIndex(x => x.internalId === options.internalId);
        if (index > -1) {
            this._dataOnCurrentPage_observable[index] = options;
        }
    }

    //endregion

    //region Отображаемые данные на текущей странице
    private _dataOnCurrentPage_observable?: DataItem[];

    /**
     * Получить данные на текущей странице
     */
    get dataOnCurrentPage() {
        return this._dataOnCurrentPage_observable;
    }

    //endregion

    //region Текущая страница
    private _selectedCurrentPage_observable: DropdownSelectItem;

    /**
     *  Текущая выбранная страница
     */
    get selectedCurrentPage() {
        return this._selectedCurrentPage_observable;
    }

    //endregion

    //region Список страниц пагинации
    private _listPages_observable: DropdownSelectItem[];

    /**
     * Получить список доступных страниц
     */
    get listPages() {
        return this._listPages_observable;
    }

    //endregion

    //region Список количества элементов на странице
    private readonly _listElementsOnPage: DropdownSelectItem[];

    /**
     * Получить список возможного отображения элементов на странице
     */
    get listElementsOnPage() {
        return this._listElementsOnPage;
    }

    //endregion

    //region Элементов на странице
    private _selectedElementsOnPage_observable: DropdownSelectItem;
    private _passedElementsOnPage_observable: number;

    /**
     * Пройденное количество элементов
     */
    get passedElementsOnPage() {
        return this._passedElementsOnPage_observable;
    }
    /**
     * Текущее количество элементов на странице
     */
    get selectedElementsOnPage() {
        return this._selectedElementsOnPage_observable;
    }

    //endregion

    //region Всего элементов
    private _totalItemsCount_observable: number;

    /**
     * Общее число отображаемых элементов
     */
    get totalItemsCount() {
        return this._totalItemsCount_observable;
    }

    //endregion

    constructor(initData?: StoreDataPaginationInitData) {
        this._dataListForPagination = [];
        this.numberElementsOnPageDefault = 12;
        let elementsOnPage: DropdownSelectItem[] = this._createListElementsOnPage();

        if(initData?.listElementsOnPage) {
            elementsOnPage = initData.listElementsOnPage;
        }

        this._selectedCurrentPage_observable = {
            value: 0,
            label: '0'
        };

        this._totalItemsCount_observable = 0;
        this._listPages_observable = [];
        this._selectedElementsOnPage_observable = elementsOnPage[0];
        this._passedElementsOnPage_observable = 0;
        this._listElementsOnPage = elementsOnPage;
        this._dataOnCurrentPage_observable = undefined;

        this.eventChangeCurrentPage = this.eventChangeCurrentPage.bind(this);
        this.eventChangeElementsOnPage = this.eventChangeElementsOnPage.bind(this);
        this.eventClickPrevBtn = this.eventClickPrevBtn.bind(this);
        this.eventClickNextBtn = this.eventClickNextBtn.bind(this);
        this.eventShowMoreItemsBtn = this.eventShowMoreItemsBtn.bind(this);

        makeObservable<this,
            '_dataOnCurrentPage_observable'
            | '_selectedCurrentPage_observable'
            | '_selectedElementsOnPage_observable'
            | '_passedElementsOnPage_observable'
            | '_listPages_observable'
            | '_totalItemsCount_observable'
            | '_refreshViewDataAfterChangePage'
            | '_expandViewDataAfterChangePage'
            | '_refreshViewDataFull'
            | '_changeCurrentPage'
            | '_expandCurrentPage'>(this, {
            _dataOnCurrentPage_observable: observable,
            _selectedCurrentPage_observable: observable,
            _selectedElementsOnPage_observable: observable,
            _passedElementsOnPage_observable: observable,
            _listPages_observable: observable,
            _totalItemsCount_observable: observable,
            _refreshViewDataAfterChangePage: action,
            _expandViewDataAfterChangePage: action,
            _refreshViewDataFull: action,
            _changeCurrentPage: action,
            _expandCurrentPage: action,
            setDataListForPagination: action,
            setAllDataList: action,
            eventChangeElementsOnPage: action,
            resetStoreData: action,
            setCurrentDataItem: action,
            dataOnCurrentPage: computed,
            totalItemsCount: computed,
            passedElementsOnPage: computed,
            selectedElementsOnPage: computed,
            listPages: computed,
            selectedCurrentPage: computed,
        });
    }

    //region События
    /**
     * Событие изменить количество элементов на странице
     * @param elementsOnPage
     */
    public eventChangeElementsOnPage(elementsOnPage: DropdownSelectCurrentSelectedItem) {
        if (!elementsOnPage) {
            return;
        }

        this._selectedElementsOnPage_observable = elementsOnPage;

        this._refreshViewDataFull({
            isResetSelectedElementsOnPage: false,
            isResetSelectedCurrentPage: true
        });
    }

    private _searchingMoreElementsForCurrentPage(options: SearchingElementsForCurrentPageOptions<DataItem>): DataItem[] {
        const currentPageNumber: number = options.currentPage < 0 ? 1 : options.currentPage;
        const elementsOnPage: number = options.elementsOnPage;
        const dataList: DataItem[] = options.dataList;
        const totalElements: number = dataList.length;
        const startIndex = (currentPageNumber - 1) * elementsOnPage;
        // Желаемое количество элементов на странице
        const desiredCountElementsOnPage = startIndex + elementsOnPage;
        const lastIndex = (desiredCountElementsOnPage > totalElements) ? totalElements : desiredCountElementsOnPage;
        const resultElementsOnPage: DataItem[] = [];
        for (let i = this._passedElementsOnPage_observable; i < lastIndex; ++i) {
            resultElementsOnPage.push(dataList[i]);
        }

        return resultElementsOnPage;
    }

    /**
     * Показать больше элементов
     * @public
     */
    public eventShowMoreItemsBtn() {
        const currentPage: number = this._selectedCurrentPage_observable.value;
        const nextPageNumber = currentPage + 1;
        this._expandCurrentPage(nextPageNumber);
    }

    /**
     * @param pageNumber
     * @private
     */
    private _expandCurrentPage(pageNumber: number) {
        if (!this._checkPageNumber(pageNumber)) {
            return;
        }

        // Номера страниц идут с 1, поэтому отнимаем 1
        const newPage: DropdownSelectItem | undefined = this._listPages_observable[pageNumber - 1];

        if (!newPage) {
            return;
        }

        this._selectedCurrentPage_observable = newPage;
        this._expandViewDataAfterChangePage();
    }

    /**
     * Обновить данные после расширения списка
     * @private
     */
    private _expandViewDataAfterChangePage() {
        this._dataOnCurrentPage_observable = this._searchingMoreElementsForCurrentPage({
            dataList: this._dataListForPagination,
            currentPage: this._selectedCurrentPage_observable.value,
            elementsOnPage: this._selectedElementsOnPage_observable.value,
        });
    }

    /**
     * Переход на страницу
     * @param type
     * @param newCurrentPage
     * @private
     */
    private moveOrReturnToPage(type: 'next' | 'prev' | 'none', newCurrentPage?: DropdownSelectCurrentSelectedItem) {
        let pageNumberToGo: number = this._selectedCurrentPage_observable.value;

        if (type === 'none') {
            if (!newCurrentPage) {
                return;
            }

            this._changeCurrentPage(newCurrentPage.value);
            return;
        }

        if (type === 'next') {
            pageNumberToGo += 1;
        } else if (type === 'prev') {
            pageNumberToGo -= 1;
        }

        this._changeCurrentPage(pageNumberToGo);
    }

    /**
     * Показать следующую страницу
     * @public
     */
    public eventClickNextBtn() {
        this.moveOrReturnToPage('next');
    }

    /**
     * Показать предыдущую страницу
     * @public
     */
    public eventClickPrevBtn() {
        this.moveOrReturnToPage('prev');
    }

    /**
     * Изменить текущую страницу
     * @param newCurrentPage
     * @public
     */
    public eventChangeCurrentPage(newCurrentPage: DropdownSelectCurrentSelectedItem) {
        this.moveOrReturnToPage('none', newCurrentPage);
    }

    //endregion

    /**
     * Возвращает список пагинации
     * @param options
     * @private
     */
    private static _createPagination(options: CountMaxPagesProps): DropdownSelectItem[] {
        const maxPages: number = StoreDataPagination._countMaxPages(options);

        const resultPagesList: DropdownSelectItem[] = [];

        if (!maxPages) {
            return resultPagesList;
        }

        for (let i = 1; i <= maxPages; ++i) {
            resultPagesList.push({
                value: i,
                label: String(i)
            });
        }

        return resultPagesList;
    }

    /**
     * Возвращает список, количество элементов на странице
     * @private
     */
    private _createListElementsOnPage() {
        const result: DropdownSelectItem[] = [];
        for (let i = this.numberElementsOnPageDefault; i <= 99; i += this.numberElementsOnPageDefault) {
            result.push({
                label: String(i),
                value: i
            });
        }

        return result;
    }

    /**
     * Изменить текущую страницу
     * @param pageNumber
     * @private
     */
    private _changeCurrentPage(pageNumber: number) {
        if (!this._checkPageNumber(pageNumber)) {
            return;
        }

        // Номера страниц идут с 1, поэтому отнимаем 1
        const newPage: DropdownSelectItem | undefined = this._listPages_observable[pageNumber - 1];

        if (!newPage) {
            return;
        }

        this._selectedCurrentPage_observable = newPage;
        this._refreshViewDataAfterChangePage();
    }

    /**
     * Проверяет номер страницы
     * Если true страница прошла проверку
     * @param pageNumber
     * @private
     */
    private _checkPageNumber(pageNumber: number): boolean {
        //Номер страницы не может быть меньше 0 или 0
        if (pageNumber <= 0) {
            return false;
        }

        // Получаем максимальное число страниц
        const maxPagesNumber = StoreDataPagination._countMaxPages({
            totalElements: this._dataListForPagination.length,
            elementsOnPage: this._selectedElementsOnPage_observable.value
        });

        return pageNumber <= maxPagesNumber;
    }

    /**
     * Обновить данные после смены страницы
     * @private
     */
    private _refreshViewDataAfterChangePage() {
        this._dataOnCurrentPage_observable = this._searchingElementsForCurrentPage({
            dataList: this._dataListForPagination,
            currentPage: this._selectedCurrentPage_observable.value,
            elementsOnPage: this._selectedElementsOnPage_observable.value,
        });
    }

    /**
     * Обновить все данные после установки новых данных
     * @param options
     * @private
     */
    private _refreshViewDataFull(options: RefreshViewDataAfterSetDataOptions) {
        //Создаем пагинацию
        const pagesList = StoreDataPagination._createPagination({
            totalElements: this._dataListForPagination.length,
            elementsOnPage: this._selectedElementsOnPage_observable.value
        });

        //Такого быть не должно, но на всякий случай
        if(!pagesList.length) {
            // Сбрасываем все данные и выходим
            this.resetStoreData();
            console.error('pagesList.length === 0');
            return;
        }

        if (options.isResetSelectedElementsOnPage) {
            // Убираем выбранное количество элементов на странице
            this._selectedElementsOnPage_observable = this._listElementsOnPage[0];
        }

        if (options.isResetSelectedCurrentPage) {
            //Обновляем текущую страницу
            this._selectedCurrentPage_observable = pagesList[0];
        }

        //Обновляем список страниц
        this._listPages_observable = pagesList;

        // Обновляем общее число элементов
        this._totalItemsCount_observable = this._dataListForPagination.length;

        //Обновляем элементы на странице
        this._refreshViewDataAfterChangePage();
    }

    /**
     * Найти все элементы для текущей страницы
     * @param options
     * @private
     */
    private _searchingElementsForCurrentPage(options: SearchingElementsForCurrentPageOptions<DataItem>): DataItem[] {

        const currentPageNumber: number = options.currentPage < 0 ? 1 : options.currentPage;
        const elementsOnPage: number = options.elementsOnPage;
        const dataList: DataItem[] = options.dataList;
        const totalElements: number = dataList.length;
        const startIndex = (currentPageNumber - 1) * elementsOnPage;

        this._passedElementsOnPage_observable = startIndex;

        // Желаемое количество элементов на странице
        const desiredCountElementsOnPage = startIndex + elementsOnPage;
        const lastIndex = (desiredCountElementsOnPage > totalElements) ? totalElements : desiredCountElementsOnPage
        const resultElementsOnPage: DataItem[] = [];

        for (let i = startIndex; i < lastIndex; ++i) {
            resultElementsOnPage.push(dataList[i]);
        }

        return resultElementsOnPage;
    }

    /**
     * Посчитать максимальное количество страниц
     * @param options
     * @private
     */
    private static _countMaxPages(options: CountMaxPagesProps): number {
        const totalElements: number = options.totalElements;
        const elementsOnPage: number = options.elementsOnPage;
        const remainder = totalElements % elementsOnPage;
        const rawMaxPages: number = (totalElements - remainder) / elementsOnPage;
        return (remainder > 0) ? (rawMaxPages + 1) : rawMaxPages;
    }

    /**
     * Очищает все данные
     * @public
     */
    public resetStoreData() {
        const firstItemElementsOnPage: DropdownSelectItem | undefined = this._listElementsOnPage[0];

        if (firstItemElementsOnPage) {
            this._selectedElementsOnPage_observable = firstItemElementsOnPage;
        } else {
            this._selectedElementsOnPage_observable = {
                value: 0,
                label: '0'
            }
        }

        this._dataOnCurrentPage_observable = [];
        this._listPages_observable = [];
        this._totalItemsCount_observable = 0;
        this._dataListForPagination = [];
        this._selectedCurrentPage_observable = {
            value: 0,
            label: '0'
        };
    }

    /**
     * Отображение пагинации в зависимости от количества страниц
     * @param listPagesNumbers
     * @param currentValue
     * @public
     */
    public paginationHelper(listPagesNumbers: number[], currentValue: number) {
        if (currentValue === 1) {
            return [listPagesNumbers[currentValue], listPagesNumbers[currentValue + 1], listPagesNumbers[currentValue + 2]]
        }

        if (currentValue === 2) {
            return [listPagesNumbers[currentValue - 1], listPagesNumbers[currentValue], listPagesNumbers[currentValue + 1]]
        }

        if (currentValue === 3) {
            return [listPagesNumbers[currentValue - 2], listPagesNumbers[currentValue - 1], listPagesNumbers[currentValue]]
        }

        if (currentValue >= 4 && currentValue < 7) {
            return [listPagesNumbers[currentValue - 2], listPagesNumbers[currentValue - 1], listPagesNumbers[currentValue]]
        }

        if (currentValue === 7 && listPagesNumbers.length === 9) {
            return [listPagesNumbers[currentValue - 2], listPagesNumbers[currentValue - 1], listPagesNumbers[currentValue]]
        }

        if (currentValue === 7 && listPagesNumbers.length === 8) {
            return [listPagesNumbers[currentValue - 3], listPagesNumbers[currentValue - 2], listPagesNumbers[currentValue - 1]]
        }

        if (currentValue === 7) {
            return [listPagesNumbers[currentValue - 2], listPagesNumbers[currentValue - 1], listPagesNumbers[currentValue]]
        }

        if (currentValue === listPagesNumbers.length - 2) {
            return [listPagesNumbers[currentValue - 2], listPagesNumbers[currentValue - 1], listPagesNumbers[currentValue]]
        }

        // Предпоследний элемент
        if (currentValue === listPagesNumbers.length - 1) {
            return [listPagesNumbers[currentValue - 3], listPagesNumbers[currentValue - 2], listPagesNumbers[currentValue - 1]]
        }

        // Последний элемент
        if (currentValue === listPagesNumbers.length) {
            return [listPagesNumbers[currentValue - 4], listPagesNumbers[currentValue - 3], listPagesNumbers[currentValue - 2]]
        }

        return [listPagesNumbers[currentValue - 2], listPagesNumbers[currentValue - 1], listPagesNumbers[currentValue]];
    }
}
