import React from "react";
import styles from "./popupItemContainerStyle.scss";

const TEXT_OPTION_FIELD_TITLE: string = 'Необязательно';
const TEXT_REQUIRED_TITLE: string = 'Поле обязательно для заполнения';

interface PopupItemContainerProps {
    readonly children: React.ReactNode;
    readonly className?: string;
    readonly classNameTitle?: string;
    readonly title?: string;
    readonly rowDirection?: boolean;
    readonly option?: boolean;
    readonly isRequired?: boolean;
}

function PopupItemContainer(props: PopupItemContainerProps) {
    const cssClass = props.rowDirection ? `${styles.container} ${styles.containerRow} ` : styles.container;
    const titleCssClass = props.option ? `${styles.titleContainer} ${styles.titleContainerOption}` : styles.titleContainer;
    return (
        <div className={props.className ? `${props.className} ${cssClass}` : cssClass}>
            <div className={props.classNameTitle ? `${titleCssClass} ${props.classNameTitle}` : titleCssClass}>
                <div className={styles.title}>{props.title}</div>
                {
                    props.isRequired ?
                        <span
                            className={styles.requiredStar}
                            title={TEXT_REQUIRED_TITLE}
                        >
                        {'*'}
                    </span>
                        : null
                }
                {
                    props.option ?
                        <div className={styles.option}>{TEXT_OPTION_FIELD_TITLE}</div>
                        : null
                }
            </div>
            {props.children}
        </div>
    );
}

export default React.memo(PopupItemContainer);
