export function convertDateToStringUTC(date: Date | null, isWithoutTime: boolean = false): string | undefined {
    if (!date) {
        return undefined;
    }

    return new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        isWithoutTime ? 0 : date.getHours(),
        isWithoutTime ? 0 : date.getMinutes(),
        isWithoutTime ? 0 : date.getSeconds()
    )).toISOString();
}
