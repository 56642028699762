import React from "react";
import styles from '../templatePageAuthUserStyle.scss';
import {SubHeaderContainer} from "../../../common/subHeaderContainer";

interface TemplatePageUnAuthUserProps {
    readonly siteTopMenu?: React.ReactNode;
    readonly siteBottomMenu?: React.ReactNode;
    readonly children: React.ReactNode;
}

function TemplatePageUnAuthUser(props: TemplatePageUnAuthUserProps) {
    return (
        <>
            {props.siteTopMenu ? props.siteTopMenu : null}
            <SubHeaderContainer/>
            <div className={styles.unAuthContentPageContainer}>
                {props.children}
            </div>
            {props.siteBottomMenu ? props.siteBottomMenu : null}
        </>
    );
}

export default React.memo(TemplatePageUnAuthUser);
