import {BaseApiStore} from "./baseApiStore";
import {getRequestErrorText} from "./helpers/getRequestErrorText";

/**
 * Api для пользователя
 */
export class StoreApiUser extends BaseApiStore {
    constructor() {
        super();
        this._getApiConfiguration()
            .then()
            .catch(error => {
                this._setErrorAuthApi(getRequestErrorText(error));
            });
    }
}
