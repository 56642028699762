import React from "react";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import styles from '../standardButton/standardButtonStyle.scss';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {cssClassStandardButtonTypes} from "../standardButton/cssClassStandardButtonTypes";

interface ButtonProps {
    readonly text: string;
    readonly href: string;
    readonly className?: string;
    readonly type?: (keyof typeof cssClassStandardButtonTypes);
    readonly eventClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    readonly iconLeft?: IconDefinition;
    readonly iconRight?: IconDefinition;
    readonly useHref?: boolean;
}


/**
 * Ссылка роутера, которая выглядит как кнопка
 * @param props
 * @constructor
 */
function LinkAsButton(props: ButtonProps) {

    let cssClass: string = styles.buttonOk;
    let componentIconLeft: JSX.Element | null = null;
    let componentIconRight: JSX.Element | null = null;
    let eventClick: ((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined = props.eventClick;

    if (props.type) {
        if (cssClassStandardButtonTypes.hasOwnProperty(props.type)) {
            cssClass = cssClassStandardButtonTypes[props.type];
        }

        if (props.type === 'disabled') {
            eventClick = undefined;
        }
    }

    if (props.className) {
        cssClass += ` ${styles.button} ${props.className}`;
    } else {
        cssClass += ` ${styles.button} ${styles.buttonFontSize}`;
    }

    if (props.iconLeft) {
        componentIconLeft = <FontAwesomeIcon className={styles.iconLeft} icon={props.iconLeft}/>;
    }

    if (props.iconRight) {
        componentIconRight = <FontAwesomeIcon className={styles.iconRight} icon={props.iconRight}/>;
    }

    if (props.type === 'disabled') {
        return (
            <span className={cssClass}>
                {componentIconLeft}
                <span>{props.text}</span>
                {componentIconRight}
            </span>
        );
    }

    return (
        <>
            {
                props.useHref ?
                    <a
                        href={props.href}
                        className={cssClass}
                    >
                        {componentIconLeft}
                        {props.text}
                        {componentIconRight}
                    </a>
                    :
                    <Link
                        to={props.href}
                        className={cssClass}
                        onClick={eventClick}
                    >
                        {componentIconLeft}
                        {props.text}
                        {componentIconRight}
                    </Link>
            }
        </>
    );
}

export default React.memo(LinkAsButton);
