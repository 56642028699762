import React from "react";
import styles from './hotelRoomsIconStyle.scss';
import RoomsIco from "../../../../img/svg_component/rooms.svg";

function HotelRoomsIcon() {
    return (
        <div className={styles.icoContainer}>
            <RoomsIco/>
        </div>
    );
}

export default HotelRoomsIcon;
