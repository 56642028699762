import {StoreUserPageContentHome} from "./storeUserPageContentHome";
import {BaseStoreUserPage, InitDataBaseStoreUserPage} from "../base/baseStoreUserPage";
import {StoreUserHotelInfo} from "../hotelInfo/storeUserHotelInfo";

interface InitDataStoreUserPageHome extends InitDataBaseStoreUserPage {
    storeUserHotelInfo: StoreUserHotelInfo;
}

export class StoreUserPageHome extends BaseStoreUserPage<StoreUserPageContentHome> {
    private _storeUserHotelInfo: StoreUserHotelInfo;

    constructor(initData: InitDataStoreUserPageHome) {
        super(initData);
        this._storeUserHotelInfo = initData.storeUserHotelInfo;
    }

    public eventPageShown() {
        super.eventPageShown();

        const storeContentPage = new StoreUserPageContentHome({
            storeRouter: this._storeRouter,
            storeSitePageTitle: this._storeSitePageTitle,
            storeApi: this._storeApi,
            storeFullScreenPopup: this._storeFullScreenPopup,
            linkBack: this._linkBack,
            storeUserHotelInfo: this._storeUserHotelInfo
        });

        // Получение параметров для фильтра
        storeContentPage.storeCommonFilter.serverRequestGetFilterOptions();
        // Получение категорий отеля
        storeContentPage.serverRequestGetAllStoreData();

        this._setStoreContentPage(storeContentPage);
    }
}
