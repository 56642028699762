import React from "react";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import styles from "../popupFullScreenConfirmStyle.scss";
import success from "../../../../../lottieAnimation/deleteRow.json";
import {Player} from "@lottiefiles/react-lottie-player";
import PopupFullScreenWithEvents, {PopupFullScreenBasicProps} from "../../../popupFullscreen/popupFullScreenWithEvents";
import {ButtonsContainer} from "../../../buttonsContainer";
import {StandardButton} from "../../../standardButton";

const TEXT_BUTTON_YES: string = 'Да';
const TEXT_BUTTON_NO: string = 'Нет';

export interface PopupFullScreenConfirmProps extends PopupFullScreenBasicProps {
    eventConfirm: () => void;
    confirmText: string;
    textButtons?: {
        yesButton: string;
        noButton: string;
    }
}

const PLAYER_LOTTIE_STYLE = {
    height: '200px',
    width: '200px'
};

function PopupFullScreenConfirm(props: PopupFullScreenConfirmProps) {
    return (
        <PopupFullScreenWithEvents
            eventClose={props.eventClose}
        >
            <Player
                autoplay
                loop={true}
                src={success}
                keepLastFrame={false}
                style={PLAYER_LOTTIE_STYLE}
            />
            <div className={styles.messageContainer}>
                {props.confirmText}
            </div>
            <ButtonsContainer className={styles.buttonActions}>
                <StandardButton
                    iconLeft={faCheck}
                    type={'ok'}
                    text={props.textButtons ? props.textButtons.yesButton : TEXT_BUTTON_YES}
                    eventClick={props.eventConfirm}
                    className={styles.popupButtonOk}
                />

                <StandardButton
                    iconLeft={faTimes}
                    type={'cancel'}
                    text={props.textButtons ? props.textButtons.noButton : TEXT_BUTTON_NO}
                    eventClick={props.eventClose}
                    className={styles.popupButtonClose}
                />
            </ButtonsContainer>
        </PopupFullScreenWithEvents>
    );
}

export default React.memo(PopupFullScreenConfirm);
