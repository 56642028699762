import {StoreSiteTopMenu} from "./common/storeSiteTopMenu";
import {ROUTER_PAGES_USER, RouterPagesKeysUser} from "../staticData/routerPages/routerPagesUser";
import {BaseStoreMain} from "./baseStoreMain";
import {StoreApiUser} from "../api/storeApiUser";
import {StoreSiteBottomMenu} from "./common/storeSiteBottomMenu";
import {StoreUserPageRooms} from "./user/rooms/storeUserPageRooms";
import {StoreUserPageDetailRoom} from "./user/detailRoom/storeUserPageDetailRoom";
import {StoreUserPageHome} from "./user/home/storeUserPageHome";
import {StoreHotelSpecificationInfo} from "./common/storeHotelSpecificationInfo";
import {StoreUserPageBooking} from "./user/booking/storeUserPageBooking";
import {StoreUserHotelInfo} from "./user/hotelInfo/storeUserHotelInfo";

const TEXT_USER_DOCUMENT_TITLE_ADD_TEXT: string = 'Панель пользователя';

export class StoreMainUser extends BaseStoreMain {
    public readonly storeSiteTopMenu: StoreSiteTopMenu<RouterPagesKeysUser>;
    public readonly storeSiteBottomMenu: StoreSiteBottomMenu;
    public readonly storeHotelSpecificationInfo: StoreHotelSpecificationInfo;
    public readonly storeUserHotelInfo: StoreUserHotelInfo;
    public readonly storeUserPageHome: StoreUserPageHome;
    public readonly storeUserPageRooms: StoreUserPageRooms;
    public readonly storeUserPageDetailRoom: StoreUserPageDetailRoom;
    public readonly storeUserPageBooking: StoreUserPageBooking;

    constructor() {
        const storeApi: StoreApiUser = new StoreApiUser();

        super({
            storeApi: storeApi,
            sitePagesDictionary: ROUTER_PAGES_USER
        });

        this.storeHotelSpecificationInfo = this._storeApi.storeHotelSpecificationInfo;
        this.storeUserHotelInfo = this._storeApi.storeUserHotelInfo;

        this.storeSiteTopMenu = new StoreSiteTopMenu<RouterPagesKeysUser>({
            storeApi: this._storeApi,
            storeRouter: this.storeRouter,
            linkToHomePage: this.storeRouter.getPagePath('userHome')
        });

        this.storeSiteBottomMenu = new StoreSiteBottomMenu({
            storeFullScreenPopup: this.storeFullScreenPopup,
            storeApi: this._storeApi,
            storeUserHotelInfo: this.storeUserHotelInfo
        });

        this.storeUserPageHome = new StoreUserPageHome({
            pageTitle: 'Бронирование номеров',
            documentTitle: 'Бронирование номеров',
            storeSitePageTitle: this.storeSitePageTitle,
            storeRouter: this.storeRouter,
            storeApi: this._storeApi,
            storeFullScreenPopup: this.storeFullScreenPopup,
            addDocumentTitleText: TEXT_USER_DOCUMENT_TITLE_ADD_TEXT,
            storeHotelSpecificationInfo: this.storeHotelSpecificationInfo,
            storeUserHotelInfo: this.storeUserHotelInfo
        });

        this.storeUserPageRooms = new StoreUserPageRooms({
            pageTitle: 'Номера',
            documentTitle: 'Номера',
            storeSitePageTitle: this.storeSitePageTitle,
            storeRouter: this.storeRouter,
            storeApi: this._storeApi,
            storeFullScreenPopup: this.storeFullScreenPopup,
            addDocumentTitleText: TEXT_USER_DOCUMENT_TITLE_ADD_TEXT,
            linkBack: this.storeRouter.getPagePath('userHome'),
            storeHotelSpecificationInfo: this.storeHotelSpecificationInfo
        });

        this.storeUserPageDetailRoom = new StoreUserPageDetailRoom({
            pageTitle: 'Номер',
            documentTitle: 'Номер',
            storeSitePageTitle: this.storeSitePageTitle,
            storeRouter: this.storeRouter,
            storeApi: this._storeApi,
            storeFullScreenPopup: this.storeFullScreenPopup,
            addDocumentTitleText: TEXT_USER_DOCUMENT_TITLE_ADD_TEXT,
            linkBack: this.storeRouter.getPagePath('rooms'),
            storeHotelSpecificationInfo: this.storeHotelSpecificationInfo
        });

        this.storeUserPageBooking = new StoreUserPageBooking({
            pageTitle: 'Бронирование номера',
            documentTitle: 'Бронирование номера',
            storeSitePageTitle: this.storeSitePageTitle,
            storeRouter: this.storeRouter,
            storeApi: this._storeApi,
            storeFullScreenPopup: this.storeFullScreenPopup,
            addDocumentTitleText: TEXT_USER_DOCUMENT_TITLE_ADD_TEXT,
            linkBack: this.storeRouter.getPagePath('rooms'),
            storeHotelSpecificationInfo: this.storeHotelSpecificationInfo
        });
    }
}
