import {action, computed, makeObservable, observable} from "mobx";
import {InitDataSuperStorePage, SuperStorePage} from "../../common/base/superStorePage";
import {RouterPagesKeysUser} from "../../../staticData/routerPages/routerPagesUser";
import {StoreApiUser} from "../../../api/storeApiUser";

export interface InitDataBaseStoreUserPage extends InitDataSuperStorePage<RouterPagesKeysUser> {
    // API пользователя
    readonly storeApi: StoreApiUser;
}

/**
 * Базовый класс Store страницы пользователя
 */
export class BaseStoreUserPage<StoreContentPage> extends SuperStorePage<RouterPagesKeysUser> {
    protected readonly _storeApi: StoreApiUser;

    //region Store контента страницы
    private _storeContentPage_observable?: StoreContentPage;

    protected _setStoreContentPage(store?: StoreContentPage) {
        this._storeContentPage_observable = store;
    }

    get storeContentPage() {
        return this._storeContentPage_observable;
    }
    //endregion

    constructor(initData: InitDataBaseStoreUserPage) {
        super(initData);
        this._storeApi = initData.storeApi;
        this._storeContentPage_observable = undefined;

        makeObservable<this,
            '_storeContentPage_observable'
            | '_setStoreContentPage'>(this, {
            _storeContentPage_observable: observable.ref,
            _setStoreContentPage: action,
            storeContentPage: computed
        });
    }

    public eventPageExit() {
        // Удаляем хранилище с контентом
        this._setStoreContentPage(undefined);
        // Закрываем все всплывающие окна
        this._storeFullScreenPopup.closeAllWindows();
    }
}
