import React from "react";
import styles from './pageLoadingStyle.scss';

interface PageLoadingProps {
    text?: string;
}

function PageLoading(props: PageLoadingProps) {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <svg className={styles.spinner} viewBox={'0 0 50 50'}>
                    <circle
                        className={styles.path}
                        cx={'25'}
                        cy={'25'}
                        r={'20'}
                        fill={'none'}
                        strokeWidth={4}
                    />
                </svg>
                {
                    props.text ? <div className={styles.text}>{props.text}</div> : null
                }
            </div>
        </div>
    );
}

export default React.memo(PageLoading);
