import {action, computed, makeObservable, observable} from "mobx";
import {v4 as uuidv4} from "uuid";
import {HotelInfoVM} from "../../../api/src";
import {HotelInfo, HotelPhotoItem} from "../../../interfaces/api/hotelInfo";

export class StoreUserHotelInfo {
    private _hotelInfo_observable?: Readonly<HotelInfo> | null;

    constructor() {
        this._hotelInfo_observable = undefined;

        makeObservable<this,
            '_hotelInfo_observable'
            | 'setHotelInfo_action'>(this, {
            _hotelInfo_observable: observable,
            setHotelInfo_action: action,
            resetHotelInfo: action,
            hotelInfo: computed
        });
    }

    get hotelInfo() {
        if (this._hotelInfo_observable === undefined) {
            return undefined;
        }

        if (this._hotelInfo_observable === null) {
            return null;
        }

        return Object.assign({}, this._hotelInfo_observable);
    }

    public setHotelInfo_action(inputHotelInfo?: HotelInfo | null) {
        if (inputHotelInfo === undefined) {
            this._hotelInfo_observable = undefined;
            return;
        }

        if (inputHotelInfo === null) {
            this._hotelInfo_observable = null;
            return;
        }

        this._hotelInfo_observable = Object.freeze(Object.assign({}, inputHotelInfo));
    }

    /**
     * Сбросить информацию об информации отеля
     */
    public resetHotelInfo() {
        this._hotelInfo_observable = null;
    }

    /**
     * Валидация данных отеля
     * @param inputHotelInfo
     * @private
     */
    public validationHotelInfo(inputHotelInfo: HotelInfoVM): HotelInfo | undefined {
        const {
            title,
            description,
            photos
        } = inputHotelInfo;

        const validPhotos: HotelPhotoItem[] = [];

        if (photos) {
            for (let i = 0; i < photos.length; ++i) {
                const photoId: number | undefined =  photos[i].id;

                if (typeof photoId !== 'number') {
                    continue;
                }

                validPhotos.push({
                    internalId: uuidv4(),
                    id: photoId,
                    photo: photos[i].photo ?? ''
                });
            }
        }

        return ({
            title: title ?? 'Отель без названия',
            description: description ?? '',
            photos: validPhotos
        });
    }
}
