import React from "react";
import {ButtonsContainer} from "../../buttonsContainer";
import {PopupFullScreenBasicProps} from "../../popupFullscreen/popupFullScreenWithEvents";
import {faCheck, faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import styles from "../standardsPopupsFullscreenStyles.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PopupFullscreen from "../../popupFullscreen/popupFullScreen";
import {LinkAsButton} from "../../linkAsButton";

const TEXT_BUTTON_RECEIVED: string = 'Принято';

export interface PopupFullScreenStepSuccessProps extends PopupFullScreenBasicProps {
    successText: string;
    link: string;
    buttonLinkPublicText?: string;
}

function PopupFullScreenStepSuccess(props: PopupFullScreenStepSuccessProps) {
    return (
        <PopupFullscreen>
            <div className={`${styles.icoSuccessColor} ${styles.icoContainer}`}>
                <FontAwesomeIcon icon={faCheckCircle}/>
            </div>

            <div className={styles.messageContainer}>
                {props.successText}
            </div>
            <ButtonsContainer>
                <LinkAsButton
                    eventClick={props.eventClose}
                    href={props.link}
                    text={props.buttonLinkPublicText ?? TEXT_BUTTON_RECEIVED}
                    type={'ok'}
                    iconLeft={faCheck}
                />
            </ButtonsContainer>
        </PopupFullscreen>
    );
}

export default React.memo(PopupFullScreenStepSuccess);
