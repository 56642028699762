import {RoomPhotoVMCollection} from "../../../api/src";
import {RoomPhotoCollection, RoomPhotoItem} from "../../../interfaces/api/roomPhotoCollection";
import {v4 as uuidv4} from "uuid";
import {Dictionary} from "../../../interfaces/dictionary";
import {action, computed, makeObservable, observable} from "mobx";
import {getRequestErrorText} from "../../../api/helpers/getRequestErrorText";
import {StoreFullScreenPopup} from "../../common/storeFullScreenPopup";
import {StoreApiUser} from "../../../api/storeApiUser";

interface InitDataStoreUserRoomPhotos {
    storeApi: StoreApiUser;
    storeFullScreenPopup: StoreFullScreenPopup;
}

type ErrorPhoto = string | undefined;

export class StoreUserRoomPhotos {
    private readonly _storeApi: StoreApiUser;
    private readonly _storeFullScreenPopup: StoreFullScreenPopup;

    private readonly _roomPhotos_observable: Dictionary<RoomPhotoItem[]>;

    private _setRoomPhotos_action(photos: RoomPhotoItem[], roomId: number) {
        this._roomPhotos_observable[roomId] = photos;
    }

    get roomPhotos() {
        return this._roomPhotos_observable;
    }

    private readonly _roomPhotosErrors_observable: Dictionary<ErrorPhoto>

    private _setRoomPhotosErrors_action(error: ErrorPhoto, roomId: number) {
        this._roomPhotosErrors_observable[roomId] = error;
    }

    get roomPhotosErrors() {
        return this._roomPhotosErrors_observable;
    }

    /**
     * Получение изображений номера
     * @param roomId
     * @public
     */
    public serverRequestGetRoomPhotos(roomId: number) {
        this._storeApi.apiRoom?.roomGetRoomPhotosGet(roomId)
            .then((inputData) => {
                const photos: RoomPhotoVMCollection = inputData.data;
                // Валидация данных
                const validPhotosData: RoomPhotoCollection | undefined = this._validationPhotosList(photos);

                if (!validPhotosData) {
                    return;
                }
                // Установка данных
                this._setRoomPhotos_action(validPhotosData.photos, roomId)
            })
            .catch((errorText) => {
                this._setRoomPhotosErrors_action(getRequestErrorText(errorText), roomId)
            });
    }

    constructor(initData: InitDataStoreUserRoomPhotos) {
        this._storeApi = initData.storeApi;
        this._storeFullScreenPopup = initData.storeFullScreenPopup;

        this._roomPhotos_observable = {};
        this._roomPhotosErrors_observable = {};

        this.serverRequestGetRoomPhotos = this.serverRequestGetRoomPhotos.bind(this);

        makeObservable<this,
            '_roomPhotos_observable'
            | '_roomPhotosErrors_observable'
            | '_setRoomPhotos_action'
            | '_setRoomPhotosErrors_action'>(this, {
            _roomPhotos_observable: observable,
            _roomPhotosErrors_observable: observable,
            _setRoomPhotos_action: action,
            _setRoomPhotosErrors_action: action,
            roomPhotos: computed,
            roomPhotosErrors: computed
        });
    }

    /**
     * Валидация данных для изображения
     * @param photoItem
     * @private
     */
    private _validationPhotosList(photoItem: RoomPhotoVMCollection): RoomPhotoCollection | undefined {
        const {
            roomId,
            photos
        } = photoItem;

        if (typeof roomId !== 'number') {
            return undefined;
        }

        const validPhotos: RoomPhotoItem[] = [];

        if (photos) {
            for (let i = 0; i < photos.length; ++i) {
                const photoId = photos[i].id;

                if (!photoId) {
                    continue;
                }

                validPhotos.push({
                    internalId: uuidv4(),
                    id: photoId,
                    photo: photos[i].photo ?? ''
                });
            }
        }

        return ({
            internalId: uuidv4(),
            roomId: roomId,
            photos: validPhotos
        });
    }
}
