import {action, computed, makeObservable, observable} from "mobx";

interface StoreSitePageTitleOptions {
    readonly pageTitle: string;
    readonly linkBack?: string;
    readonly eventReturnBack?: () => void;
}

export class StoreSitePageTitle {
    private _pageTitle_observable: string;
    private _linkBack_observable?: string;
    private _eventReturnBack_observable?: () => void;

    get pageTitle(): string {
        return this._pageTitle_observable;
    }

    get linkBack(): string | undefined {
        return this._linkBack_observable;
    }

    get isHasEventReturnBack(): boolean {
        return typeof this._eventReturnBack_observable === 'function';
    }

    public eventReturnBack() {
        if (typeof this._eventReturnBack_observable === 'function') {
            this._eventReturnBack_observable()
        }
    }

    public setOptions(inputOptions: StoreSitePageTitleOptions) {
        if (inputOptions.pageTitle !== this._pageTitle_observable) {
            this._pageTitle_observable = inputOptions.pageTitle;
        }

        if (inputOptions.linkBack !== this._linkBack_observable) {
            this._linkBack_observable = inputOptions.linkBack;
        }

        this._eventReturnBack_observable = inputOptions.eventReturnBack
    }

    constructor() {
        this.eventReturnBack = this.eventReturnBack.bind(this);

        this._pageTitle_observable = '';
        this._linkBack_observable = undefined;
        this._eventReturnBack_observable = undefined;

        makeObservable<this,
            '_pageTitle_observable'
            | '_linkBack_observable'
            | '_eventReturnBack_observable'>(this, {
            _pageTitle_observable: observable.ref,
            _linkBack_observable: observable.ref,
            _eventReturnBack_observable: observable.ref,
            setOptions: action,
            pageTitle: computed,
            linkBack: computed,
            isHasEventReturnBack: computed
        });
    }
}
