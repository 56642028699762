import {action, computed, makeObservable, observable} from "mobx";
import {ErrorText} from "../../interfaces/errorText";

interface InitDataStoreComponentStandardCheckbox {
    isCheck: boolean;
    // Сбросить ошибку при изменении данных
    isResetErrorOnChangeValue?: boolean;
}

export class StoreComponentStandardCheckbox {
    private readonly _isResetErrorOnChangeValue?: boolean;

    private _isCheck_observable: boolean;

    public eventChangeValue() {
        if (this._isResetErrorOnChangeValue) {
            // Сбрасываем ошибку если есть
            if (this._errorText_observable) {
                this._errorText_observable = undefined;
            }
        }

        this._isCheck_observable = !this._isCheck_observable;
    }

    get isCheck() {
        return this._isCheck_observable;
    }

    private _errorText_observable: ErrorText;

    public setErrorText(inputErrorText: ErrorText) {
        if (this._errorText_observable === inputErrorText) {
            return;
        }

        this._errorText_observable = inputErrorText;
    }

    get errorText() {
        return this._errorText_observable;
    }

    public resetIsCheck() {
        this._isCheck_observable = false;
    }

    constructor(initData: InitDataStoreComponentStandardCheckbox) {
        this.eventChangeValue = this.eventChangeValue.bind(this);

        this._isCheck_observable = initData.isCheck;
        this._isResetErrorOnChangeValue = initData?.isResetErrorOnChangeValue;
        this._errorText_observable = undefined;

        makeObservable<this,
            | '_isCheck_observable'
            | '_errorText_observable'>(this, {
            _isCheck_observable: observable.ref,
            _errorText_observable: observable.ref,
            eventChangeValue: action,
            setErrorText: action,
            resetIsCheck: action,
            isCheck: computed,
            errorText: computed
        });
    }
}
